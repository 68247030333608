import React, {useState, useEffect} from 'react';
import {useHistory} from "react-router";
import {useLocation} from "react-router-dom";
import styles from './styles.module.scss';
import userState from 'src/globalState/user';
import {Formik, Field, Form} from 'formik';
import * as Yup from 'yup';
import AuthFormField from "../../components/AuthFormField";
import ErrorMessageWrapper from "../../components/ErrorMessageWrapper";
import SDInput from "../../components/common/sdInput/sdInput";

const Auth = () => {
    const [errorMessage, setErrorMessage] = useState(null);
    const [redirectPath, setRedirectPath] = useState(null);
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        document.title = 'sdCloud :: Bringing System Dynamics Into Cloud :: Sign-In';
        setRedirectPath(new URLSearchParams(location.search).get('redirectTo'));
    }, []);

    const submit = async (values) => {
        const data = {
            UserName: values.username,
            Password: values.password,
        };
        const {response, error} = await userState.login(data);
        if (response) {
            let locationObject;
            if (redirectPath) {
                const locationJson = atob(redirectPath);
                try {
                    locationObject = JSON.parse(locationJson);
                } catch (e) {
                    // Will fallback to default redirect;
                }
            }
            history.push(locationObject || '/');
        } else {
            setErrorMessage(error.Message);
        }
    };

    return (
        <div className={styles.authBody}>
            <Formik
                initialValues={
                    {
                        username: "",
                        password: ""
                    }
                }
                validationSchema={
                    Yup.object().shape({
                        username: Yup.string().required('Username cannot be empty'),
                        password: Yup.string().required('Password cannot be empty'),
                    })}
                onSubmit={(values) => submit(values)}
            >
                {
                    ({errors, touched, handleSubmit}) => (
                        <Form
                            className={`${styles.authFormWrapper} ${styles.authControlsWrapper}`}
                            onKeyDown={e => {
                                if (e.key === 'Enter') {
                                    handleSubmit();
                                }
                            }}
                        >
                            <div className={`${styles.authImage}`}/>
                            <div className={`${styles.authCaption}`}>
                                Welcome to sdCloud
                            </div>

                            <Field
                              label={"Username"}
                              name={"username"}
                              placeholder={"Login"}
                              type={"text"}
                              component={AuthFormField}
                            />
                            <Field
                              label={"Password"}
                              name={"password"}
                              type={"password"}
                              placeholder={"Password"}
                              component={AuthFormField}
                            />

                            <div className={`${styles.authLinkWrapper}`}>
                                <div
                                    className={styles.authLink}
                                    onClick={() => history.push('/signup')}
                                >
                                    Sign up
                                </div>
                                <div className={`${styles.forgotPasswordLink} ${styles.authLink}`}>
                                    Forgot password?
                                </div>
                            </div>
                            {
                                Object.keys(errors).map(
                                    (error, key) => (
                                        touched[error] &&
                                        <ErrorMessageWrapper key={key}>
                                            {errors[error]}
                                        </ErrorMessageWrapper>
                                    )
                                )
                            }
                            <ErrorMessageWrapper>{errorMessage}</ErrorMessageWrapper>
                            <button
                                className={`${styles.authButton}`}
                                type={"submit"}
                            >
                                Login
                            </button>
                        </Form>
                    )
                }
            </Formik>
        </div>
    );
};

export default Auth;
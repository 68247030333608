/**
 *  Gallery component
 */
import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import { useParams } from "react-router";
import { Store as store } from "react-notifications-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SDCModalDialog from "../common/sdcModalDialog/sdcModalDialog";
import apiRequest from "src/lib/apiRequest";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { faEdit } from "@fortawesome/free-solid-svg-icons";

library.add(fas, faEdit);

const statusToColor = {
  Success: "#6EFF6E",
  Error: "#FF6E6E",
  Queued: "#F98C32",
  InProgress: "#3F88C5",
  Canceled: "#A0A0A0",
};

const AssignmentsRows = ({ assignmentsList, attachCommand, executionId }) => {
  let assignmentsRecords = [];
  if (assignmentsList) {
    for (var i = 0; i < assignmentsList.length; i++) {
      var key = "dataRow_" + i;
      let assignment = assignmentsList[i];
      const startDate = new Date(
        Date.parse(assignment.CreatedDateUtc + "Z")
      ).toLocaleString();
      const endDate = assignment.EndDateUtc
        ? "Not set"
        : new Date(Date.parse(assignment.EndDateUtc + "Z")).toLocaleString();

      assignmentsRecords.push(
        <div className={styles.assignmentsListRow} key={key}>
          <div className={styles.assignmentsListCell}>{assignment.Id}</div>
          <div className={styles.assignmentsListCell}>{assignment.Name}</div>
          <div className={styles.assignmentsListCell}>
            {assignment.AuthorUserName}
          </div>
          <div className={styles.assignmentsListCell}>{startDate}</div>
          <div className={styles.assignmentsListCell}>{endDate}</div>

          <div className={styles.assignmentsListActionsCell}>
            <FontAwesomeIcon
              className={styles.assignmentsEnrollIcon}
              icon="plus-circle"
              onClick={() => attachCommand(assignment.Id, executionId)}
            />
          </div>
        </div>
      );
    }
  }

  return assignmentsRecords;
};

const ExecutionRows = ({ modelsRunsList, onAttachButtonClick, modelId }) => {
  let executionRecords = [];
  if (modelsRunsList) {
    for (var i = 0; i < modelsRunsList.length; i++) {
      var execution = modelsRunsList[i];
      const executionId = execution.ModelExecutionId;

      const queuedDate = Date.parse(execution.QueuedDateUtc + "Z");
      if (
        execution.StatusAsString == "Success" ||
        execution.StatusAsString == "Warning" ||
        execution.StatusAsString == "Error"
      ) {
        let endDate = Date.parse(execution.EndDateUtc + "Z");
        let modelTime = Math.abs(endDate - queuedDate);
        let fancyView = new Date(modelTime).toISOString().substr(11, 8);
        executionRecords.push(
          <div className={styles.executionResultsRow}>
            <div className={styles.executionResultsCell}>
              <Link
                to={`/models/${execution.ModelId}/executions/${execution.ModelExecutionId}`}
              >
                {execution.ModelExecutionId}
              </Link>
            </div>
            {modelId === undefined && (
              <div className={styles.executionResultsCell}>
                {execution.ModelId}
              </div>
            )}
            <div className={styles.executionResultsCell}>
              {execution.ExecutionEngineId}
            </div>
            <div className={styles.executionResultsCell}>
              {new Date(queuedDate).toLocaleString()}
            </div>
            <div className={styles.executionResultsCell}>{fancyView}</div>
            <div
              className={styles.executionResultsCell}
              style={{
                "background-color": statusToColor[execution.StatusAsString],
              }}
            >
              {execution.StatusAsString}
            </div>
            <div className={styles.executionResultsCell}>
              <div
                className={styles.buttonSubmit}
                onClick={() => onAttachButtonClick(executionId)}
                data-cy={"attachButton"}
              />
            </div>
          </div>
        );
      } else {
        executionRecords.push(
          <div className={styles.executionResultsRow}>
            <div className={styles.executionResultsCell}>
              {execution.ModelExecutionId}
            </div>
            {modelId === undefined && (
              <div className={styles.executionResultsCell}>
                {execution.ModelId}
              </div>
            )}
            <div className={styles.executionResultsCell}>
              {execution.ExecutionEngineId}
            </div>
            <div className={styles.executionResultsCell}>
              {new Date(queuedDate).toLocaleString()}
            </div>
            <div className={styles.executionResultsCell}>
              {execution.StatusAsString}
            </div>
            <div
              className={styles.executionResultsCell}
              style={{
                "background-color": statusToColor[execution.StatusAsString],
              }}
            >
              {execution.StatusAsString}
            </div>
          </div>
        );
      }
    }
  }

  return executionRecords;
};

const ModelsRun = ({ stateModelId }) => {
  const [modelsRunsList, setModelsRunsList, exactModelsRunsList] = useState([]);
  const [assignmentsList, setAssignmentsList] = useState([]);
  const [showCodeDialog, setShowCodeDialog] = useState("False");
  const [selectedExecutionId, setSelectedExecutionId] = useState(-1);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const modelsRunsList = getTotalModelRuns(setModelsRunsList);
    const assignmentsList = getAssignmentsList(setAssignmentsList);
    let exactModelsRunsList = getExactModelRuns(stateModelId);
  }, []);

  const handleAttachButtonClick = (executionId) => {
    setSelectedExecutionId(executionId);
    setShowCodeDialog("True");
  };

  const getAssignmentsList = async () => {
    const { response } = await new apiRequest(
      "GET /assignments/enrolled",
      false
    ).send();
    if (response) {
      return setAssignmentsList(response.Entities);
    }
    return [];
  };

  const sendAttachment = async (assignmentId, attachmentId) => {
    const data = {
      AttachmentId: attachmentId,
      AttachmentType: "Execution",
    };

    let requestPath =
      "/assignments/" + assignmentId + "/submission/attachments";

    const { response } = await new apiRequest(
      "POST " + requestPath,
      false
    ).sendJSON(data);

    if (response) {
      store.addNotification({
        title: "Success",
        message: "Execution attached",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
        },
      });
    } else {
      store.addNotification({
        title: "Error",
        message: `${response.error.Message}`,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
        },
      });
    }
  };

  const getTotalModelRuns = async (setModelsRunsList) => {
    let requestPath = "/executions";
    if (stateModelId !== undefined) {
      requestPath = "/models/" + stateModelId + "/executions";
    }
    const { response } = await new apiRequest(
      "GET " + requestPath,
      false
    ).send();
    if (response) {
      setLoading(false);
      return setModelsRunsList(response.Entities);
    } else {
      return [];
    }
  };

  const getExactModelRuns = (stateModelId) => {
    let modelsShowed = [];

    if (stateModelId === undefined) {
      return modelsRunsList;
    } else {
      modelsShowed = modelsRunsList.filter(function (el) {
        return el.ModelId === Number(stateModelId);
      });
    }
    return modelsShowed;
  };

  const filteredList = getExactModelRuns(stateModelId);

  if (filteredList.length === 0) {
    return (
      <div className={styles.execHist}>
        <div className={styles.reload}>
          <h2 className={styles.execHistHeader}>Execution history</h2>
          <FontAwesomeIcon
            className={styles.reloadButton}
            icon="redo"
            onClick={() => getTotalModelRuns(setModelsRunsList)}
          />
        </div>
        <div className={styles.emptyExecMessage}>
          There are no executions for this model.
        </div>
      </div>
    );
  }

  const SkeletonRows = () => {
    let skeletonRows = [];

    for (var i = 0; i < 10; i++) {
      skeletonRows.push(
        <div className={styles.executionResultsRow}>
          <div className={styles.executionResultsCell}>
            <Skeleton />
          </div>
          <div className={styles.executionResultsCell}>
            <Skeleton />
          </div>
          <div className={styles.executionResultsCell}>
            <Skeleton />
          </div>
          <div className={styles.executionResultsCell}>
            <Skeleton />
          </div>
          <div className={styles.executionResultsCell}>
            <Skeleton />
          </div>
          <div className={styles.executionResultsCell}>
            <Skeleton />
          </div>
          <div className={styles.executionResultsCell}>
            <Skeleton />
          </div>
        </div>
      );
    }

    return skeletonRows;
  };

  const closeCodeDialog = () => {
    setShowCodeDialog("False");
    setSelectedExecutionId("");
  };

  const dialogBody = (
    <div className={styles.assignmentsListContainer}>
      <AssignmentsRows
        assignmentsList={assignmentsList}
        attachCommand={sendAttachment}
        executionId={selectedExecutionId}
      />
    </div>
  );

  return (
    <div className={styles.execHist}>
      <div className={styles.reload}>
        <h2 className={styles.execHistHeader}>Execution history</h2>
        <FontAwesomeIcon
          className={styles.reloadButton}
          icon="redo"
          onClick={() => getTotalModelRuns(setModelsRunsList)}
        />
      </div>
      <div
        className={styles.executionResultsContainer}
        data-cy={"executionHistoryTable"}
      >
        <SDCModalDialog
          width="900"
          height="500"
          show={showCodeDialog}
          title="Select assignments"
          body={dialogBody}
          onCancel={closeCodeDialog}
          onOk={closeCodeDialog}
        />

        <div
          className={styles.executionResultsHeaderRow}
          data-cy={"executionHistoryTableHeader"}
        >
          <div className={styles.executionResultsCell}>Execution #</div>
          {stateModelId === undefined && (
            <div className={styles.executionResultsCell}>Model #</div>
          )}
          <div className={styles.executionResultsCell}>Engine</div>
          <div className={styles.executionResultsCell}>Execution date</div>
          <div className={styles.executionResultsCell}>Execution time</div>
          <div className={styles.executionResultsCell}>Status</div>
          <div className={styles.executionResultsCell}>Attach</div>
        </div>

        {loading && <SkeletonRows />}
        {!loading && (
          <ExecutionRows
            modelsRunsList={filteredList}
            onAttachButtonClick={handleAttachButtonClick}
            modelId={stateModelId}
          />
        )}
      </div>
    </div>
  );
};

export default ModelsRun;

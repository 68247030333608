import React, { useState, useEffect } from "react";
import styles from "../styles.module.scss";
import { useHistory } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AvailableAct = ({ id, onRunButtonClick, modelType }) => {
  const history = useHistory();

  const Params = async () => {
    history.push({
      pathname: `/models/${id}`,
    });
  };
  const Edit = () => {
    history.push({
      pathname: `/models/edit/${id}`,
    });
  };

  return (
    <div className={styles.modelDetailsAvailAct}>
      <h2 className={styles.modelDetailsCardHeader}>Available Actions</h2>
      <div className={styles.modelDetailsAvailActRow}>
        <div
          data-cy={"runWithPySDButton"}
          className={styles.buttonStartWithPySD}
          onClick={() => onRunButtonClick("pysd")}
        />
        <span className={styles.modelDetailsAvailActRowName}>
          Run model with PySD
        </span>
      </div>
      {modelType === "mdl" &&
       <div className={styles.modelDetailsAvailActRow}>
        <div
          data-cy={"runWithSDEButton"}
          className={styles.buttonStartWithSDE}
          onClick={() => onRunButtonClick("sdeverywhere")}
        />
        <span className={styles.modelDetailsAvailActRowName}>
          Run model with SDE
        </span>
      </div>}
      <div className={styles.modelDetailsAvailActRow}>
        <div
          data-cy={"runWithParamsButton"}
          className={styles.buttonParams}
          onClick={Params}
        />
        <span className={styles.modelDetailsAvailActRowName}>
          Run model with parameters
        </span>
      </div>
      <div className={styles.modelDetailsAvailActRow}>
        <FontAwesomeIcon
          className={styles.actEditIcon}
          icon="edit"
          onClick={Edit}
          data-cy={"editModelButton"}
        />
        <span className={styles.modelDetailsAvailActRowName}>
          Edit model content
        </span>
      </div>
    </div>
  );
};

export default AvailableAct;

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import styles from "../styles.module.scss";
import Skeleton from 'react-loading-skeleton';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Store as store } from 'react-notifications-component';
import MarkdownComponent from "../../common/markdownComponent/markdownComponent";
import apiRequest from 'src/lib/apiRequest';

import ModelsRows from "../../common/assignmentModelsRows/assignmentModelsRows";
import ExecutionsRows from "../../common/assignmentExecutionsRows/assignmentExecutionsRows";
import SDButton from "../../common/sdButton/sdButton";
import SDTextArea from "../../common/sdTextArea/sdTextArea";

const statusToColor = {
    'Success': "#6EFF6E",
    'Error': "#FF6E6E",
    'Queued': "#F98C32",
    'InProgress': "#3F88C5",
    'Canceled': "#A0A0A0"
}

const SkeletonRowsExecutions = () => {
    let skeletonRows = [];
    for (var i = 0; i < 5; i++) {
        var key = "skeletonRow_" + i;
        skeletonRows.push(
            <div className={styles.assignmentsListRow} key={key}>
                <div className={styles.assignmentsListCell}><Skeleton /></div>
                <div className={styles.assignmentsListCell}><Skeleton /></div>
                <div className={styles.assignmentsListCell}><Skeleton /></div>
                <div className={styles.assignmentsListCell}><Skeleton /></div>
                <div className={styles.assignmentsListCell}><Skeleton /></div>
                <div className={styles.assignmentsListCell}><Skeleton /></div>
                <div className={styles.assignmentsListCell}><Skeleton /></div>
            </div>);
    }
    return skeletonRows;
}

const SkeletonRowsModels = () => {
    let skeletonRows = [];
    for (var i = 0; i < 5; i++) {
        var key = "skeletonRow_" + i;
        skeletonRows.push(
            <div className={styles.assignmentsListRow} key={key}>
                <div className={styles.assignmentsListCell}><Skeleton /></div>
                <div className={styles.assignmentsListCell}><Skeleton /></div>
                <div className={styles.assignmentsListCell}><Skeleton /></div>
                <div className={styles.assignmentsListCell}><Skeleton /></div>
            </div>);
    }
    return skeletonRows;
}

const MySubmissions = ({ assignmentId, assignmentData }) => {
    const [description, setDescription] = useState(null);
    const [submissionDetails, setSubmissionDetails] = useState({});
    const [attachmentsList, setAttachmentsList] = useState([]);
    const [modelsList, setModelsList] = useState([]);
    const [executionsList, setExecutionsList] = useState([]);
    const [loading, setLoading] = useState(false);
    const assignemntDetails = assignmentData;
    const history = useHistory();

    useEffect(() => {
        setLoading(true);
        const modelsList = getModelsList(setModelsList);
        const attachmentsList = getAttachmentsList(setAttachmentsList);
        const submissionDetails = getSubmissionDetails(setSubmissionDetails);
        const executionsList = getExecutionsList(setExecutionsList);
    }, []);

    const getSubmissionDetails = async (setSubmissionDetails) => {
        let requestPath = "/assignments/" + assignmentId + "/submission/";

        const { response } = await new apiRequest('GET ' + requestPath, false).send();
        console.log(response);
        if (!response.Entity.Notes || 
            response.Entity.Notes === "" ||
            response.Entity.Notes === "Please add a note about your submission. You can use Markdown syntax") {
            setDescription(assignemntDetails.SubmissionTemplate);
        } else {
            setDescription(response.Entity.Notes);
        }

        return setSubmissionDetails(response.Entity);
    };

    const saveSubmission = async () => {
        let requestPath = "/assignments/" + assignmentId + "/submission/";
        const data = submissionDetails;
        data.Notes = description;
        const { response } = await new apiRequest('POST ' + requestPath, false).sendJSON(data);
        if (response) {
            getSubmissionDetails(setSubmissionDetails);
        }

        responseToAction(response, "Submission saved");
    }

    const submitSubmission = async () => {

        let requestPath = "/assignments/" + assignmentId + "/submission/";
        const data = submissionDetails;
        data.Notes = description;
        data.Status = "Submitted";
        //TODO: add confirmation dialog
        const { response } = await new apiRequest('POST ' + requestPath, false).sendJSON(data);

        responseToAction(response, "Submission posted");

    }

    const attachModel = () => {
        history.push({
            pathname: '/models',
        })
    }

    const attachExecution = () => {
        history.push({
            pathname: '/modelsrun',
        })
    }

    const getAttachmentsList = async (setAttachmentsList) => {
        let requestPath = "/assignments/" + assignmentId + "/submission/attachments";
        const { response } = await new apiRequest('GET ' + requestPath, false).send();
        if (response) {
            return setAttachmentsList(response.Entities);
        }
        else {
            return [];
        }
    }

    const getModelsList = async (setModelsList) => {
        const { response } = await new apiRequest('GET /models', false).send();
        if (response) {
            return setModelsList(response.Entities);
        }
        return [];
    }

    const getExecutionsList = async (setExecutionsList) => {
        const { response } = await new apiRequest('GET /executions', false).send();
        if (response) {
            setLoading(false);
            return setExecutionsList(response.Entities);
        }
        return [];
    }

    const responseToAction = (response, message) => {
        if (response.response !== false) {
            store.addNotification({
                title: "Success",
                message: message,
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 5000
                }
            });
        } else {
            store.addNotification({
                title: "Error",
                message: "Unknown error",
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 5000
                }
            });
        }
    }

    const deleteAttachmentRequest = async (attachmentId, attachmentType) => {
        const { response } = await new apiRequest(`DELETE /assignments/${assignmentId}/submission/attachments/${attachmentId}`).send();
        if (response.response !== false) {
            if (attachmentType === "Model") {
                getModelsList(setModelsList);
                getAttachmentsList(setAttachmentsList);
            } else {
                getExecutionsList(setExecutionsList);
                getAttachmentsList(setAttachmentsList);
            }
        }
        responseToAction(response, "Attachment deleted");
    }

    const renderModelsList = () => {
        return (
            <div>
                <div className={styles.attachmentHeaderContainer}>
                    <SDButton name={'Attach model'} icon={<FontAwesomeIcon className={styles.plusIcon} icon="plus-circle" />}
                              appearance={'primary'} onClick={attachModel} style={{marginLeft: 'auto'}} />
                </div>
                <div className={styles.assignmentsListContainer}>
                    <div className={styles.assignmentsListHeaderRow}>
                        <div className={styles.assignmentsListCell}>Id</div>
                        <div className={styles.assignmentsListCell}>Model Id</div>
                        <div className={styles.assignmentsListCell}>Name</div>
                        <div className={styles.assignmentsListActionsCell}>Actions</div>
                    </div>
                    {loading &&
                        <SkeletonRowsModels />}
                    {!loading &&
                        <ModelsRows
                            modelsList={modelsList}
                            attachmentsList={attachmentsList}
                            deleteAttachment={deleteAttachmentRequest} 
                            assignmentId={assignmentId}
                            />}
                </div>
             </div>);
    }

    const renderExecutionsList = () => {
        return (
            <div>
                <div className={styles.attachmentHeaderContainer}>
                    <SDButton name={'Attach execution'}
                              icon={<FontAwesomeIcon className={styles.plusIcon} icon="plus-circle" />}
                              style={{marginLeft: 'auto'}} appearance={'primary'} />
                </div>

                <div className={styles.assignmentsListContainer}>
                    <div className={styles.assignmentsListHeaderRow}>
                        <div className={styles.assignmentsListCell}>Id</div>
                        <div className={styles.assignmentsListCell}>Execution Id</div>
                        <div className={styles.assignmentsListCell}>Model Id</div>
                        <div className={styles.assignmentsListCell}>Engine</div>
                        <div className={styles.assignmentsListCell}>Execution date</div>
                        <div className={styles.assignmentsListCell}>Execution time</div>
                        <div className={styles.assignmentsListCell}>Status</div>
                        <div className={styles.assignmentsListCell}></div>
                    </div>

                    {loading &&
                        <SkeletonRowsExecutions />}
                    {!loading &&
                        <ExecutionsRows
                            executionsList={executionsList}
                            attachmentsList={attachmentsList}
                            deleteAttachment={deleteAttachmentRequest} 
                            assignmentId={assignmentId}/>}
                </div>
            </div>
        );
    }

    return (
        <div>
            <div>
                <div className={styles.submissionDetailsHeaderContainer}>
                    <span className={styles.submissionDetailsHeaderText}>Submission notes</span>
                </div>

                <div className={styles.submissionNotesContainer}>
                    <div className={styles.plainTextDiv}>
                        Please add a note about your submission. You can use 
                        <a href="https://daringfireball.net/projects/markdown/" target="_blank"> Markdown </a>
                        syntax to make description better formatted and structured.
                    </div>
                    {description != null && <div className={styles.assignmentsRow}>
                        <MarkdownComponent
                            markdownContent={description}
                            updateRoot={setDescription}
                            isEditable={true}
                        />
                    </div>}
                </div>

            </div>

            <div>
                <div className={styles.submissionDetailsHeaderContainer}>
                    <span className={styles.submissionDetailsHeaderText}>Attached models</span>
                </div>
                {renderModelsList()}
            </div>

            <div>
                <div className={styles.submissionDetailsHeaderContainer}>
                    <span className={styles.submissionDetailsHeaderText}>Attached executions</span>
                </div>
                <div>
                    {renderExecutionsList()}
                </div>
            </div>

            <div className={styles.buttonFooter}>
                <SDButton name={'Save assignment'} appearance={'secondary'} onClick={saveSubmission}
                          style={{marginLeft: 'auto', marginRight: '10px'}} />

                <SDButton name={'Submit'} appearance={'primary'} onClick={submitSubmission} />
            </div>
        </div>
    );
}

export default MySubmissions;
import React from "react";
import styles from "./styles.module.scss";



const SDPanel = (
    {
        title,
        width,
        height,
        children
    }) => {

    return (
        <div className={styles.outerPanel}>
            <div className={styles.panel}>
                <div className={styles.panelTitle}>{title}</div>
                {children} 
            </div>
        </div>
    )
};

export default SDPanel;
    
import React from "react";
import styles from "./styles.module.scss";
import SDButton from "../sdButton/sdButton";

const SDCModalDialog = (
    {
        width,
        height,
        show,
        title,
        body,
        onOk,
        onCancel
    }) => {
    if (!width) {
        width = 400;
    }

    if (!height) {
        height = 200;
    }
    const globalStyle = show === "True" ? styles.sdcModalDialogShow : styles.sdcModalDialog;
    const contentHeight = height - 120;
    return (
        <div id="snackbar" className={globalStyle}>
            <div className={styles.backgroundShade}>
            </div>
            <div className={styles.dialogHolder}>
                <div
                    className={styles.dialogBody}
                    style={{width: width + "px", height: height + "px"}}
                >
                    <div className={styles.dialogHeader}>
                        {title}
                    </div>
                    <div className={styles.dialogContent}
                         style={{height: contentHeight + "px"}}>
                        {body}
                    </div>
                    <div className={styles.dialogFooter}>
                        <SDButton name={'Cancel'} appearance={'outline'} onClick={onCancel} />

                        <SDButton name={'OK'} appearance={'primary'}
                                  style={{marginLeft: 'auto', marginRight: '10px'}} onClick={onOk} />
                    </div>
                </div>
            </div>
        </div>
    )
};

export default SDCModalDialog;

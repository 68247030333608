/**
 *  Execution component
 */
import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { useLocation } from "react-router-dom";
import styles from "./styles.module.scss";
import apiRequest from "src/lib/apiRequest";
import Skeleton from "react-loading-skeleton";

/* Set of custom sub-components for screen parts */
import InfoCard, {
  Icons,
} from "src/components/execution/components/infocard/infocard";
import SliderMenu from "src/components/execution/components/sliderMenu/sliderMenu";
import ResultsChart from "src/components/execution/components/resultsChart/resultsChart";
import ResultsTable from "src/components/execution/components/resultsTable/resultsTable";

const ExecutionResultsExporter = require("../../helpers/executionResultsExporter");

const Execution = () => {
  const [view, setView] = useState("sliderMenuItem_logs");
  const [executionDetails, setExecutionDetails] = useState([]);
  const [logs, setLogs] = useState([]);
  const [artifacts, setArtifacts] = useState([]);
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [modelInfo, setModelInfo] = useState({});
  const [executionInfo, setExecutionInfo] = useState({});
  const [modelCardsInfo, setModelCardsInfo] = useState([]);
  const [executionCardsInfo, setExecutionCardsInfo] = useState([]);
  const [resultsCardsInfo, setResultsCardsInfo] = useState([]);
  const [dataPartsCount, setDataPartsCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const { id, executionId } = useParams();

  const search = useLocation().search;
  const qSource = new URLSearchParams(search).get("source");
  const qAssignmentId = new URLSearchParams(search).get("assignment-id");
  const qEnrollmentId = new URLSearchParams(search).get("enrollment-id");
  const qAttachmaneId = new URLSearchParams(search).get("attachment-id");

  useEffect(() => {
    setLoading(true);
    const executionDetails = getExecution(
      setExecutionDetails,
      setResultsCardsInfo,
      setView
    );
    const executionInfo = getExecutionInfo(
      setExecutionInfo,
      setExecutionCardsInfo
    );
    const modelInfo = getModelInfo(setModelInfo, setModelCardsInfo, setView);
    const logs = getExecutionLogs(setLogs);
    const artifacts = getExecutionArtifacts(setArtifacts);
  }, []);

  const getExecution = async (
    setExecutionDetails,
    setResultsCardsInfo,
    setView
  ) => {
    let path = "/models/" + id + "/executions/" + executionId + "/results";
    if (qSource === "assignment") {
      if (qEnrollmentId) {
        path =
          "/assignments/" +
          qAssignmentId +
          "/enrollments/" +
          qEnrollmentId +
          "/submission/attachments/" +
          qAttachmaneId +
          "/asexecution/results";
      } else {
        path =
          "/assignments/" +
          qAssignmentId +
          "/submission/attachments/" +
          qAttachmaneId +
          "/asexecution/results";
      }
    }

    const { response } = await new apiRequest("GET " + path, false).send();
    if (response) {
      var resultsCardsInfo = [
        {
          icon: Icons.StartTime,
          value: response.Entities[0].Time + " second(s)",
        },
        {
          icon: Icons.EndTime,
          value:
            response.Entities[response.Entities.length - 1].Time + " second(s)",
        },
        {
          icon: Icons.ExecutionStep,
          value: response.Entities[1].TimeDelta + " second(s)",
        },
        {
          icon: Icons.Table,
          value:
            Object.keys(response.Entities[0].Values).length +
            1 +
            " column(s) and " +
            response.Entities.length +
            " row(s)",
        },
        {
          icon: Icons.Download,
          value: renderExportResultsButtons(),
        },
      ];
      setResultsCardsInfo(resultsCardsInfo);
      return setExecutionDetails(response.Entities);
    } else {
      setResultsCardsInfo([]);
      return setExecutionDetails([]);
    }
  };

  const renderExportResultsButtons = () => {
    return (
      <div>
        <span onClick={function() {ExecutionResultsExporter.saveExecutionResultsAsJson(id, executionId)}} className={styles.executionResultsExportButton}>.json</span>
        <span onClick={function() {ExecutionResultsExporter.saveExecutionResultsAsCSV(id, executionId)}} className={styles.executionResultsExportButton}>.csv</span>
      </div>
    )
  }

  const getExecutionInfo = async (setExecutionInfo, setExecutionCardsInfo) => {
    let path = "/models/" + id + "/executions/" + executionId;
    if (qSource === "assignment") {
      if (qEnrollmentId) {
        path =
          "/assignments/" +
          qAssignmentId +
          "/enrollments/" +
          qEnrollmentId +
          "/submission/attachments/" +
          qAttachmaneId +
          "/asexecution";
      } else {
        path =
          "/assignments/" +
          qAssignmentId +
          "/submission/attachments/" +
          qAttachmaneId +
          "/asexecution";
      }
    }

    const { response } = await new apiRequest("GET " + path, false).send();

    if (response) {
      const startDate = Date.parse(response.Entity.StartDateUtc + "Z");
      const queuedDate = Date.parse(response.Entity.QueuedDateUtc + "Z");
      const endDate = Date.parse(response.Entity.EndDateUtc + "Z");
      const modelTime = Math.abs(endDate - queuedDate);
      const queueTime = Math.abs(queuedDate - startDate);
      const fancyExecutionView = new Date(modelTime)
        .toISOString()
        .substr(11, 8);
      const fancyQueueView = new Date(queueTime).toISOString().substr(11, 8);

      response.Entity["QueueTime"] = fancyQueueView;
      response.Entity["ExecutionTime"] = fancyExecutionView;

      var executionCardsInfo = [
        {
          icon: Icons.StartTime,
          value: new Date(response.Entity.StartDateUtc).toLocaleString(),
        },
        {
          icon: Icons.EndTime,
          value: new Date(response.Entity.EndDateUtc).toLocaleString(),
        },
        {
          icon: Icons.ExecutionEngine,
          value: response.Entity.ExecutionEngineId,
        },
        {
          icon: Icons.QueueTime,
          value: response.Entity.QueueTime,
        },
        {
          icon: Icons.ExecutionTime,
          value: response.Entity.ExecutionTime,
        },
      ];
      setExecutionCardsInfo(executionCardsInfo);
      if (response.Entity.StatusAsString === "Error") {
        setView("sliderMenuItem_logs");
      } else {
        setView("sliderMenuItem_data");
      }
      return setExecutionInfo(response.Entity);
    } else {
      setExecutionCardsInfo([]);
      setView("sliderMenuItem_logs");
      return setExecutionInfo({});
    }
  };

  const getModelInfo = async (setModelInfo, setModelCardsInfo) => {
    if (qSource === "assignment") {
      return setModelInfo({});
    }
    const { response } = await new apiRequest(
      "GET /models/" + id,
      false
    ).send();
    if (response) {
      setLoading(false);
      var modelCardsInfo = [];
      if (response.Entity.Id) {
        Array.prototype.push.apply(modelCardsInfo, [
          {
            icon: Icons.Name,
            value: response.Entity.Name,
          },
          {
            icon: Icons.Created,
            value: response.Entity.CreatedDateUtc,
          },
        ]);
        if (response.Entity.UpdatedDateUtc) {
          modelCardsInfo.push({
            icon: Icons.Updated,
            value: response.Entity.UpdatedDateUtc,
          });
        }
        if (response.Entity.FileName) {
          modelCardsInfo.push({
            icon: Icons.ModelFile,
            value: response.Entity.FileName,
          });
        }
      }
      setModelCardsInfo(modelCardsInfo);
      return setModelInfo(response.Entity);
    } else {
      setModelCardsInfo([]);
      return setModelInfo({});
    }
  };

  const getExecutionLogs = async (setLogs) => {
    if (qSource === "assignment") {
      return setLogs([]);
    }
    const { response } = await new apiRequest(
      "GET /models/" + id + "/executions/" + executionId + "/logs",
      false
    ).send();
    if (response) {
      return setLogs(response.Entity.LogRecords);
    } else {
      return setLogs([]);
    }
  };

  const getExecutionArtifacts = async (setArtifacts) => {
    if (qSource === "assignment") {
      return setArtifacts([]);
    }
    const { response } = await new apiRequest(
      "GET /models/" + id + "/artifacts",
      false
    ).send();
    if (response) {
      return setArtifacts(response.Entities);
    } else {
      return setArtifacts([]);
    }
  };

  const createHeaders = () => {
    let _columns = [];
    if (executionDetails[0]) {
      _columns.push({
        Header: "Time",
        accessor: (item) => {
          return item.Time;
        },
        id: "time",
      });
      Object.keys(executionDetails[0].Values).map((header, i) => {
        _columns.push({
          Header: header,
          accessor: (item) => {
            return item.Values[header].toFixed(3);
          },
          id: i,
        });
      });
    }
    return _columns;
  };

  const createTable = () => {
    let res = [];
    if (executionDetails[0]) {
      let columns = createHeaders();
      let data = createContent();
      res.push(
        <div>
          <div className={styles.contentContainer}>
            <ResultsTable columns={columns} data={data} />
          </div>
        </div>
      );
    }
    return res;
  };

  const createContent = () => {
    let _data = [];
    if (executionDetails[0]) {
      for (var i = 0; i < executionDetails.length; i++) {
        _data.push(executionDetails[i]);
      }
    }
    return _data;
  };

  const InfoCards = () => {
    return (
      <div className={styles.topCardsHolder}>
        <InfoCard title="Model info" properties={modelCardsInfo}></InfoCard>

        <InfoCard title="Execution info" properties={executionCardsInfo}></InfoCard>

        {resultsCardsInfo.length > 0 &&
        <InfoCard title="Results info" properties={resultsCardsInfo}></InfoCard>
        }
      </div>
    );
  };

  const renderLogLines = () => {
    let logLines = [];
    for (var i = 0; i < logs.length; i++) {
      logLines.push(
        <>
          <span className={styles.logLine}>
            {logs[i].LoggedDateUtc}: [{logs[i].LogTypeAsString}] -{" "}
            {logs[i].Summary}
          </span>
          <br />
        </>
      );
    }
    return logLines;
  };

  const renderLogs = () => {
    return (
      <div className={styles.contentContainer}>
        <div className={styles.logsContainer}>{renderLogLines()}</div>
      </div>
    );
  };

  const renderCharts = () => {
    return (
      <div className={styles.contentContainer}>
        <div className="App">
          <div className={styles.chartContainer} data-cy={"resultsChart"}>
            <ResultsChart executionDetails={executionDetails} />
          </div>
        </div>
      </div>
    );
  };

  const renderControlSlider = () => {
    var menuItems = [];
    var defaultSelected = view;
    if (executionDetails[0]) {
      //defaultSelected = "sliderMenuItem_data";
      menuItems.push({
        name: "Data",
        id: "sliderMenuItem_data",
      });
      menuItems.push({
        name: "Charts",
        id: "sliderMenuItem_charts",
      });
    }
    menuItems.push({
      name: "Logs",
      id: "sliderMenuItem_logs",
    });

    return (
      <SliderMenu
        items={menuItems}
        selected={defaultSelected}
        callback={sliderMenuSelecionChanged}
      />
    );
  };

  const sliderMenuSelecionChanged = (selectedItemId) => {
    setView(selectedItemId);
  };
  console.log("Active view is " + view);
  return (
    <div>
      {loading && qSource !== "assignment" && (
        <div className={styles.topCardsHolder}>
          <div className={styles.skeletonFrame}>
            <Skeleton width={380} height={250} />
          </div>
          <div className={styles.skeletonFrame}>
            <Skeleton width={380} height={250} />
          </div>
          <div className={styles.skeletonFrame}>
            <Skeleton width={380} height={250} />
          </div>
        </div>
      )}
      {!loading && qSource !== "assignment" && <InfoCards />}
      {renderControlSlider()}
      {!!(view === "sliderMenuItem_data") && createTable()}
      {!!(view === "sliderMenuItem_charts") && renderCharts()}
      {!!(view === "sliderMenuItem_logs") && renderLogs()}
    </div>
  );
};

export default Execution;

exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".src-components-footer-___styles-module__footer___2j8xa{margin-top:auto;height:350px;width:100%;background:url(\"/assets/img/sdcloud-application-footer.png\") no-repeat 50% 0;background-size:cover;text-align:center}.src-components-footer-___styles-module__labCredentials___3q33a{color:#3F88C5;padding-left:10px;padding-top:10px;width:300px;font-size:17px;display:inline-block}.src-components-footer-___styles-module__version___1OuTW{color:#3F88C5;width:300px;font-size:17px;padding-left:10px;margin:auto}.src-components-footer-___styles-module__footerMenu___2YKXQ{font-size:18px;color:#3F88C5;margin-top:260px}.src-components-footer-___styles-module__footerMenu___2YKXQ a{text-decoration:none;color:#3F88C5;margin-left:10px;margin-right:10px}.src-components-footer-___styles-module__footerMenu___2YKXQ a:hover{border-bottom:1px solid #3F88C5}\n", ""]);

// Exports
exports.locals = {
	"footer": "src-components-footer-___styles-module__footer___2j8xa",
	"labCredentials": "src-components-footer-___styles-module__labCredentials___3q33a",
	"version": "src-components-footer-___styles-module__version___1OuTW",
	"footerMenu": "src-components-footer-___styles-module__footerMenu___2YKXQ"
};
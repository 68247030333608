import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import userState from "src/globalState/user";
import styles from "./styles.module.scss";
import UserMenu from "./userMenu";
import Index from "../feedbackForm";

const Header = () => {
  const location = useLocation();

  const [isFeedbackFormVisible, setFeedbackFormVisible] = useState(false);

  const toggleFeedbackFormVisibility = () => {
    setFeedbackFormVisible(!isFeedbackFormVisible);
  };

  const itemStyles = (pathname) => {
    return location && location.pathname === pathname
      ? `${styles.menuItem} ${styles.isActive}`
      : styles.menuItem;
  };

  return (
    <div className={styles.header}>
      <div className={styles.headerMenuLogo}>
        <UserMenu />
      </div>
      <div className={styles.menuContainer}>
        <div className={styles.sdCloudMenu}>
          <div className={itemStyles("/")}>
            <Link to="/">Dashboard</Link>
          </div>
          <div className={itemStyles("/models")}>
            <Link to="/models">My Models</Link>
          </div>
          <div className={itemStyles("/modelsrun")}>
            <Link to="/modelsrun">My Models Run</Link>
          </div>
          <div className={itemStyles("/assignments")}>
            <Link to="/assignments">
              Assignments<sup>&beta;</sup>
            </Link>
          </div>
        </div>
      </div>
      <div
        data-cy={"feedback"}
        className={styles.feedbackMenuButton}
        onClick={() => toggleFeedbackFormVisibility()}
      >
        <div className={styles.feedbackIcon}></div>
      </div>

      {isFeedbackFormVisible && (
        <Index
          toggleFeedbackFormVisibility={() => toggleFeedbackFormVisibility()}
        />
      )}
    </div>
  );
};

export default Header;

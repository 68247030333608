/**
 *  Assignments component
 */

import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { useHistory } from "react-router";
import styles from "./styles.module.scss";
import SliderMenu from "../../components/execution/components/sliderMenu/sliderMenu";
import apiRequest from "../../lib/apiRequest";
import { Link, useLocation } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import SDCModalDialog from "../common/sdcModalDialog/sdcModalDialog";
import { Store as store } from "react-notifications-component";
import SDButton from "../common/sdButton/sdButton";

const statusToColor = {
  Passed: "#6EFF6E",
  Rejected: "#FF6E6E",
  Submitted: "#F98C32",
  Enrolled: "#3F88C5",
};

const AssignmentsListRows = ({
  assignmentsList,
  flavour,
  enrollmentHandler,
}) => {
  let assignmentsRecords = [];
  if (assignmentsList) {
    for (var i = 0; i < assignmentsList.length; i++) {
      var key = "dataRow_" + flavour + "_" + i;
      var assignment = assignmentsList[i];
      const endDate = assignment.EndDateUtc
        ? new Date(Date.parse(assignment.EndDateUtc + "Z")).toLocaleString()
        : "Not set";
      const assignmentId = assignment.Id;
      assignmentsRecords.push(
        <div className={styles.assignmentsListRow} key={key}>
          <div className={styles.assignmentsListCell}>{assignment.Id}</div>
          <div className={styles.assignmentsListCell}>
            {assignmentsList[i].Secured && (
              <FontAwesomeIcon
                className={styles.assignmentsEnrollIcon}
                icon="lock"
              />
            )}
            {!assignmentsList[i].Secured && (
              <FontAwesomeIcon
                className={styles.assignmentsEnrollIcon}
                icon="lock-open"
              />
            )}
          </div>
          <div className={styles.assignmentsListCell}>
            <Link to={`/assignments/${assignment.Id}/assignmentDetails/`}>
              {assignment.Name}
            </Link>
          </div>
          {flavour !== "managed" && (
            <div className={styles.assignmentsListCell}>
              {assignment.AuthorUserName}
            </div>
          )}
          <div className={styles.assignmentsListCell}>{endDate}</div>
          {flavour === "managed-hidden" && (
            <div className={styles.assignmentsListCell}>
              {assignment.Statistics}
            </div>
          )}
          {flavour === "available" && (
            <div className={styles.assignmentsListActionsCell}>
              <FontAwesomeIcon
                className={styles.assignmentsEnrollIcon}
                icon="plus-circle"
                onClick={() => enrollmentHandler(assignmentId)}
              />
            </div>
          )}
          {flavour === "enrolled" && (
            <div
              className={styles.assignmentsListCell}
              style={{
                backgroundColor: statusToColor[assignment.StatusAsString],
              }}
            >
              {assignment.StatusAsString}
            </div>
          )}
        </div>
      );
    }
  }
  return assignmentsRecords;
};

const SkeletonRows = ({ flavour }) => {
  let skeletonRows = [];
  for (var i = 0; i < 10; i++) {
    var key = "skeletonRow_" + flavour + "_" + i;
    skeletonRows.push(
      <div className={styles.assignmentsListRow} key={key}>
        <div className={styles.assignmentsListCell}>
          <Skeleton />
        </div>
        <div className={styles.assignmentsListCell}>
          <Skeleton />
        </div>
        <div className={styles.assignmentsListCell}>
          <Skeleton />
        </div>
        {flavour !== "managed" && (
          <div className={styles.assignmentsListCell}>
            <Skeleton />
          </div>
        )}
        <div className={styles.assignmentsListCell}>
          <Skeleton />
        </div>
        <div className={styles.assignmentsListCell}>
          <Skeleton />
        </div>
        {flavour === "managed-hidden" && (
          <div className={styles.assignmentsListCell}>
            <Skeleton />
          </div>
        )}
        {flavour === "available" && (
          <div className={styles.assignmentsListCell}>
            <Skeleton />
          </div>
        )}
        {flavour === "enrolled" && (
          <div className={styles.assignmentsListCell}>
            <Skeleton />
          </div>
        )}
      </div>
    );
  }
  return skeletonRows;
};

const Assignments = () => {
  const [activeAssignmentsList, setAssignmentsList] = useState([]);
  const [managedAssignmentsList, setManagedAssignmentsList] = useState([]);
  const [availableAssignmentsList, setAvailableAssignmentsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [view, setView] = useState("");
  const [showCodeDialog, setShowCodeDialog] = useState("False");
  const [selectedAssignmentId, setSelectedAssignmentId] = useState(-1);
  const [assignmentAccessCode, setAssignmentAccessCode] = useState("");
  const [searchDisabled, setSearchDisabled] = useState(false);
  const [joinCode, setJoinCode] = useState("");
  const history = useHistory();
  const location = useLocation();
  const { id, assignmentId } = useParams();

  useEffect(() => {
    getUsersAssignments(setAssignmentsList);
    getManagedAssignments(setManagedAssignmentsList);
    getAvailableAssignments(setAvailableAssignmentsList);
    document.title = "Assignments and Challenges";
  }, []);

  useEffect(() => {
    const qAspect = new URLSearchParams(location.search).get("aspect");
    if (qAspect) {
      setView("sliderMenuItem_" + qAspect);
    } else {
      setView("sliderMenuItem_enrolled");
    }
  }, [location]);

  const getUsersAssignments = async (setAssignmentsList) => {
    return loadAndRenderAssignmentsList("/enrolled", setAssignmentsList);
  };

  const getManagedAssignments = async (setManagedAssignmentsList) => {
    return loadAndRenderAssignmentsList("", setManagedAssignmentsList);
  };

  const getAvailableAssignments = async (setAvailableAssignmentsList) => {
    return loadAndRenderAssignmentsList(
      "/available",
      setAvailableAssignmentsList
    );
  };

  const loadAndRenderAssignmentsList = async (path, setAssignmentsFunction) => {
    setLoading(true);
    let requestPath = "/assignments" + path;
    const { response } = await new apiRequest(
      "GET " + requestPath,
      false
    ).send();
    if (response) {
      setLoading(false);
      return setAssignmentsFunction(response.Entities);
    } else {
      return [];
    }
  };

  const enrollToAssignment = async (assignmentId) => {
    const selectedAssignment = availableAssignmentsList.filter(
      (assignmnet) => assignmnet.Id === assignmentId
    )[0];
    if (selectedAssignment && selectedAssignment.Secured) {
      setSelectedAssignmentId(assignmentId);
      setShowCodeDialog("True");
    } else {
      let requestPath = "/assignments/" + assignmentId + "/enrollment";
      const response = await new apiRequest(
        "POST " + requestPath,
        false
      ).send();
      if (response.response !== false) {
        getUsersAssignments(setAssignmentsList);
        getAvailableAssignments(setAvailableAssignmentsList);
      }
      responseToNotification(response);
    }
  };

  const handleCodeChange = (e) => {
    setAssignmentAccessCode(e.target.value);
  };

  const closeCodeDialog = () => {
    setShowCodeDialog("False");
    setAssignmentAccessCode("");
  };

  const responseToNotification = (response) => {
    if (response.response !== false) {
      store.addNotification({
        title: "Success",
        message: "Enrollment successful",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
        },
      });
    } else {
      store.addNotification({
        title: "Failed to enroll",
        message: `${response.error.Message}`,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
        },
      });
    }
  };

  const enrollToAssignmentWithCode = async () => {
    let requestPath = "/assignments/" + selectedAssignmentId + "/enrollment";
    const response = await new apiRequest(
      "POST " + requestPath,
      false
    ).sendJSON({ AccessCode: assignmentAccessCode });
    responseToNotification(response);
    closeCodeDialog();
    if (response.response) {
      getUsersAssignments(setAssignmentsList);
      getAvailableAssignments(setAvailableAssignmentsList);
    }
  };

  const renderActiveAssignments = () => {
    return (
      <div
        className={styles.assignmentsListContainer}
        data-cy={"assignmentsTable"}
      >
        {renderAssignmentsTableHeader("enrolled")}

        {loading && <SkeletonRows flavour="enrolled" />}
        {!loading && (
          <AssignmentsListRows
            assignmentsList={activeAssignmentsList}
            flavour="enrolled"
          />
        )}
      </div>
    );
  };

  const createNewAssignment = () => {
    history.push({
      pathname: "/newAssignment",
    });
  };

  const renderManagedAssignments = () => {
    return (
      <div className={styles.managedTabContainer}>
        <SDButton icon={<FontAwesomeIcon className={styles.plusIcon} icon="plus-circle" />}
          name={'Create assignment'}
          appearance={'primary'} style={{ alignSelf: 'end', marginRight: '15%' }} onClick={createNewAssignment} />

        <div
          className={styles.assignmentsListContainer}
          data-cy={"assignmentsTable"}
        >
          {renderAssignmentsTableHeader("managed")}
          {loading && <SkeletonRows flavour="managed" />}
          {!loading && (
            <AssignmentsListRows
              assignmentsList={managedAssignmentsList}
              flavour="managed"
            />
          )}
        </div>
      </div>
    );
  };

  const enableSearch = () => {
    console.log("Re-enabling search");
    
  };

  const findAssignmentByJoinCode = async () => {
    console.log("Disabling search");
    setSearchDisabled(true);
    let requestPath = "/assignments/byJoinCode/" + joinCode;
    const response = await new apiRequest(
      "GET " + requestPath,
      false
    ).send();
    setSearchDisabled(false);
    if (response.response === false) {
      store.addNotification({
        title: "Not found",
        message: "Assignment with join code " + joinCode + " was not found",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
        },
      });
    } else {
      let hiddenAssignmentPath = `/assignments/${response.response.Entity.Id}/assignmentDetails/`;
      console.log("Redirecting to: " + hiddenAssignmentPath);
      history.push({
        pathname: hiddenAssignmentPath,
        search: `?joinCode=${joinCode}`
      }); 
    }
    
  };

  const renderAvailableAssignments = () => {
    return (
      <div className={(searchDisabled) ? styles.disabledGroup : ''}>
        <div style={{
          width: '70%',
          textAlign: 'right',
          marginTop: '20px',
          fontSize: '20px',
          marginLeft: 'auto',
          marginRight: 'auto'
        }}>
          <span>View by join code: </span>
          <input
            className={styles.modelInput}
            id={'accessCode'}
            placeholder={""}
            style={{ marginLeft: '10px', width: '160px' }}
            type="text"
            onChange={function (e) {
               setJoinCode(e.target.value)
            }}
          />
          <button
            className={styles.buttonModel}
            style={{ width: '60px', marginLeft: '10px' }}
            onClick={findAssignmentByJoinCode}
          >
            Find
          </button>
        </div>
        <div
          className={styles.assignmentsListContainer}
          data-cy={"assignmentsTable"}
        >
          {renderAssignmentsTableHeader("available")}
          {loading && <SkeletonRows flavour="available" />}
          {!loading && (
            <AssignmentsListRows
              assignmentsList={availableAssignmentsList}
              flavour="available"
              enrollmentHandler={enrollToAssignment}
            />
          )}
        </div>
      </div>
    );
  };

  const renderAssignmentsTableHeader = (flavour) => {
    return (
      <div
        className={styles.assignmentsListHeaderRow}
        data-cy={"assignmentsTableHeader"}
      >
        <div className={styles.assignmentsListCell}>Id</div>
        <div className={styles.assignmentsListCell}></div>
        <div className={styles.assignmentsListCell}>Name</div>
        {flavour !== "managed" && (
          <div className={styles.assignmentsListCell}>Author</div>
        )}
        <div className={styles.assignmentsListCell}>End date</div>
        {flavour === "managed-hidden" && (
          <div className={styles.assignmentsListCell}>
            <div>Statistics</div>
            <div>
              E/S/G/A/R
              <FontAwesomeIcon
                icon={faQuestionCircle}
                className={styles.statisticsQuestionIcon}
              />
            </div>
          </div>
        )}
        {flavour === "available" && (
          <div className={styles.assignmentsListActionsCell}>Actions</div>
        )}
        {flavour === "enrolled" && (
          <div className={styles.assignmentsListCell}>Status</div>
        )}
      </div>
    );
  };

  const renderControlSlider = () => {
    var menuItems = [];
    var defaultSelected = view;
    menuItems.push({
      name: "My Active Assignments",
      id: "sliderMenuItem_enrolled",
    });
    menuItems.push({
      name: "Managed Assignments",
      id: "sliderMenuItem_managed",
    });
    menuItems.push({
      name: "Available Assignments",
      id: "sliderMenuItem_available",
    });

    return (
      <SliderMenu
        className={styles.assignmentsSlider}
        items={menuItems}
        selected={defaultSelected}
        callback={sliderMenuSelectionChanged}
      />
    );
  };

  const sliderMenuSelectionChanged = (selectedItemId) => {
    if (selectedItemId !== view) {
      const selectedAspect = selectedItemId
        .toString()
        .replace("sliderMenuItem_", "");
      history.push({ search: `?aspect=${selectedAspect}` });
    }
  };

  const dialogBody = (
    <div>
      Please, enter access code to enroll to this assignment
      <input
        type="text"
        style={{
          width: "200px",
          marginLeft: "auto",
          marginRight: "auto",
          display: "block",
          marginTop: "15px",
        }}
        onChange={handleCodeChange}
        value={assignmentAccessCode}
      />
    </div>
  );

  return (
    <>
      <SDCModalDialog
        height="200"
        show={showCodeDialog}
        title="Access key is required"
        body={dialogBody}
        onCancel={closeCodeDialog}
        onOk={enrollToAssignmentWithCode}
      />
      <div className={styles.assignments}>
        <div className={styles.assignmentsContainer}>
          <div className={styles.assignmentsRow}>
            <div className={styles.assignmentsHeader}>
              Assignments and Challenges
            </div>

            <div className={styles.infoBox}>
              <div className={styles.infoBoxLeft}>
                <span
                  className={styles.infoBoxText}
                  style={{ fontSize: "4rem" }}
                >
                  &beta;
                </span>
              </div>
              <div className={styles.infoBoxRight}>
                <span
                  className={styles.infoBoxText}
                  style={{ fontSize: "1rem" }}
                >
                  Assignments is the new and rapidly evolving feature of our
                  solutions. Since it was recently added, we will keep it marked
                  in beta-state, because we are expecting updates and
                  improvements delivered soon. Please, share your feedback with
                  us to help making sdCloud better for everyone using our
                  feedback form in the right bottom corner!
                </span>
              </div>
            </div>

            <div>
              {renderControlSlider()}
              {!!(view === "sliderMenuItem_enrolled") &&
                renderActiveAssignments()}
              {!!(view === "sliderMenuItem_managed") &&
                renderManagedAssignments()}
              {!!(view === "sliderMenuItem_available") &&
                renderAvailableAssignments()}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Assignments;

import React, { Component } from "react";
import styles from "./styles.module.scss";
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Gallery extends Component {
    render() {
        return (
            <div className={styles.gallery}>
                <div className={styles.galleryHeader}>Models Gallery</div>
                <div className={styles.allModels}>
                    <div className={styles.model}>
                        <div className={`${styles.modelHeader}`}>
                            Teacup
                        </div>

                        <div className={styles.modelBody}>
                            <div className={styles.mainText}>
                            Товарищи! начало повседневной работы по формированию позиции позволяет оценить значение систем массового участия. С другой стороны новая модель организационной деятельности влечет за собой процесс внедрения и модернизации новых предложений.
                            </div>
                            <div className={styles.footerButton}>
                                <div className={`${styles.buttonModel}`}>
                                    Download
                                </div>
                                <div className={`${styles.buttonModel} ${styles.buttonCopy}`}>
                                    Copy
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.model}>
                        <div className={`${styles.modelHeader} ${styles.vensim}`}>
                            Teacup
                        </div>
                        <div className={styles.modelBody}>
                            <div className={styles.mainText}>
                                Товарищи! начало повседневной работы по формированию позиции позволяет оценить значение систем массового участия. С другой стороны новая модель организационной деятельности влечет за собой процесс внедрения и модернизации новых предложений.
                            </div>
                            <div className={styles.footerButton}>
                                <div className={`${styles.buttonModel}`}>
                                    Download
                                </div>
                                <div className={`${styles.buttonModel} ${styles.buttonCopy}`}>
                                    Copy
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.model}>
                        <div className={`${styles.modelHeader}`}>
                            Teacup
                        </div>

                        <div className={styles.modelBody}>
                            <div className={styles.mainText}>
                                Товарищи! начало повседневной работы по формированию позиции позволяет оценить значение систем массового участия. С другой стороны новая модель организационной деятельности влечет за собой процесс внедрения и модернизации новых предложений.
                            </div>
                            <div className={styles.footerButton}>
                                <div className={`${styles.buttonModel}`}>
                                    Download
                                </div>
                                <div className={`${styles.buttonModel} ${styles.buttonCopy}`}>
                                    Copy
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.model}>
                        <div className={`${styles.modelHeader}`}>
                            Teacup
                        </div>

                        <div className={styles.modelBody}>
                            <div className={styles.mainText}>
                                Товарищи! начало повседневной работы по формированию позиции позволяет оценить значение систем массового участия. С другой стороны новая модель организационной деятельности влечет за собой процесс внедрения и модернизации новых предложений.
                            </div>
                            <div className={styles.footerButton}>
                                <div className={`${styles.buttonModel}`}>
                                    Download
                                </div>
                                <div className={`${styles.buttonModel} ${styles.buttonCopy}`}>
                                    Copy
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.model}>
                        <div className={`${styles.modelHeader}`}>
                            Teacup
                        </div>

                        <div className={styles.modelBody}>
                            <div className={styles.mainText}>
                                Товарищи! начало повседневной работы по формированию позиции позволяет оценить значение систем массового участия. С другой стороны новая модель организационной деятельности влечет за собой процесс внедрения и модернизации новых предложений.
                            </div>
                            <div className={styles.footerButton}>
                                <div className={`${styles.buttonModel}`}>
                                    Download
                                </div>
                                <div className={`${styles.buttonModel} ${styles.buttonCopy}`}>
                                    Copy
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.model}>
                        <div className={`${styles.modelHeader}`}>
                            Teacup
                        </div>

                        <div className={styles.modelBody}>
                            <div className={styles.mainText}>
                                Товарищи! начало повседневной работы по формированию позиции позволяет оценить значение систем массового участия. С другой стороны новая модель организационной деятельности влечет за собой процесс внедрения и модернизации новых предложений.
                            </div>
                            <div className={styles.footerButton}>
                                <div className={`${styles.buttonModel}`}>
                                    Download
                                </div>
                                <div className={`${styles.buttonModel} ${styles.buttonCopy}`}>
                                    Copy
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Gallery;

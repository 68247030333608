exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".src-components-common-sdButton-___styles-module__sdButtonPrimary___2U7u_{font-size:16px;display:inline-block;margin-top:5px;color:#ffffff;text-align:center;padding:13px 20px;float:right;background:#3F88C5;border-radius:.5rem}.src-components-common-sdButton-___styles-module__sdButtonPrimary___2U7u_:hover{cursor:pointer;opacity:0.7;-webkit-transition-duration:0.3s;transition-duration:0.3s}.src-components-common-sdButton-___styles-module__sdButtonOutline___3PJ7W{font-size:16px;display:inline-block;margin-top:5px;color:black;text-align:center;padding:13px 20px;float:right;border-radius:.5rem;border:1px #3F88C5 solid}.src-components-common-sdButton-___styles-module__sdButtonOutline___3PJ7W:hover{cursor:pointer;border:1px #bbc5ff solid;-webkit-transition-duration:0.3s;transition-duration:0.3s}.src-components-common-sdButton-___styles-module__sdButtonSecondary___1ynyc{font-size:16px;font-weight:bold;display:inline-block;margin-top:5px;color:#3F88C5;text-align:center;padding:13px 20px;float:right;background:#e7edff;border-radius:.5rem}.src-components-common-sdButton-___styles-module__sdButtonSecondary___1ynyc:hover{cursor:pointer;opacity:0.7;-webkit-transition-duration:0.3s;transition-duration:0.3s}.src-components-common-sdButton-___styles-module__sdButtonWarning___1bOcj{font-size:16px;display:inline-block;margin-top:5px;color:white;text-align:center;padding:13px 20px;float:right;background:#ff401d;border-radius:.5rem}.src-components-common-sdButton-___styles-module__sdButtonWarning___1bOcj:hover{cursor:pointer;opacity:0.7;-webkit-transition-duration:0.3s;transition-duration:0.3s}\n", ""]);

// Exports
exports.locals = {
	"sdButtonPrimary": "src-components-common-sdButton-___styles-module__sdButtonPrimary___2U7u_",
	"sdButtonOutline": "src-components-common-sdButton-___styles-module__sdButtonOutline___3PJ7W",
	"sdButtonSecondary": "src-components-common-sdButton-___styles-module__sdButtonSecondary___1ynyc",
	"sdButtonWarning": "src-components-common-sdButton-___styles-module__sdButtonWarning___1bOcj"
};
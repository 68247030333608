import React from "react";
import styles from "./styles.module.scss";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ModelsRows = ({ attachmentsList, deleteAttachment, assignmentId, enrollmentId}) => {
    let modelsRecords = [];

    let modelAttachments = attachmentsList.filter(x => x.AttachmentType == 1);
    for (var j = 0; j < modelAttachments.length; j++) {
        var key = "dataRow_" + j;
        var attachment = modelAttachments[j];
        let path="";
        if (enrollmentId) {
            path=`/models/0/modeldetails/?source=assignment&assignment-id=${assignmentId}&attachment-id=${attachment.Id}&enrollment-id=${enrollmentId}`;
        } else {
            path=`/models/0/modeldetails/?source=assignment&assignment-id=${assignmentId}&attachment-id=${attachment.Id}`;
        }
        modelsRecords.push(
            <div className={styles.assignmentsListRow} key={key}>
                <div className={styles.assignmentsListCell}>{attachment.Id}</div>
                <div className={styles.assignmentsListCell}>
                    {attachment.AttachmentId}
                </div>
                <div className={styles.assignmentsListCell}>
                    <Link to={path}>{attachment.Metadata.ModelName}</Link>
                </div>
                {deleteAttachment &&
                    <div className={styles.assignmentsListActionsCell}>
                        <FontAwesomeIcon className={styles.attachmentDeleteIcon}
                            icon="trash"
                            onClick={() => deleteAttachment(attachment.Id)} />
                    </div>}
            </div>);
    }
    return modelsRecords;
};

export default ModelsRows;
import React, {useState, useEffect} from "react";
import styles from "./styles.module.scss";
import {Link} from 'react-router-dom';
import apiRequest from 'src/lib/apiRequest';
import {UI_VERSION} from "../../env/env";
import moment from "moment";


const Footer = () => {
  const [serverVersion, setServerVersion] = useState([]);
  useEffect(() => {
    getServerVersion(setServerVersion);
  }, []);

  const getServerVersion = async (setServerVersion) => {
    const {response} = await new apiRequest('GET /profile/me', false).send();
    return setServerVersion(response.Entity.Server.Version);
  };

  return (
    <div className={styles.footer}>
      <div className={styles.footerMenu}>
        <Link to="/">About us</Link>
        |
        <Link to="/">Our blog</Link>
      </div>
      <div className={styles.labCredentials}>
        &#9400; {moment().year()} by sdCloud Team
      </div>
      <div className={styles.version}>
        Version: {serverVersion}/{UI_VERSION}
      </div>
    </div>
  )
};

export default Footer;

import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router";
import { Link, useLocation } from "react-router-dom";
import { Store as store } from "react-notifications-component";

import ModelsRun from "../modelsRun";
import styles from "./styles.module.scss";
import ExecutionStat from "./executionStat/executionStat";
import LastExecInfo from "./lastExecInfo/lastExecInfo";
import AvailableAct from "./availableAct/availableAct";
import ModelConfig from "./modelConfig/modelConfig";

import { Controlled as CodeMirror } from "react-codemirror2";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/material.css";
require("codemirror/mode/xml/xml");
require("codemirror/mode/javascript/javascript");

import apiRequest from "../../lib/apiRequest";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { faEdit } from "@fortawesome/free-solid-svg-icons";

library.add(fas, faEdit);

const ModelDetails = () => {
  const { id } = useParams();

  // Taken from /model/{id} api response
  const [modelMetadata, setModelMetadata] = useState(false);
  // Taken from server response for model exec history
  const [allExecutions, setAllExecutions] = useState(false);
  // Will be taken from top of the model exec history
  const [lastExecution, setLastExecution] = useState(false);

  const headerValue = useState(null);

  const search = useLocation().search;
  const qSource = new URLSearchParams(search).get("source");
  const qAssignmentId = new URLSearchParams(search).get("assignment-id");
  const qEnrollmentId = new URLSearchParams(search).get("enrollment-id");
  const qAttachmaneId = new URLSearchParams(search).get("attachment-id");

  const codemirrorRef = React.useRef();

  useEffect(() => {
    if (codemirrorRef.current) {
      codemirrorRef.current.editor.display.wrapper.style.height = "600px";
    }
    getModelMetadata(id);
    getModelExecutionHistory(id);
  }, []);

  const getModelMetadata = async (id) => {
    let path = "/models/" + id;
    if (qSource === "assignment") {
      if (qEnrollmentId) {
        path =
          "/assignments/" +
          qAssignmentId +
          "/enrollments/" +
          qEnrollmentId +
          "/submission/attachments/" +
          qAttachmaneId +
          "/asmodel";
      } else {
        path =
          "/assignments/" +
          qAssignmentId +
          "/submission/attachments/" +
          qAttachmaneId +
          "/asmodel";
      }
    }
    const { response } = await new apiRequest("GET " + path, false).send();
    if (response) {
      setModelMetadata(response.Entity);
    } else {
      return false;
    }
  };

  const getModelExecutionHistory = async (id) => {
    const { response } = await new apiRequest(
      "GET /models/" + id + "/executions",
      false
    ).send();
    if (response) {
      const executions = response.Entities;
      if (executions.length > 0) {
        setLastExecution(executions[0]);
        setAllExecutions(executions);
      }
    }
  };

  const handleEditHeader = async () => {
    await new apiRequest(`POST /models/${modelMetadata.Id}`).sendJSON({
      Name: modelMetadata.Name,
      Description: modelMetadata.Description,
    });
  };

  const handleRunButtonClick = async (modelId, type) => {
    var response = await new apiRequest(
      `POST /models/${modelId}/executions`
    ).sendJSON({ Type: type });
    if (response.response) {
      store.addNotification({
        title: "Success",
        message: "Execution queued",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
        },
      });
    } else {
      store.addNotification({
        title: "Error",
        message: `${response.error.Message}`,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
        },
      });
    }
  };

  function handleEnterPressEdit(e) {
    if (e.keyCode === 13 && !e.ctrlKey) {
      handleEditHeader();
      e.preventDefault();
    }
  }

  return (
    <div className={styles.modelDetailsContainer}>
      <div className={styles.headerWrapper}>
        <h1
          contenteditable="true"
          onInput={(e) => (modelMetadata.Name = e.currentTarget.textContent)}
          onKeyDown={handleEnterPressEdit}
          className={styles.modelDetailsHeader}
        >
          {modelMetadata ? modelMetadata.Name : "Loading..."}
          <FontAwesomeIcon className={styles.titleEditIcon} icon="edit" />
        </h1>
      </div>
      <div
        contentEditable="true"
        onInput={(e) =>
          (modelMetadata.Description = e.currentTarget.textContent)
        }
        onKeyDown={handleEnterPressEdit}
        className={styles.modelDetailsContainerDesc}
      >
        <p>
          {modelMetadata ? modelMetadata.Description : ""}
          <FontAwesomeIcon className={styles.descriptionEditIcon} icon="edit" />
        </p>
      </div>
      {qSource !== "assignment" && (
        <div className={styles.modelDetailsContainerExec}>
          <ExecutionStat executionsData={allExecutions} />
          <LastExecInfo executionData={lastExecution} />
          {modelMetadata.FileName && <AvailableAct
            id={id}
            onRunButtonClick={(type) => handleRunButtonClick(id, type)}
            modelType={modelMetadata.FileName.split('.')[modelMetadata.FileName.split('.').length-1].toLowerCase()}
          />}
        </div>
      )}
      <div className={styles.modelDetailsContainerConfig}>
        {qSource === "assignment" && <ModelConfig modelData={modelMetadata} />}
        {qSource === undefined && <ModelConfig modelId={id} />}
      </div>
      {qSource !== "assignment" && (
        <div className={styles.modelDetailsContainerExecHistory}>
          <ModelsRun stateModelId={id} />
        </div>
      )}
      {qSource === "assignment" && (
        <div className={styles.modelDetailsContainerSources}>
          <h2 className={styles.modelConfigHeader}>Model source code</h2>
          <CodeMirror
            className={styles.editor}
            value={modelMetadata.Content}
            style={{ height: "800px" }}
            ref={codemirrorRef}
            options={{
              mode: "xml",
              theme: "material",
              lineNumbers: true,
            }}
          />
        </div>
      )}
    </div>
  );
};

export default ModelDetails;

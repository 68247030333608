/**
 *  Header component
 */

import React, { Component } from 'react';
import userState from 'src/globalState/user';
import styles from './styles.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { Wrapper, Button, Menu, MenuItem } from 'react-aria-menubutton';

const menuItemWords = [
    'Profile',
    'Log out',
];


class UserMenu extends React.Component {

    constructor(props) {
        super(props);
    }

    handleSelection(value, event) {
        if (value === 'Log out') {
            userState.logout();
        }
    }

    render() {
        const username = userState.user && userState.user.UserName;

        const menuItems = menuItemWords.map((word, i) => {
            return (
                <li key={ i }>
                    <MenuItem className={ styles.AriaMenuButtonMenuItem }>
                        { word }
                    </MenuItem>
                </li>
            );
        });

        return (
            <div id="userMenuContainer" className={ styles.menuContainer }>
                <Wrapper
                    className={ styles.AriaMenuButton }
                    onSelection={ this.handleSelection.bind(this) }
                >
                    <Button className={ styles.AriaMenuButtonButton }>
                        <div className={ styles.iconUser }>
                            <FontAwesomeIcon size="2x" color="#fff" icon={ faUser } />
                        </div>
                        <div className={ styles.username } onClick={ () => {
                            this.isOpenMenu = !this.isOpenMenu;
                        } }>
                            { username }
                        </div>
                    </Button>
                    <Menu className={ styles.AriaMenuButtonMenu }>
                        <ul>{ menuItems }</ul>
                    </Menu>
                </Wrapper>
            </div>
        );
    }
}


export default UserMenu;

import React, { useState, useEffect } from "react";
import ReactMarkdown from 'react-markdown';
import SliderMenu from 'src/components/execution/components/sliderMenu/sliderMenu';
import styles from "./styles.module.scss";
import { Controlled as CodeMirror } from 'react-codemirror2';
require('codemirror/mode/markdown/markdown');

const MarkdownComponent = ({ markdownContent, updateRoot, isEditable }) => {
    const [view, setView] = useState(isEditable ? "sliderMenuItem_edit" : "sliderMenuItem_preview");
    const [editorContent, setContent] = useState(null);
    const codemirrorRef = React.useRef();

    useEffect(() => {
        setContent(markdownContent);
      }, [markdownContent]);

    const renderPreview = () => {
        return (
            <div className={styles.markdownContainer}>
                <ReactMarkdown children={editorContent ? editorContent : "No content provided." }></ReactMarkdown>
            </div>
        )
    }

    const renderEdit = () => {
        return (
            <div className={styles.markdownContainer}>
                <CodeMirror className={styles.editor}
                value={editorContent}
                style={{ "height": "800px" }}
                ref={codemirrorRef}
                options={{
                    mode: 'markdown',
                    theme: 'material',
                    lineNumbers: true
                }}

                onBeforeChange={(editor, data, value) => {
                    setContent(value);
                    updateRoot(value);
                }}

                onChange={(editor, data, value) => {
                    setContent(value);
                    updateRoot(value);
                        }} />
            </div>);
    }

    const renderControlSlider = () => {
        var menuItems = [];
        var defaultSelected = view;

        if (isEditable) {
            menuItems.push({
                name: "Edit",
                id: "sliderMenuItem_edit"
            });
            menuItems.push({
                name: "Preview",
                id: "sliderMenuItem_preview"
            });
        } else {
            menuItems.push({
                name: "Description",
                id: "sliderMenuItem_preview"
            });
        }

        return (
            <SliderMenu items={menuItems} selected={defaultSelected} callback={sliderMenuSelecionChanged} />
        );
    }

    const sliderMenuSelecionChanged = (selectedItemId) => {
        setView(selectedItemId)
    }

    return (
        <div>
            {renderControlSlider()}
            {!!(view === "sliderMenuItem_preview") && renderPreview()}
            {!!(view === "sliderMenuItem_edit") && renderEdit()}
        </div>);
}

export default MarkdownComponent;
import { observable, action } from 'mobx';
import apiRequest, { getUserAccessToken } from 'src/lib/apiRequest';


class User {
    @observable user = false;
    @observable authorized = false;
    @observable initialFetching = true;
    accessToken = null;

    constructor() {
        this.fetchProfile().catch(console.error);
    }

    @action
    async fetchProfile() {
        const accessToken = getUserAccessToken();
        this.accessToken = accessToken;

        try {
            if (accessToken) {
                const { response } = await new apiRequest('GET /profile/me').send();
                this.user = response.Entity;
                this.authorized = true;
            }
        }
        catch (e) {
            this.user = false;
            this.authorized = false;

            throw e;
        }
        finally {
            this.initialFetching = false;
        }
    }

    @action
    async signup(data, recaptchaResponse) {
        const { response, error } = await new apiRequest('POST /profile' + '?g-recaptcha-response=' + recaptchaResponse, false).sendJSON(data);

        if (response) {
            this.user = data;
            this.authorized = true;
            if (response.Entity && response.Entity.Token) {
                window.localStorage.setItem('accessToken', response.Entity.Token);
            }
            else {
                throw new Error(`Unexpected server registration error`);
            }
        }

        return {response, error};
    };

    @action logout() {
        window.localStorage.removeItem('accessToken');

        this.user = false;
        this.authorized = false;
    }

    @action
    async login(data) {
        const {response, error} = await new apiRequest('POST /profile/login', false).sendJSON(data);

        if (response) {
            this.user = data;
            this.authorized = true;
            if (response.Entity && response.Entity.Token) {
                window.localStorage.setItem('accessToken', response.Entity.Token);
            }
            return { response };
        }
        return { response, error };
    }
}

export default new User();
import React, { useState } from "react";
import styles from "./styles.module.scss";
import apiRequest from "src/lib/apiRequest";

const positiveImpression = "Positive";
const neutralImpression = "Neutral";
const negativeImpression = "Negative";

const shareFeedbackMessage =
  "Please, share your feedback, we would love to hear from you :)";

const impressions = [positiveImpression, neutralImpression, negativeImpression];

const PositiveMessage = () => (
  <>
    We are happy to see your feedback and know that your positive about our
    work. Give us a minute to review your comments and we will do our best to
    keep you pleased and interested in our work
  </>
);

const NeutralMessage = () => (
  <>
    Thank you so much for your input, we are pleased to receive your feedback
    and will review it carefully. We are working hard to be better day by day
    and we are thankful for your help with this
  </>
);

const NegativeMessage = () => (
  <>
    We are sorry that we haven't meet your expectations, but we are thankful for
    your feedback and will try to do our best to became better as soon as we can
  </>
);

const SendMessageForm = (props) => {
  const [submitted, setSubmitted] = useState(false);
  const [impression, setImpression] = useState(neutralImpression);
  const [details, setDetails] = useState("");
  let componentTitleMessage;

  const handleButtonClick = () => {
    if (!submitted) {
      if(details.trim() !== ''){
        sendUserFeedback();
        setSubmitted(true);
      }
    } else {
      props.toggleFeedbackFormVisibility();
    }
  };

  const sendUserFeedback = () => {
    new apiRequest("POST /profile/feedback", false).sendJSON({
      Impression: impression,
      Message: details,
      Source: "WebUI",
      Location: "some-page-in-app",
    });
  };

  switch (impression) {
    case positiveImpression:
      componentTitleMessage = <PositiveMessage />;
    case negativeImpression:
      componentTitleMessage = <NegativeMessage />;
    default:
      componentTitleMessage = <NeutralMessage />;
  }

  return (
    <div className={styles.feedbackForm}>
      <div className={styles.feedbackTitle}>
        {submitted ? componentTitleMessage : shareFeedbackMessage}
      </div>

      {!submitted && (
        <div>
          <div className={styles.feedbackItemCaption}>
            Overall impression of your feedback is:
          </div>

          <select
            id="userFeedbackImpression"
            className={styles.feedbackImpression}
            name="Impression"
            value={impression}
            data-cy={"feedbackImpression"}
            onChange={(e) => setImpression(e.target.value)}
          >
            {impressions.map((item, i) => (
              <option key={i} value={item}>
                {item}
              </option>
            ))}
          </select>

          <div className={styles.feedbackItemCaption}>
            Help us with some more details:
          </div>

          <textarea
            id="userFeedbackDetails"
            className={styles.feedbackDetails}
            name="Message"
            value={details}
            data-cy={"feedbackDetails"}
            onChange={(e) => setDetails(e.target.value)}
          />
        </div>
      )}

      <div
        className={styles.feedbackSendButton}
        onClick={() => handleButtonClick()}
      >
        {submitted ? "Close" : "Send!"}
      </div>
    </div>
  );
};

export default SendMessageForm;

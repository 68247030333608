import axios from "axios";
import {API_BASE_URL} from "../env/env";

class apiRequest {
    __unifyErrorsHandler = true;
    __method = 'GET';
    __url = '/';
    __options = {
        headers: {},
    };
    __data = null;
    __onUploadProgress = false;


    constructor(url = 'GET /', unifyErrorsHandler = true) {
        this.__unifyErrorsHandler = unifyErrorsHandler;
        const spaceIndex = url.indexOf(' ');
        this.__method = url.substr(0, spaceIndex).trim().toUpperCase();
        this.__url = url.substr(spaceIndex + 1).trim();
    }

    paramsToUrl = (params = {}, parentKey = '') => {
        const esc = encodeURIComponent;
        return Object.keys(params)
                     .map(k => {
                         let key = k;
                         if (parentKey) {
                             key = `${parentKey}[${k}]`;
                         }
                         if (params[k] && typeof params[k] === 'object') {
                             return this.paramsToUrl(params[k], k);
                         }
                         return esc(key) + '=' + esc(params[k]);
                     })
                     .join('&');
    };

    qs(params = {}) {
        const query = this.paramsToUrl(params);

        this.__url += (this.__url.indexOf('?') === -1 ? '?' : '&') + query;

        return this;
    }

    onUploadProgress(func) {
        this.__onUploadProgress = func;

        return this;
    }

    options(options = {}) {
        this.__options = {
            ...this.options,
            ...options,
        };

        return this;
    }

    async __send() {
        let options = {
            ...this.__options,
            method: this.__method,
        };
        if (this.__data !== null) options.data = this.__data;

        const userAccessToken = getUserAccessToken();
        if (userAccessToken) options.headers['Authorization'] = 'sdcToken ' + userAccessToken;

        let baseUrl = window.API_BASE_URL || API_BASE_URL;
        options.url = baseUrl + this.__url;

        // Upload progress
        options.onUploadProgress = (progressEvent) => {
            let uploadPercentage = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total));
            if (typeof this.__onUploadProgress === 'function') {
                this.__onUploadProgress(uploadPercentage);
            }
        };

        let response;

        let error;

        try {
            response = await axios(options);
        } catch (e) {
            error = e;
            response = e.response;
            return {response: false, error: response.data};
        }

        let resp = response.data;

        return {response: resp};
    }

    async sendJSON(data = {}) {
        this.__data = JSON.stringify(data);

        this.__options.headers = {
            'Content-Type': 'application/json',
        };
        return this.__send();
    }

    async sendModelFile(fileObject) {
        this.__data = new FormData();

        this.__data.append("modelName", fileObject);

        this.__options.headers = {
            'Content-Type': 'multipart/form-data',
        };
        return this.__send();
    }

    async send(data = null) {
        this.__data = data;

        return this.__send();
    }
}


export function getUserAccessToken() {
    return window.localStorage.getItem('accessToken');
}

export default apiRequest;

import React from "react";
/* Styles of the component */
import styles from "./styles.module.scss";
import { useTable, usePagination } from 'react-table';
import TablePaginator from 'src/components/execution/components/tablePaginator/tablePaginator'

function Table({ columns, data }) {


const { 
        getTableProps, 
        getTableBodyProps, 
        headerGroups, 
        prepareRow, 
        page, 
        canPreviousPage, 
        canNextPage, 
        pageOptions, 
        pageCount, 
        gotoPage, 
        nextPage, 
        previousPage, 
        setPageSize, 
        state: { pageIndex, pageSize } 
        } = useTable({ 
        columns, 
        data, 
        initialState: { pageIndex: 0 }}, 
        usePagination);
    return (
        <>
            <div className={styles.resultsTableWrapper}>
                <table {...getTableProps()}>
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => <th {...column.getHeaderProps()}>{column.render('Header')}</th>)}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row, i) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                        return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>

            <div className={styles.pagination}>
                <button className={styles.mr_2} onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                    {'<<'}
                </button>
                <button className={styles.mr_2} onClick={() => previousPage()} disabled={!canPreviousPage}>
                    {'<'}
                </button>
                <button className={styles.mr_2} onClick={() => nextPage()} disabled={!canNextPage}>
                    {'>'}
                </button>
                <button className={styles.mr_2} onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                    {'>>'}
                </button>
                <span>
                    Page
                    <strong className={styles.ml_2}>
                        {pageIndex + 1}
                    </strong>
                </span>
                <span>
                    | Go to page:
                    <input className={styles.ml_2}
                        type="number"
                        defaultValue={pageIndex + 1}
                        onChange={e => {
                            const page = e.target.value ? Number(e.target.value) - 1 : 0;
                            gotoPage(page);
                        }}
                        style={{ width: '100px' }}
                    />
                </span> 
                <select className={styles.ml_2}
                    value={pageSize}
                    onChange={e => {
                        setPageSize(Number(e.target.value));
                    }}>
                    {[10, 20, 30, 40, 50].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                            Show {pageSize}
                        </option>
                    ))}
                </select>
            </div>
        </>
    )
}


const ResultsTable = ({columns, data}) => {
    
    return(
        <div>
            <Table columns={columns} data={data} />
        </div>
    );
}

export default ResultsTable;
exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".src-components-feedbackForm-___styles-module__feedbackForm___bYD40{position:fixed;bottom:1em;right:90px;width:300px;border-radius:5px;border:1px solid #3F88C5;background-color:rgba(255, 255, 255, .85);z-index:10000;-webkit-box-shadow:0 4px 4px rgba(0, 0, 0, .25);box-shadow:0 4px 4px rgba(0, 0, 0, .25)}.src-components-feedbackForm-___styles-module__feedbackForm___bYD40 .src-components-feedbackForm-___styles-module__feedbackTitle___4sBVU{margin:7px;font-size:20px;text-align:center}.src-components-feedbackForm-___styles-module__feedbackForm___bYD40 .src-components-feedbackForm-___styles-module__feedbackItemCaption___1vfjF{margin:7px;font-size:17px;padding-top:7px}.src-components-feedbackForm-___styles-module__feedbackForm___bYD40 .src-components-feedbackForm-___styles-module__feedbackImpression___3nMTX{width:280px;margin-left:10px;margin-right:10px}.src-components-feedbackForm-___styles-module__feedbackForm___bYD40 .src-components-feedbackForm-___styles-module__feedbackDetails___2MWWj{width:275px;height:180px;margin:0px 10px 0px 10px;resize:none}.src-components-feedbackForm-___styles-module__feedbackForm___bYD40 .src-components-feedbackForm-___styles-module__feedbackSendButton___2e72V{cursor:pointer;border:1px solid #3F88C5;border-radius:.5rem;margin:10px;width:260px;height:35px;text-align:center;font-size:20px;color:#3F88C5;padding:10px 10px 0}.src-components-feedbackForm-___styles-module__feedbackForm___bYD40 .src-components-feedbackForm-___styles-module__feedbackSendButton___2e72V:hover{background-color:rgba(63, 136, 197, .6);color:#FFFFFF}\n", ""]);

// Exports
exports.locals = {
	"feedbackForm": "src-components-feedbackForm-___styles-module__feedbackForm___bYD40",
	"feedbackTitle": "src-components-feedbackForm-___styles-module__feedbackTitle___4sBVU",
	"feedbackItemCaption": "src-components-feedbackForm-___styles-module__feedbackItemCaption___1vfjF",
	"feedbackImpression": "src-components-feedbackForm-___styles-module__feedbackImpression___3nMTX",
	"feedbackDetails": "src-components-feedbackForm-___styles-module__feedbackDetails___2MWWj",
	"feedbackSendButton": "src-components-feedbackForm-___styles-module__feedbackSendButton___2e72V"
};
import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router";
import styles from "./styles.module.scss";
import Skeleton from 'react-loading-skeleton';
import ModelsRows from "./assignmentModelsRows/assignmentModelsRows";
import ExecutionsRows from "./assignmentExecutionsRows/assignmentExecutionsRows";
import apiRequest from 'src/lib/apiRequest';
import { Store as store } from 'react-notifications-component';
import MarkdownComponent from "../../components/common/markdownComponent/markdownComponent";

const SkeletonRowsExecutions = () => {
    let skeletonRows = [];
    for (var i = 0; i < 5; i++) {
        var key = "skeletonRow_" + i;
        skeletonRows.push(
            <div className={styles.assignmentsListRow} key={key}>
                <div className={styles.assignmentsListCell}><Skeleton /></div>
                <div className={styles.assignmentsListCell}><Skeleton /></div>
                <div className={styles.assignmentsListCell}><Skeleton /></div>
                <div className={styles.assignmentsListCell}><Skeleton /></div>
                <div className={styles.assignmentsListCell}><Skeleton /></div>
                <div className={styles.assignmentsListCell}><Skeleton /></div>
                <div className={styles.assignmentsListCell}><Skeleton /></div>
            </div>);
    }
    return skeletonRows;
}

const SkeletonRowsModels = () => {
    let skeletonRows = [];
    for (var i = 0; i < 5; i++) {
        var key = "skeletonRow_" + i;
        skeletonRows.push(
            <div className={styles.assignmentsListRow} key={key}>
                <div className={styles.assignmentsListCell}><Skeleton /></div>
                <div className={styles.assignmentsListCell}><Skeleton /></div>
                <div className={styles.assignmentsListCell}><Skeleton /></div>
                <div className={styles.assignmentsListCell}><Skeleton /></div>
            </div>);
    }
    return skeletonRows;
}

const GradeAssignment = () => {
    const { assignmentId, enrollmentId } = useParams();


    const [description, setDescription] = useState(null);
    const [submissionDetails, setSubmissionDetails] = useState({});
    const [attachmentsList, setAttachmentsList] = useState([]);
    const [enrollment, setEnrollment] = useState(null);
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    useEffect(() => {
        setLoading(true);
        getEnrollmentDetails(setEnrollment);
    }, []);

    const getEnrollmentsSubmission = async (setSubmissionDetails) => {
        let requestPath = "/assignments/" + assignmentId + "/enrollments/" + enrollmentId + "/submission";

        const { response } = await new apiRequest('GET ' + requestPath, false).send();
        console.log(response);
        if (response) {
            setDescription(response.Entity.Notes);
        }
        setAttachmentsList(response.Entity.Attachments);
        setSubmissionDetails(response.Entity);

    };

    const getEnrollmentDetails = async (setEnrollment) => {
        let requestPath = "/assignments/" + assignmentId + "/enrollments/" + enrollmentId;

        const { response } = await new apiRequest('GET ' + requestPath, false).send();
        console.log(response);
        
        setEnrollment(response.Entity);
        getEnrollmentsSubmission(setSubmissionDetails);
        setLoading(false);
    };

    const acceptSubmission = async () => {
        sendStatusUpdate("Passed", "Submission solution accepted");
    }

    const rejectSubmission = async () => {
        sendStatusUpdate("Rejected", "Submission solution rejected");
    }

    const returnSubmission = async () => {
        sendStatusUpdate("Enrolled", "Submission was returned to participant");
    }

    const sendStatusUpdate = async (status, message) => {
        let requestPath = "/assignments/" + assignmentId + "/enrollments/" + enrollmentId;
        const data = {};
        data.Status = status;
        const { response } = await new apiRequest('POST ' + requestPath, false).sendJSON(data);

        responseToAction(response, message);
    }


    returnSubmission

    const navigateBack = async () => {
        history.goBack();
    }

    const responseToAction = (response, message) => {
        if (response.response !== false) {
            store.addNotification({
                title: "Success",
                message: message,
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 5000
                }
            });
            navigateBack();
        } else {
            store.addNotification({
                title: "Error",
                message: "Unknown error",
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 5000
                }
            });
        }
    }

    const renderModelsList = () => {
        return (
            <div>
                <div className={styles.assignmentsListContainer}>
                    <div className={styles.assignmentsListHeaderRow}>
                        <div className={styles.assignmentsListCell}>Id</div>
                        <div className={styles.assignmentsListCell}>Model Id</div>
                        <div className={styles.assignmentsListCell}>Name</div>
                    </div>
                    {loading &&
                        <SkeletonRowsModels />}
                    {!loading &&
                        <ModelsRows
                            attachmentsList={attachmentsList} 
                            assignmentId={assignmentId}
                            enrollmentId={enrollmentId}/>}
                </div>
            </div>);
    }

    const renderExecutionsList = () => {
        return (
            <div>
                <div className={styles.assignmentsListContainer}>
                    <div className={styles.assignmentsListHeaderRow}>
                        <div className={styles.assignmentsListCell}>Id</div>
                        <div className={styles.assignmentsListCell}>Execution Id</div>
                        <div className={styles.assignmentsListCell}>Model Id</div>
                        <div className={styles.assignmentsListCell}>Engine</div>
                        <div className={styles.assignmentsListCell}>Execution date</div>
                        <div className={styles.assignmentsListCell}>Execution time</div>
                        <div className={styles.assignmentsListCell}>Status</div>
                    </div>

                    {loading &&
                        <SkeletonRowsExecutions />}
                    {!loading &&
                        <ExecutionsRows
                            attachmentsList={attachmentsList}
                            assignmentId={assignmentId}
                            enrollmentId={enrollmentId}/>}
                </div>
            </div>
        );
    }

    return (
        <div className={styles.assignments}>
            <div>
                <div className={styles.submissionDetailsHeaderContainer}>
                    <span className={styles.submissionDetailsHeaderText}>Submission notes</span>
                </div>

                <div className={styles.submissionNotesContainer}>
                    <MarkdownComponent
                        markdownContent={description}
                        updateRoot={setDescription}
                        isEditable={false}
                    />
                </div>

            </div>

            <div>
                <div className={styles.submissionDetailsHeaderContainer}>
                    <span className={styles.submissionDetailsHeaderText}>Attached models</span>
                </div>
                {renderModelsList()}
            </div>

            <div>
                <div className={styles.submissionDetailsHeaderContainer}>
                    <span className={styles.submissionDetailsHeaderText}>Attached executions</span>
                </div>
                <div>
                    {renderExecutionsList()}
                </div>
            </div>

            <div className={styles.buttonFooter}>
                <button className={`${styles.buttonModel} ${styles.assignmentSolutionBackButton}`} 
                        onClick={navigateBack}>Back</button>

                {enrollment && enrollment.Status === 2 && <button className={`${styles.buttonModel} ${styles.assignmentSolutionRejectButton}`} 
                        onClick={rejectSubmission}>Reject</button>}

                {enrollment && enrollment.Status === 4 && <button className={`${styles.buttonModel} ${styles.assignmentSolutionBackButton}`} 
                        onClick={returnSubmission}>Return to submitter</button>}

                {enrollment && enrollment.Status === 2 && <button className={`${styles.buttonModel} ${styles.assignmentSolutionAcceptButton}`} 
                        onClick={acceptSubmission}>Accept</button>}
            </div>
        </div>
    );
}

export default GradeAssignment;
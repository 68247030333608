/**
 *  Models component
 */
import React, { useState, useEffect } from "react";
import { Store as store } from "react-notifications-component";
import { useHistory } from "react-router";
import styles from "./styles.module.scss";
import apiRequest from "../../lib/apiRequest";
import Skeleton from "react-loading-skeleton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SDCModalDialog from "../common/sdcModalDialog/sdcModalDialog";
import SDModelCard from "../common/sdModelCard";
import SDButton from "../common/sdButton/sdButton";

const AssignmentsRows = ({ assignmentsList, attachCommand, modelId }) => {
  let assignmentsRecords = [];
  console.log(assignmentsList);
  if (assignmentsList) {
    for (var i = 0; i < assignmentsList.length; i++) {
      var key = "dataRow_" + i;
      let assignment = assignmentsList[i];
      const startDate = new Date(
        Date.parse(assignment.CreatedDateUtc + "Z")
      ).toLocaleString();
      const endDate = assignment.EndDateUtc
        ? "Not set"
        : new Date(Date.parse(assignment.EndDateUtc + "Z")).toLocaleString();

      assignmentsRecords.push(
        <div className={styles.assignmentsListRow} key={key}>
          <div className={styles.assignmentsListCell}>{assignment.Id}</div>
          <div className={styles.assignmentsListCell}>{assignment.Name}</div>
          <div className={styles.assignmentsListCell}>
            {assignment.AuthorUserName}
          </div>
          <div className={styles.assignmentsListCell}>{startDate}</div>
          <div className={styles.assignmentsListCell}>{endDate}</div>

          <div className={styles.assignmentsListActionsCell}>
            <FontAwesomeIcon
              className={styles.assignmentsEnrollIcon}
              icon="plus-circle"
              onClick={() => attachCommand(assignment.Id, modelId)}
            />
          </div>
        </div>
      );
    }
  }

  return assignmentsRecords;
};

const Models = () => {
  const [modelsList, setModelsList] = useState([]);
  const [assignmentsList, setAssignmentsList] = useState([]);
  const [selectedModelId, setSelectedModelId] = useState(-1);
  const [showCodeDialog, setShowCodeDialog] = useState("False");
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  useEffect(() => {
    setLoading(true);
    const modelsList = getAllModels(setModelsList);
    const assignmentsList = getAssignmentsList(setAssignmentsList);
  }, []);

  const getAssignmentsList = async () => {
    const { response } = await new apiRequest(
      "GET /assignments/enrolled",
      false
    ).send();
    if (response) {
      return setAssignmentsList(response.Entities);
    }
    return [];
  };

  const getAllModels = async () => {
    const { response } = await new apiRequest("GET /models", false).send();
    if (response) {
      setLoading(false);
      return setModelsList(response.Entities);
    }
    return [];
  };

  const addNewModel = () => {
    history.push({
      pathname: "/newModel",
    });
  };

  const sendAttachment = async (assignmentId, attachmentId) => {
    const data = {
      AttachmentId: attachmentId,
      AttachmentType: "Model",
    };

    let requestPath =
      "/assignments/" + assignmentId + "/submission/attachments";

    const { response } = await new apiRequest(
      "POST " + requestPath,
      false
    ).sendJSON(data);

    if (response) {
      store.addNotification({
        title: "Success",
        message: "Model attached",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
        },
      });
    } else {
      store.addNotification({
        title: "Error",
        message: `${response.error.Message}`,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
        },
      });
    }
  };

  const handleAttachButtonClick = (modelId) => {
    setShowCodeDialog("True");
    setSelectedModelId(modelId);
  };

  const handleRunButtonClick = async (modelId, type) => {
    const response = await new apiRequest(
      `POST /models/${modelId}/executions`
    ).sendJSON({ Type: type });
    if (response) {
      store.addNotification({
        title: "Success",
        message: "Execution queued",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
        },
      });
    } else {
      store.addNotification({
        title: "Error",
        message: `${response.error.Message}`,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
        },
      });
    }
  };

  const handleDeleteButtonClick = async (modelId) => {
    const response = await new apiRequest(`DELETE /models/${modelId}`).send();

    if (response) {
      store.addNotification({
        title: "Deleted",
        message: `Model with id ${modelId} successfully deleted`,
        type: "info",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
        },
      });
    } else {
      store.addNotification({
        title: "Error",
        message: `${response.error.Message}`,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
        },
      });
    }

    getAllModels(setModelsList);
  };

    const models = modelsList.map(model => {
        return (
            <div key={model.Id} className={styles.modelsWrapper}>
                <SDModelCard
                    name={model.Name}
                    id={model.Id}
                    description={model.Description}
                    created={model.CreatedDateUtc}
                    updated={model.ModifyDateUtc}
                    modelFileName={model.FileName}
                    onDeleteButtonClick={() => handleDeleteButtonClick(model.Id)}
                    onRunButtonClick={(type) => handleRunButtonClick(model.Id, type)}
                    onAttachButtonClick={() => handleAttachButtonClick(model.Id)}
                />
            </div>
        );
    });

  const closeCodeDialog = () => {
    setShowCodeDialog("False");
    setSelectedModelId("");
  };

  const dialogBody = (
    <div className={styles.assignmentsListContainer}>
      <AssignmentsRows
        assignmentsList={assignmentsList}
        attachCommand={sendAttachment}
        modelId={selectedModelId}
      />
    </div>
  );

  return (
    <div className={styles.models}>
      <SDCModalDialog
        width="900"
        height="500"
        show={showCodeDialog}
        title="Select assignments"
        body={dialogBody}
        onCancel={closeCodeDialog}
        onOk={closeCodeDialog}
      />

      <div className={styles.modelsHeader}>
        <div className={styles.modelsTitle}>My Models</div>

          <SDButton appearance={'primary'} name={'Create new model'} onClick={addNewModel} />

            </div>

      <div className={styles.allModels}>
        {loading && (
          <Skeleton
            className={styles.skeletonFrame}
            width={350}
            height={250}
            count={3}
          />
        )}

        {!loading && models}
      </div>
    </div>
  );
};

export default Models;

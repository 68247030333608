import React from "react";
import styles from "../styles.module.scss";

const ExecutionResultsExporter = require("../../../helpers/executionResultsExporter");

const statusToColor = {
    'Success': "#6EFF6E",
    'Error': "#FF6E6E",
    'Queued': "#F98C32",
    'InProgress': "#3F88C5",
    'Canceled': "#A0A0A0"
}

const LastExecInfo = ({executionData}) => {
    if (executionData) {
        const queuedDate = Date.parse(executionData.QueuedDateUtc + "Z");
        const startDate = Date.parse(executionData.StartDateUtc + "Z");
        const endDate = Date.parse(executionData.EndDateUtc + "Z");
        const execTime = Math.abs(endDate - startDate);
        const execTimeAsString = execTime > 0 ? execTime / 1000 + " s" : "instant";
        //const execTimeConverted =  new Date(execTime).toISOString().substr(11, 8);
    
        return (
            <div className={styles.modelDetailsLastExecInfo}>
    
                <h2 className={styles.modelDetailsCardHeader}>Last execution info</h2>
                <div className={styles.modelDetailsExecStatRow}>
                    <span className={styles.modelDetailsExecStatRowName}>Queued</span>
                    <span>{new Date(queuedDate).toDateString()}</span>
                </div>
                <div className={styles.modelDetailsExecStatRow}>
                    <span className={styles.modelDetailsExecStatRowName}>Status</span>
                    <span className={styles.modelDetailsExecStatStatusBtn} style={{ 'background-color': statusToColor[executionData.StatusAsString] }}>{executionData.StatusAsString}</span>
                </div>
                <div className={styles.modelDetailsExecStatRow}>
                    <span className={styles.modelDetailsExecStatRowName}>Execution time</span>
                    <span>{execTimeAsString}</span>
                </div>
                {executionData.StatusAsString === "Success" && 
                <div className={styles.modelDetailsExecStatRow}>
                    <span className={styles.modelDetailsExecStatRowName}>Execution results</span>
                    
                    <div>
                        <span onClick={function() {ExecutionResultsExporter.saveExecutionResultsAsJson(executionData.ModelId, executionData.ModelExecutionId)}} className={styles.modelDetailsExecStatResultBtn}>.json</span>
                        <span onClick={function() {ExecutionResultsExporter.saveExecutionResultsAsCSV(executionData.ModelId, executionData.ModelExecutionId)}} className={styles.modelDetailsExecStatResultBtn}>.csv</span>
                    </div>
                </div>}
            </div>
        );
    } else {
        return (
            <div className={styles.modelDetailsLastExecInfo}>
    
                <h2 className={styles.modelDetailsCardHeader}>Last execution info</h2>
                Model was not yet executed
            </div>
        );
    }
    
}

export default LastExecInfo;

import styles from "./styles.module.scss";
import React from "react";
import PropTypes from 'prop-types';

const SDInput = ({label, type, value, placeholder, field, name, style, onChange, onClick, ...props}) => {
  return (
    <>
      <div className={styles.inputLabel}>{label}</div>
      <input
        {...props}
        {...field}
        type={type}
        name={name}
        value={value}
        style={style}
        onChange={onChange}
        onClick={onClick}
        className={styles.inputControl}
        placeholder={placeholder}
      />
    </>
  );
};

// SDInput.propTypes = {
//   label: PropTypes.string.isRequired,
//   placeholder: PropTypes.string.isRequired,
//   type: PropTypes.string.isRequired
// };

export default SDInput;

import React, { useState, useEffect } from 'react';
import styles from './styles.module.scss';
import apiRequest from 'src/lib/apiRequest';
import Plot from 'react-plotly.js';
import { layout, text } from 'd3';
import SDPieIndicatorCard from "../common/sdPieIndicatorCard/sdPieIndicatorCard";
import SDInfoCard from "../common/sdInfoCard/sdInfoCard";

const defaultSettings = {
    'totalSpaceTakenByAllArtifacts': 'Artifacts',
    'totalSpaceTakenByAllExecutionResults': 'Execution Results',
    'usage/sdcloud/sdeverywhere': 'SDEverywhere',
    'usage/sdcloud/pysd': 'PySD',
    'model_type/mdl': 'Vensim',
    'model_type/xmile': 'XMILE',
    'model_type/itmx': 'ITMX',
    'model_type/stmx': 'STMX',
    'model_type/not_uploaded': 'No file',
    'exec_result/Success': 'Success',
    'exec_result/Error': 'Failed',
    'exec_result/InProgress': 'In Progress',
    'exec_result/Queued': 'Queued',
    'exec_result/Canceled': 'Canceled',
};

const ModelsTypesChart = ({ userStatistics }) => {
    const data = getDataPointsByMetricName('modelTypesMetrics', userStatistics);
    if (data && data.length) {
        return <Chart datapoints={data} preffix='modelTypesMetrics' renderTotalCount metricName='modelTypesMetrics' userStatistics={userStatistics} />;
    } else return <div className={styles.emptyText}>There are no models uploaded to the system</div>;
};

const ExecutionStatusesChart = ({ userStatistics }) => {
    const data = getDataPointsByMetricName('executionResultsMetrics', userStatistics);
    if (data && data.length) {
        return <Chart datapoints={data} prefix='executionStatusesMetrics' renderTotalCount metricName='executionResultsMetrics' userStatistics={userStatistics} />;
    } else return <div className={styles.emptyText}>You haven't performed any model executions yet</div>;
};

const StorageUsageChart = ({ userStatistics }) => {
    const data = getDataPointsByMetricName('storageMetrics', userStatistics);
    console.log(userStatistics);
    return <Chart datapoints={data} prefix='storageUsage' renderTotalCount metricName='storageMetrics' userStatistics={userStatistics} />;
};

const EnginesUsageChart = ({ userStatistics }) => {
    const data = getDataPointsByMetricName('enginesUsageMetrics', userStatistics);
    if (data && data.length) {
        return <Chart datapoints={data} prefix='executionStatusesMetrics' renderTotalCount metricName='enginesUsageMetrics' userStatistics={userStatistics} />;
    } else return <div className={styles.emptyText}>You haven't performed any model executions yet</div>;
};

const getMetricsFromCollection = (collectionName, userStatistics) => {
    if (!userStatistics.MetricsCollections) return {};
    let result = {};
    userStatistics.MetricsCollections.forEach((collection) => {
        if (collection.Name === collectionName) {
            collection.Metrics.forEach((metric) => {
                result[metric.Name] = {
                    value: metric.Value,
                    description: metric.Description,
                    units: metric.UnitsAsString,
                };
            });
        }
    });

    return result;
};
const colors = [
    '3F88C5',
    '79ACD7',
    'C9DEEF',
    'C9DFF0',
    'EFF5FA',
];
const getDataPointsByMetricName = (metricName, userStatistics) => {
    let dataPoints = [];
    let values = [];
    let labels = [];
    let units = [];
    let color = [];

    const metricData = getMetricsFromCollection(metricName, userStatistics);
    let i = 0;
    for (const metricName in metricData) {
        values.push(parseInt(metricData[metricName].value));
        labels.push(defaultSettings[metricName]);
        units.push(metricData[metricName].units),
            color.push('#' + colors[i])
        i++;
    }

    dataPoints.push({
        values: values,
        labels: labels,
        domain: { column: 0 },
        units: units,
        marker: {
            colors: color
        },
        hoverinfo: 'label+percent',
        textinfo: 'none',
        hole: .6,
        type: 'pie'
    })
    return dataPoints;
};

const getDataPointsForTotalByMetricName = (metricName, userStatistics) => {
    let dataPointsForTotal = [];
    const metricData = getMetricsFromCollection(metricName, userStatistics);
    let i = 0;
    for (const metricName in metricData) {
        dataPointsForTotal.push({
            name: metricName,
            count: parseInt(metricData[metricName].value),
            color: '#' + colors[i],
            units: metricData[metricName].units,
        });
        i++;
    }
    console.log(dataPointsForTotal);
    return dataPointsForTotal;
};

const LegendFromDataPoints = ({ dataPoints }) => (
    <>
        {
            dataPoints && dataPoints.map((point, index) => (
                <li className={styles['dot_' + colors[index]]}>{defaultSettings[point.name]}</li>
            ))
        }
    </>
);


const getDatapointsTotal = (data) => {
    if (!data || !data.length) return '';

    let result = data.reduce((acc, curr) => acc + curr.count, 0);

    if (data[0].units === 'Bytes') {
        let suffix = [
            '',
            'K',
            'M',
            'T',
        ];
        let powerCounter = 0;
        while (result > 1024) {
            result = result / 1024;
            powerCounter++;
        }
        result = result.toFixed(0);
        result += suffix[powerCounter] + 'b';
    };
    return result;
};

const getUserStatistics = async (setUserStatistics) => {
    const { response } = await new apiRequest('GET /profile/metrics', false).send();
    return response ? setUserStatistics(response.Entity) : [];
};

const Chart = ({ datapoints, prefix, renderTotalCount, metricName, userStatistics }) => {
    let total;


    total = getDatapointsTotal(getDataPointsForTotalByMetricName(metricName, userStatistics));
    console.log(total);
    let layouts = {
        height: 200,
        width: 160,
        showlegend: false,
        annotations: [{
            font: {
                size: 18
            },
            showarrow: false,
            text: total
        }],
        margin: { "t": 30, "b": 30, "l": 30, "r": 30 },
        paper_bgcolor: 'rgba(0,0,0,0)',
        plot_bgcolor: 'rgba(0,0,0,0)'
    };
    return (
        <div className={styles.chartContainer}>
            <div className={styles.chartContent}>
                <div className={styles.chartHolder}>
                    <Plot data={datapoints} layout={layouts} config={{ displayModeBar: false }} />
                </div>
                <div className={styles.chartLegentHolder}>
                    <div className={styles.chartLegentData}>
                        <ul>
                            <LegendFromDataPoints dataPoints={getDataPointsForTotalByMetricName(metricName, userStatistics)} />
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

const Dashboard = () => {
    const [activeRuns, setActiveRuns] = useState([]);
    const [failedRuns, setFailedRuns] = useState([]);
    const [totalRuns, setTotalRuns] = useState([]);
    const [successRuns, setSuccessRuns] = useState([]);
    const [userStatistics, setUserStatistics] = useState({});
    const [enginesData, setEnginesData] = useState([])

    useEffect(() => {
        getUserStatistics(setUserStatistics);
    }, []);

    return (
      <>
        <div className={styles.Dashboard}>
            <SDPieIndicatorCard title={'Beta! Be careful!'} subTitle={'This service is under construction and have few issues... Please be careful and stand by!'}/>
            <SDPieIndicatorCard title={'Models statistics'} subTitle={'Models uploaded to the system:'} pieIndicator={<ModelsTypesChart userStatistics={userStatistics} />} />
            <SDPieIndicatorCard title={'Executions statistics'} subTitle={'Overall models executions statistics'} pieIndicator={<ExecutionStatusesChart userStatistics={userStatistics} />} />
            <SDPieIndicatorCard title={'Storage usage'} subTitle={'Disk space used by different user assets'} pieIndicator={<StorageUsageChart userStatistics={userStatistics} />} />
            <SDPieIndicatorCard title={'Engines statistics'} subTitle={'Popularity of different execution engines/processors'} pieIndicator={<EnginesUsageChart userStatistics={userStatistics} />} />
        </div>
      </>
    );
};


export default Dashboard;
import React, { useState, useEffect } from "react";
import styles from "../styles.module.scss";
import assignmentsStyles from '../../../pages/newAssignment/styles.module.scss';
import { Store as store } from 'react-notifications-component';
import DateTimePicker from 'react-datetime-picker';
import MarkdownComponent from "../../common/markdownComponent/markdownComponent";
import apiRequest from 'src/lib/apiRequest';
import SDPanel from "../../common/sdPanel/sdPanel";
import QRCode from "react-qr-code";
import { renderToStaticMarkup } from 'react-dom/server';
import html2canvas from "html2canvas";

const AssignmentEdit = ({ assignmentData }) => {
    const [endDate, setEndDate] = useState(assignmentData.EndDateUtc ? new Date(assignmentData.EndDateUtc + "Z") : null);
    const [assignmentContent, setContent] = useState("");
    const [submissionTemplate, setSubmissionTemplate] = useState("");
    const [loading, setLoading] = useState(false);

    const [isSecured, setSecured] = useState(assignmentData.AccessCode ? true : false);
    const [isHidden, setHidden] = useState(assignmentData.IsHidden);
    const [isEndDateDefined, setEndDateDefined] = useState(assignmentData.EndDateUtc ? true : false);

    const [copyButtonText, setCopyButtonText] = useState("Copy")

    const copyAssignmentLink = () => {
        let linkText = document.getElementById('assignmnentLink');

        linkText.select();
        linkText.setSelectionRange(0, 99999);

        navigator.clipboard.writeText(linkText.value);
        setCopyButtonText("Copied!");

        setTimeout(() => {setCopyButtonText("Copy");}, 5000)
    }

    const saveAssignmentDetails = async () => {
        let requestPath = "/assignments/" + assignmentData.Id;
        let endDateToSet = endDate;
        if (!isEndDateDefined) {
            endDateToSet = null;
        }
        const payload = { ...assignmentData, Content: assignmentContent, EndDateUtc: endDateToSet, SubmissionTemplate: submissionTemplate };
        const { response } = await new apiRequest('POST ' + requestPath, false).send(payload);
        if (response) {
            setLoading(false);
            store.addNotification({
                title: "Success",
                message: "Assignment updated",
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 5000
                }
            });
            return setContent(response.Entity.Content);
        } else {
            store.addNotification({
                title: "Error",
                message: "Something went wrong",
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 5000
                }
            });
            return [];
        }
    }

    const downloadQR = async () => {
        const element = document.getElementById("assignment-access-qr-code");
        const canvas = await html2canvas(element);
        const pngUrl = canvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;

        let fileName = assignmentData.Id + '_' + assignmentData.Name.replace(/[\W_]+/g," ") + ".png";
        fileName = fileName.replaceAll(' ', '-');

        downloadLink.download = fileName;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    let assignmentUrl = window.location.protocol + '//' + 
                        window.location.host + 
                        window.location.pathname + '#/assignments/' +
                        assignmentData.Id + '/assignmentDetails/?joinCode=' + 
                        assignmentData.JoinCode;
    
    return (
        <div className={styles.assignments}>
            <div className={styles.assignmentsContainer}>

                <SDPanel title="Visibility and Security" width="100%" height="100px">
                    <div className={styles.checkBoxContainer}>
                        <input
                            type="checkbox"
                            className={styles.modelCheckBox}
                            style={{ marginLeft: '0.2rem', marginTop: '0.5rem' }}
                            id={'check'}
                            checked={isSecured}
                            onChange={function (e) {
                                setSecured(e.target.checked);
                            }}
                        />
                        <label
                            for={'check'}
                            className={styles.modelLabelForCheckBox}
                            style={{ marginTop: '0.5rem' }}
                        >
                            Secure assignment
                        </label>
                    </div>
                    {isSecured ?
                        <div>
                            <input
                                className={styles.modelInput}
                                id={'accessCode'}
                                placeholder={""}
                                style={{ marginTop: '0.6rem' }}
                                type="text"
                                value={assignmentData.AccessCode}
                                onChange={function (e) {
                                    setAccessCode(e.target.value)
                                }}
                            />
                            <label for={'accessCode'} className={styles.modelLabel}>Enter Access Code</label>
                        </div>
                        :
                        <span />
                    }

                    <div className={styles.checkBoxContainer}>
                        <input
                            type="checkbox"
                            className={styles.modelCheckBox}
                            style={{ marginLeft: '0.2rem', marginTop: '0.5rem' }}
                            id={'checkIsHidden'}
                            checked={isHidden}
                            onChange={function (e) {
                                setHidden(e.target.checked);
                            }}
                        />
                        <label
                            for={'checkIsHidden'}
                            className={styles.modelLabelForCheckBox}
                            style={{ marginTop: '0.5rem' }}
                        >
                            Hide assignment
                        </label>
                    </div>
                    {isHidden ?
                        <div>
                            <input
                                className={styles.modelInput}
                                id={'joinCode'}
                                type="text"
                                value={assignmentData.JoinCode}
                                readOnly="true"
                            />
                            <div style={{fontSize: 'larger', marginTop:'15px'}}>
                                Assignment direct link: <br/>
                                <div style={{width: '100%', display: 'table'}}>
                                    <div style={{display: 'table-cell', verticalAlign: 'bottom'}}>
                                        <input
                                            className={styles.modelInput}
                                            id={'assignmnentLink'}
                                            style={{ width: '100%'}}
                                            type="text"
                                            value={assignmentUrl}
                                            readOnly="true"
                                        />
                                    </div>
                                    <div style={{width: '120px', display: 'table-cell', verticalAlign: 'bottom', textAlign: 'center'}}>
                                        <button 
                                            className={styles.buttonModel}
                                            style={{width: '80px'}} 
                                            onClick={copyAssignmentLink}>
                                                {copyButtonText}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div style={{width: "100%", textAlign: "center", marginTop: '20px'}} >
                                <div id="assignment-access-qr-code" style={{width: '256px', marginLeft: 'auto', marginRight: 'auto'}}>
                                    <QRCode
                                        value={assignmentUrl} />
                                </div>
                                <button 
                                    className={styles.buttonQrDownload}
                                    style={{width: '256px', marginTop: '10px'}} 
                                    onClick={downloadQR}>
                                        Download QR-Code
                                </button>
                            </div>
                        </div>
                        :
                        <span />
                    }
                </SDPanel>

                <SDPanel title="Timeline configuration" width="100%" height="100px">
                    <div className={styles.checkBoxContainer}>
                        <input
                            type="checkbox"
                            className={styles.modelCheckBox}
                            style={{ marginLeft: '0.2rem', marginTop: '0.5rem' }}
                            id={'checkIsEndDateDefined'}
                            checked={isEndDateDefined}
                            onChange={function (e) {
                                setEndDateDefined(e.target.checked);
                            }}
                        />
                        <label
                            for={'checkIsStartDateDefined'}
                            className={styles.modelLabelForCheckBox}
                            style={{ marginTop: '0.5rem' }}
                        >
                            Specify assignment end date
                        </label>
                    </div>
                    {isEndDateDefined ?
                        <div className={styles.dateTimeContainer}>
                            <div className={styles.assignmentEditRightColumn}>
                                <DateTimePicker
                                    maxDetail="minute"
                                    clearIcon={null}
                                    onChange={setEndDate}
                                    value={endDate} />
                            </div>
                        </div>
                        :
                        <span />
                    }
                </SDPanel>


                <SDPanel title="Assignment content" width="100%" height="100px">
                    <h2 className={styles.assignmentsHeaderNew}>Assignment Content</h2>
                    {assignmentData.IsOwned && <div className={styles.plainTextDiv}>
                        Please specify content of the assignment. This is a problem statement to be
                        addressed by assignment participants. You can use
                        <a href="https://daringfireball.net/projects/markdown/" target="_blank"> Markdown </a>
                        syntax to make description better formatted and structured.
                    </div>}
                    <div className={styles.assignmentsRow}>
                        <MarkdownComponent
                            markdownContent={assignmentData.Content}
                            updateRoot={setContent}
                            isEditable={true}
                        />
                    </div>
                </SDPanel>


                <SDPanel title="Submission template" width="100%" height="100px">
                    <h2 className={styles.assignmentsHeaderNew}>Submission template</h2>
                    {assignmentData.IsOwned && <div className={styles.plainTextDiv}>
                        Optional: You can specify a template for the submission notes for this assignment. This notes template will
                        be offered to participants when they will start working on their submission. Here you can specify list of 
                        response sections to be included or general recommendations on how to fill in submission notes for this
                        assignemnt. You can use <a href="https://daringfireball.net/projects/markdown/" target="_blank"> Markdown </a>
                        syntax to make description better formatted and structured.
                    </div>}
                    <div className={styles.assignmentsRow}>
                        <MarkdownComponent
                            markdownContent={assignmentData.SubmissionTemplate}
                            updateRoot={setSubmissionTemplate}
                            isEditable={true}
                        />
                    </div>
                </SDPanel>
            </div>
            <div className={styles.buttonFooter}>
                <button className={styles.buttonModel} onClick={saveAssignmentDetails}>Save</button>
            </div>
        </div>
    );
}

export default AssignmentEdit;
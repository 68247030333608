import React from "react";
import styles from "./styles.module.scss";

const SDInfoCard = (
    {
        title,
        badgeName,
        iconsArr,
        keysArr,
        valArr,
    }) => {

    return (
      <div className={styles.card}>
        {badgeName &&
          <div className={styles.badge}>
            <div className={styles.badgeName}>
              {badgeName}
            </div>
          </div>
        }
        <div className={styles.header}>
          {title}
        </div>
        <hr className={styles.titleHr}/>
        <div className={styles.mainText}>
          {keysArr.map((value, i) =>{
            return <div key={value} className={styles.data}>
              <span className={styles.textStyle}>{iconsArr !== undefined ? iconsArr[i] : ''} {value}:</span>
              <span>{valArr[i]}</span>
            </div>
          })}
        </div>
      </div>
    )
};

export default SDInfoCard;

import * as React from 'react';
import { observer } from 'mobx-react';
import styles from "./styles.module.scss";
import Header from 'src/components/header';
import Footer from 'src/components/footer';

@observer
export default class MainLayout extends React.Component {
    constructor(props) {
        super(props);
        document.title = 'sdCloud :: Bringing System Dynamics Into Cloud';
    }

    render() {
        const { children, ...restParams } = this.props;

        return (
            <div className={styles.mainApp}>
                <div className={styles.mainLayout}>
                    <Header location={this.props.location}/>
                    <div className={ styles.main }>
                        { React.cloneElement(children, restParams) }
                    </div>
                    <Footer/>
                </div>
            </div>
        );
    }
}
/**
 * This module represents a single simple information card with 
 * title and several rows of data. Each card can contains 1-5
 * rows of properties. Each property has name, value and icon.
 * Icon - is a FontAwesome icon that should be inmorted and
 * registered in this module
 */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { faTag, faFolderPlus, faEdit, faFileContract, faHourglassStart, faHourglassEnd, faCogs, faClock, faStopwatch20, faShoePrints, faSave, faTable, faDownload } from '@fortawesome/free-solid-svg-icons'
import React, { Component } from "react";
import styles from "./styles.module.scss";
import { useParams } from "react-router";
import { useHistory } from "react-router";



library.add(fas,
    faTag, 
    faFolderPlus, 
    faEdit, 
    faFileContract, 
    faHourglassStart, 
    faHourglassEnd, 
    faCogs, 
    faClock, 
    faStopwatch20, 
    faShoePrints, 
    faSave, 
    faTable,
    faDownload);

/**
 * List of registered icons to be used in
 * infocard proerties
 */
export const Icons = {
        Created : "folder-plus",
        Updated : "edit",
        Name: "tag",
        ModelFile: "file-contract", 
        StartTime: "hourglass-start", 
        EndTime: "hourglass-end", 
        ExecutionEngine: "cogs", 
        QueueTime: "clock", 
        ExecutionTime: "stopwatch-20", 
        ExecutionStep: "shoe-prints", 
        Disk: "save", 
        Table: "table",
        Download: "download"
    }

/**
 * This method randers header of info card
 * with provided titel
 * @param { cardTitle } param0 - info card title
 */
const InfoCardHeader = ({ cardTitle }) => {
    return (
        <div className={styles.header}>
            { cardTitle }
        </div>
    )
}

/**
 * This method renders a single row of properties
 * for info card content
 * @param { icon, value } param0 - icon and value of 
 *                  parameter to be rendered 
 */
const InfoCardPropertyRow = ({ icon, value}) => {
return (
        <div className={styles.cardContentRow}>
            <div className={styles.
                cardContentIcon}>
                <FontAwesomeIcon icon={icon} /> 
            </div>
            <div className={styles.cardContentValue}>
                {value}
            </div>
        </div>
    )
}


/**
 * This method converts array of properties into list
 * of rendered info cards properties rows
 * @param { properties } param0 - properties to be 
 *                  converted into card rows
 */


const InfoCardPropertyRowsSet = ({ properties }) => {
    let propertyRows = [];
    properties.forEach(item => propertyRows.push(
        <InfoCardPropertyRow icon={item.icon} value={item.value} />
    ));

    return propertyRows;
}

/**
 * This method renders a wrapper for info card content
 * @param { properties } param0 - list of card properties
 *                   to be rendered
 */
const InfoCardProperties = ({ properties }) => {
    return (
        <div className={styles.cardContent}>
            <InfoCardPropertyRowsSet properties={ properties }/>
        </div>
    )
}


/**
 * Root method for rendering single info card object
 * @param { title, properties } param0 - title and 
 *                  properties to be rendered on
 *                  the card
 */
const InfoCard = ({ title, properties, style }) => {
   
    if (title=="Model info") {
   
    const history = useHistory();  

    const { id } = useParams();
   
    const InfoCardBack = () => {
  
        history.push({
            pathname:  `/models/${id}/modeldetails`,
        });
    }
  
    return (
    
            <div className={styles.card} style={style}>

            <InfoCardHeader cardTitle={ title }/>

            <InfoCardProperties properties={ properties }/>

            <div className={styles.buttonBack} onClick={() => InfoCardBack()}>Model details</div>

        </div>)
        } else return (

        <div className={styles.card} >

            <InfoCardHeader cardTitle={ title }/>

            <InfoCardProperties properties={ properties }/>

        </div>
    )
}

export default InfoCard;

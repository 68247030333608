import React from "react";
import styles from "./styles.module.scss";

const SDButton = ({
  name,
  icon,
  disabled,
  appearance,
  onClick,
  style
}) => {

  let buttonStyle = styles.sdButtonPrimary;

  switch (appearance) {
    case "secondary":
      buttonStyle = styles.sdButtonSecondary;
      break;
    case "outline":
      buttonStyle = styles.sdButtonOutline;
      break;
    case "warning":
      buttonStyle = styles.sdButtonWarning;
      break;
    default:
      buttonStyle = styles.sdButtonPrimary;
  }

  return (
    <div
      className={buttonStyle}
      onClick={event => {
        if (!disabled) {
          onClick(event);
        }
      }}
      style={style}
    >
      {icon}
      {" "}
      {name}
    </div>
  );
};

export default SDButton;

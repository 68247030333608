exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".src-components-execution-components-resultsTable-___styles-module__resultsTableWrapper___2CFrq{overflow-x:scroll}table{width:100%;border-spacing:0;border:1px solid #C9DEEF;margin-bottom:7px}table tr :last-child td{border-bottom:0}table th,table td{margin:0;padding:16px;padding:1rem;border-bottom:1px solid #C9DEEF;border-right:1px solid #C9DEEF}table th :last-child,table td :last-child{border-right:0}.src-components-execution-components-resultsTable-___styles-module__ml_2___1446Z{margin-left:2px}.src-components-execution-components-resultsTable-___styles-module__mr_2___2u6Y2{margin-right:2px}.src-components-execution-components-resultsTable-___styles-module__pagination___1s4cR{width:600px;margin-left:auto;margin-right:auto}\n", ""]);

// Exports
exports.locals = {
	"resultsTableWrapper": "src-components-execution-components-resultsTable-___styles-module__resultsTableWrapper___2CFrq",
	"ml_2": "src-components-execution-components-resultsTable-___styles-module__ml_2___1446Z",
	"mr_2": "src-components-execution-components-resultsTable-___styles-module__mr_2___2u6Y2",
	"pagination": "src-components-execution-components-resultsTable-___styles-module__pagination___1s4cR"
};
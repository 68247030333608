import React, { useState, useEffect } from "react";
import styles from "../styles.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import apiRequest from "src/lib/apiRequest";
import SDButton from "../../common/sdButton/sdButton";
import SDPieIndicatorCard from "../../common/sdPieIndicatorCard/sdPieIndicatorCard";

const AssignmentEnrollment = ({ isEnrolled, leaveCommand, enrollCommand }) => {

  return (
    <SDPieIndicatorCard title={'Enrollment'} subTitle={isEnrolled ? (
      <span>
            You can leave this assignment by pressing the button below
          </span>
    ) : (
      <span>
            To submit your solution you need to enroll into this assignment
          </span>
    )} pieIndicator={isEnrolled ? (
      <SDButton style={{marginTop: '40px', marginRight: '105px'}} name={'Leave'} icon={<FontAwesomeIcon className={styles.enrollIcon} icon="minus-circle" />}
                appearance={'warning'} onClick={leaveCommand} />
    ) : (
      <SDButton style={{marginTop: '40px', marginRight: '105px'}} name={'Enroll'} icon={<FontAwesomeIcon className={styles.enrollIcon} icon="plus-circle" />}
                onClick={enrollCommand} appearance={'primary'} />
    )}>

    </SDPieIndicatorCard>
  );
};

export default AssignmentEnrollment;

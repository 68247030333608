import styles from "./styles.module.scss";
import React from "react";
import PropTypes from 'prop-types';

const AuthFormField = ({label, type, placeholder, field, ...props}) => {
    return (
        <div className={`${styles.authFormField}`}>
            <div className={styles.authFormFieldLabel}>{label}</div>
            <input
                {...field}
                {...props}
                type={type}
                className={`${styles.authControlsItem} ${styles.authInputControl}`}
                placeholder={placeholder}
            />
        </div>
    );
};

AuthFormField.propTypes = {
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired
};

export default AuthFormField;
import React from "react";
import SDInfoCard from "../../common/sdInfoCard/sdInfoCard";

const AssignmentInfo = ({ assignmentData }) => {
    let endDateToShow = "Not set";
    if (assignmentData.EndDateUtc) {
        const endDate = Date.parse(assignmentData.EndDateUtc + "Z");
        endDateToShow = (new Date(endDate)).toLocaleString();
    }
    

    return (
        <SDInfoCard title={'Assignment Info'} keysArr={['Author', 'End date']}
                    valArr={[assignmentData.AuthorUserName, endDateToShow]}>
        </SDInfoCard>
    );
}

export default AssignmentInfo;

exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".src-components-common-sdPieIndicatorCard-___styles-module__card___Y1gqT{border-radius:.5rem;border:1px solid #3F88C5;-webkit-box-shadow:0 1px 3px rgba(0, 0, 0, .12),0 1px 2px rgba(0, 0, 0, .24);box-shadow:0 1px 3px rgba(0, 0, 0, .12),0 1px 2px rgba(0, 0, 0, .24);margin-top:32px;margin-right:20px;width:350px;float:left;position:relative}.src-components-common-sdPieIndicatorCard-___styles-module__header___1Op25{color:#404040;width:80%;font-size:28px;text-align:center;font-weight:bold;margin:20px auto 10px}.src-components-common-sdPieIndicatorCard-___styles-module__mainText___2pN3P{padding-left:20px;padding-right:20px;height:15%;margin:auto;text-align:center;color:#3F88C5;font-size:22px}.src-components-common-sdPieIndicatorCard-___styles-module__titleHr___2OV8Z{width:90%;height:1px;border:none;background-color:#3F88C5;margin-top:-5px}.src-components-common-sdPieIndicatorCard-___styles-module__badge___2kDTa{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:right;-ms-flex-pack:right;justify-content:right;margin-right:2px;margin-top:2px}.src-components-common-sdPieIndicatorCard-___styles-module__badgeName___1Oz9_{padding:0 10px;font-size:14px;background-color:#73899E;border-radius:.5rem;color:#FFFFFF;margin-bottom:-20px}\n", ""]);

// Exports
exports.locals = {
	"card": "src-components-common-sdPieIndicatorCard-___styles-module__card___Y1gqT",
	"header": "src-components-common-sdPieIndicatorCard-___styles-module__header___1Op25",
	"mainText": "src-components-common-sdPieIndicatorCard-___styles-module__mainText___2pN3P",
	"titleHr": "src-components-common-sdPieIndicatorCard-___styles-module__titleHr___2OV8Z",
	"badge": "src-components-common-sdPieIndicatorCard-___styles-module__badge___2kDTa",
	"badgeName": "src-components-common-sdPieIndicatorCard-___styles-module__badgeName___1Oz9_"
};
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import styles from "./styles.module.scss";
import apiRequest from "src/lib/apiRequest";
import SliderMenu from "src/components/execution/components/sliderMenu/sliderMenu";
import { Store as store } from "react-notifications-component";
import SDButton from "../../components/common/sdButton/sdButton";
import SDInput from "../../components/common/sdInput/sdInput";
import SDTextArea from "../../components/common/sdTextArea/sdTextArea";

const NewModel = () => {
  const [isTextEditor, setIsTextEditor] = useState(false);
  const [view, setView] = useState("sliderMenuItem_text");

  const [name, setName] = useState(null);
  const [description, setDescription] = useState("");
  const [modelFile, setModelFile] = useState("");
  const [file, setFile] = useState(null);
  const [cont, setContent] = useState("chooseFile");

  const history = useHistory();

  useEffect(() => {
    document.title =
      "sdCloud :: Bringing System Dynamics Into Cloud :: Create new model";
  }, []);

  const uploadFile = (file) => {
    setIsTextEditor(false);
    setFile(file.target.files[0]);
  };

  const sendModel = async () => {
    const data = {
      Name: name,
      Description: description,
      IsTextEditorSelected: isTextEditor,
      FileName: modelFile,
    };
    const response = await new apiRequest("POST /models", false).sendJSON(data);
    if (response) {
      const uplodResult = await new apiRequest(
        "POST /models/" + response.response.Entity.Id + "/content",
        false
      ).sendModelFile(file);
      if (uplodResult) {
        history.push({
          pathname: "/models",
        });
      }
      store.addNotification({
        title: "Success",
        message: "Model created",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
        },
      });
    }

    if (cont == "pastType") {
      let modelContent;
      if (modelFile.substring(0, 5) === "<?xml") {
        modelContent = new File([modelFile], ".xmile", { type: "text/plain" });
      } else {
        modelContent = new File([modelFile], ".mdl", { type: "text/plain" });
      }
      const uplodResult = await new apiRequest(
        "POST /models/" + response.response.Entity.Id + "/content",
        false
      ).sendModelFile(modelContent);
      if (uplodResult) {
        history.push({
          pathname: "/models",
        });
      }
    }
  };

  const renderTextEditor = () => {
    return (
      <div className={styles.newModel}>
        <div className={styles.modelContainer}>
          <div className={styles.modelRow}>
              <SDInput
                data-cy={"modelNameInput"}
                name={'name'}
                value={name}
                label={'Name'}
                type={'text'}
                onChange={function(e) { setName(e.target.value)}}
              />
              <div className={styles.textField}>
                  <SDTextArea
                    label={'Description'}
                    name={'description'}
                    data-cy={"modelDescriptionInput"}
                    value={description}
                    onChange={function(e) { setDescription(e.target.value)}}
                  />
              </div>
            <div
              className={`${styles.modelRow} ${styles.buttonFooter}`}
              onClick={setViewToFileUpload}
            >
                <SDButton name={'Next'} appearance={'outline'} onClick={renderFileUpload} style={{marginLeft: 'auto'}}/>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const setViewToText = () => {
    setView("sliderMenuItem_text");
  };

  const setViewToFileUpload = () => {
    setView("sliderMenuItem_file");
  };

  const renderFileUpload = () => {
    return (
      <div className={styles.newModel}>
        <div className={styles.modelContainer}>
          <div className={styles.modelRow}>
            <div className={styles.radioButton}>
              <input
                type="radio"
                name="radio"
                value="chooseFile"
                id="chooseFile"
                checked={!isTextEditor}
                data-cy={"modelFileRadio"}
                onChange={() => setContent("chooseFile")}
              />

              <label
                htmlFor="chooseFile"
                onClick={function (e) {
                  setIsTextEditor(false);
                }}
              >
                Choose model file
                <div
                  htmlFor="chooseFile"
                  className={styles.modelField}
                  onClick={function (e) {
                    setIsTextEditor(false);
                  }}
                >
                  ModelFile
                </div>
                <input
                  htmlFor="chooseFile"
                  type="file"
                  name="file"
                  data-cy={"modelFileInput"}
                  onClick={function (e) {
                    setIsTextEditor(false);
                  }}
                  onChange={uploadFile}
                />
              </label>
            </div>
            <div className={styles.modelField}></div>
            <h4>
              <span> or </span>
            </h4>
            <div className={styles.radioButton}>
              <input
                type="radio"
                name="radio"
                value="pastType"
                id="pastType"
                checked={isTextEditor}
                data-cy={"modelSourceRadio"}
                onChange={() => setContent("pastType")}
              />
              <label
                htmlFor="pastType"
                onClick={function (e) {
                  setIsTextEditor(true);
                }}
              >
                Past or type model content
                <SDTextArea
                  label={"Model source"}
                  name={"modelFile"}
                  data-cy={"modelSourceInput"}
                  value={modelFile}
                  onClick={function (e) {
                    setIsTextEditor(true);
                  }}
                  onChange={function (e) {
                    setIsTextEditor(true);
                    setModelFile(e.target.value);
                  }}
                />
              </label>
            </div>
            <div className={`${styles.modelRow} ${styles.buttonFooter}`}>
                <SDButton name={'Back'} appearance={'outline'} style={{marginLeft: 'auto', marginRight: '10px'}} onClick={setViewToText} />
                <SDButton disabled={!name || !description} name={'Create'} appearance={'primary'} onClick={sendModel} />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderControlSlider = () => {
    var menuItems = [];
    var defaultSelected = view;
    menuItems.push({
      name: "Model Info",
      id: "sliderMenuItem_text",
    });
    menuItems.push({
      name: "Source",
      id: "sliderMenuItem_file",
    });

    return (
      <SliderMenu
        items={menuItems}
        selected={defaultSelected}
        callback={sliderMenuSelecionChanged}
      />
    );
  };

  const sliderMenuSelecionChanged = (selectedItemId) => {
    setView(selectedItemId);
  };

  return (
    <div className={styles.newModel}>
      <div className={styles.modelContainer}>
        <div className={styles.modelRow}>
          <div className={styles.modelHeader}>Create new model</div>

          <div>
            <div className={styles.sliderItems}>{renderControlSlider()}</div>
            {view === "sliderMenuItem_text" && renderTextEditor()}
            {view === "sliderMenuItem_file" && renderFileUpload()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewModel;

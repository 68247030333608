import apiRequest from '../lib/apiRequest';

const saveExecutionResultsAsJson = async(modelId, executionId) => {
    const { response } = await new apiRequest('GET /models/' + modelId + '/executions/' + executionId + '/results/', false).send();
    if (response) {
        const jsonData = JSON.stringify(response.Entities);
        let blob = new Blob( [ jsonData ], {
            type: 'application/octet-stream'
        });
        let url = URL.createObjectURL( blob );
        let link = document.createElement( 'a' );
        link.setAttribute( 'href', url );
        link.setAttribute( 'download', 'sdc_model_' + modelId + '_execution_' + executionId + '.json' );
        let event = document.createEvent( 'MouseEvents' );
        event.initMouseEvent( 'click', true, true, window, 1, 0, 0, 0, 0, false, false, false, false, 0, null);
        link.dispatchEvent( event );
    } else {
        return [];
    }
};

const saveExecutionResultsAsCSV = async(modelId, executionId) =>  {
    const { response } = await new apiRequest('GET /models/' + modelId + '/executions/' + executionId + '/results/', false).send();
    if (response) {
        const results = response.Entities;
        let csvData = "";
        
        if (results.length > 0) {
            // If we have non empty set of results
            // We will take a firs result entry and generate
            // headers for CSV file out of it
            const firstRow =  results[0].Values;
            let headers = [ "Time", "TimeDelta" ]; // Adding two default column headers which are always present
            // Iterate value properlies and pull names
            for (var entryName in firstRow) {
                headers.push(entryName);
            }
            csvData = headers.join(',');
        
            // Now iterate though all result entiries and
            // create CSV data rows
            for (var i = 0; i < results.length; i++) {
                let resultEntry = results[i].Values;
                let entryValues = [ results[i].Time, results[i].TimeDelta ];
                for (var entryName in resultEntry) {
                    entryValues.push(resultEntry[entryName]);
                }
                csvData += "\n" + entryValues.join(',');
            }
        }
        let blob = new Blob( [ csvData ], {
            type: 'application/octet-stream'
        });
        let url = URL.createObjectURL( blob );
        let link = document.createElement( 'a' );
        link.setAttribute( 'href', url );
        link.setAttribute( 'download', 'sdc_model_' + modelId + '_execution_' + executionId + '.csv' );
        let event = document.createEvent( 'MouseEvents' );
        event.initMouseEvent( 'click', true, true, window, 1, 0, 0, 0, 0, false, false, false, false, 0, null);
        link.dispatchEvent( event );
    } else {
        return [];
    }
}


export {
    saveExecutionResultsAsJson,
    saveExecutionResultsAsCSV
}
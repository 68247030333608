import * as React from 'react';
import { observer } from 'mobx-react';
import { Route as ReactRouterRoute, HashRouter as Router, Switch, Redirect } from 'react-router-dom';
import LoginPage from "./pages/Auth";
import ErrorPage from "./pages/404";
import SignUpPage from './pages/SignUp';
import LoginLayout from "./layout/login";
import MainLayout from "./layout/main";
import userState from './globalState/user';
import Dashboard from './components/dashboard';
import Gallery from './components/gallery';
import NewModel from './pages/newModel';
import NewAssignment from './pages/newAssignment';
import Models from './components/models';
import ModelDetails from './components/modelDetails';
import ModelsRun from './components/modelsRun';
import Execution from './components/execution';
import Parameters from './components/parameters';
import Assignments from './components/assignments';
import AssignmentDetails from './components/assignmentDetails';
import GradeAssignment from './pages/gradeAssignment';
import ModelEditor from './components/model-edit';

@observer
export default class Routes extends React.Component {
    render() {
        return <Router>
            <Switch>
                <PrivateRoute path="/" exact><MainLayout><Dashboard/></MainLayout></PrivateRoute>
                <Route path="/login" exact><LoginLayout><LoginPage /></LoginLayout></Route>
                <Route path="/signup" exact><LoginLayout><SignUpPage /></LoginLayout></Route>
                <PrivateRoute path="/gallery" exact><MainLayout><Gallery/></MainLayout></PrivateRoute>
                <PrivateRoute path="/newModel" exact><MainLayout><NewModel /></MainLayout></PrivateRoute>
                <PrivateRoute path="/newAssignment" exact><MainLayout><NewAssignment /></MainLayout></PrivateRoute>
                <PrivateRoute path="/assignments" exact>
                    <MainLayout>
                        <Assignments />
                    </MainLayout>
                </PrivateRoute>
                <PrivateRoute path="/assignments/:id/assignmentDetails" exact>
                    <MainLayout>
                        <AssignmentDetails />
                    </MainLayout>
                </PrivateRoute>
                <PrivateRoute path="/assignments/:assignmentId/enrollments/:enrollmentId" exact>
                    <MainLayout>
                        <GradeAssignment />
                    </MainLayout>
                </PrivateRoute>
                <PrivateRoute path="/models" exact><MainLayout><Models/></MainLayout></PrivateRoute>
                <PrivateRoute path="/models/:id/modeldetails" exact><MainLayout><ModelDetails/></MainLayout></PrivateRoute>
                {/*<PrivateRoute path="/modeldetails" exact><MainLayout><ModelDetails/></MainLayout></PrivateRoute>*/}
                <PrivateRoute path="/models/:id/executions/:executionId" exact>
                    <MainLayout>
                        <Execution />
                    </MainLayout>
                </PrivateRoute>
                <PrivateRoute path="/models/:id" exact><MainLayout><Parameters/></MainLayout></PrivateRoute>
                <PrivateRoute path="/models/edit/:id" exact><MainLayout><ModelEditor/></MainLayout></PrivateRoute>
                <PrivateRoute path="/modelsrun" exact><MainLayout><ModelsRun/></MainLayout></PrivateRoute>
                <PrivateRoute path='/404'><MainLayout><ErrorPage /></MainLayout></PrivateRoute>
                <PrivateRoute>
                    <Redirect to={ {
                        pathname: '/404',
                    } } />
                </PrivateRoute>
            </Switch>
        </Router>;
    }
}

const PrivateRoute = observer(({ children, ...rest }) => {
    const { user, initialFetching } = userState;
    const renderFunc = (props) => {
        if (initialFetching === true) return null;
        if (user) return React.cloneElement(children, props);

        window.localStorage.setItem('redirect', window.location.pathname + window.location.search);

        let redirectPath;
        if (props.location) {
            if (props.location.pathname) {
                redirectPath =  {
                    pathname : props.location.pathname
                }
                if (props.location.search) {
                    redirectPath.search = props.location.search;
                }
            }
        }

        return <Redirect to={ {
            pathname: '/login',
            search: redirectPath ? '?redirectTo='+btoa(JSON.stringify(redirectPath)) : "",
            state: { from: props.location },
        } } />;
    };

    return (
        <ReactRouterRoute { ...rest } render={ renderFunc } />
    );
});

const Route = observer(({ children, ...rest }) => {
    const renderFunc = (props) => {
        return React.cloneElement(children, props);
    };

    return (
        <ReactRouterRoute { ...rest } render={ renderFunc } />
    );
});

import styles from "./styles.module.scss";
import React from "react";

const SDTextArea = ({label, type, value, placeholder, field, name, style, onChange, onClick, ...props}) => {
  return (
    <>
      <div className={styles.inputLabel}>{label}</div>
      <textarea
        {...props}
        {...field}
        name={name}
        value={value}
        style={style}
        onChange={onChange}
        onClick={onClick}
        className={styles.inputControl}
        placeholder={placeholder}
      />
    </>
  );
};

export default SDTextArea;

/**
 *  Edit-model component
 */
import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import { useHistory, useParams } from "react-router";
import { Controlled as CodeMirror } from "react-codemirror2";
import apiRequest from "src/lib/apiRequest";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "codemirror/lib/codemirror.css";
import "codemirror/theme/material.css";
import SDButton from "../common/sdButton/sdButton";
import { Store as store } from "react-notifications-component";

require("codemirror/mode/xml/xml");
require("codemirror/mode/javascript/javascript");

const ModelEditor = () => {
  const { id } = useParams();
  const history = useHistory();

  const [modelContent, setContent] = useState("");

  const [modelMetadata, setModelMetadata] = useState(false);

  const codemirrorRef = React.useRef();

  useEffect(() => {
    getModelContent(setContent);
    getModelMetadata(setModelMetadata);
    const current = (codemirrorRef.current.editor.display.wrapper.style.height =
      "750px");
  }, []);

  const getModelMetadata = async () => {
    const { response } = await new apiRequest(
      "GET /models/" + id,
      false
    ).send();
    if (response) {
      setModelMetadata(response.Entity);
    } else {
      return false;
    }
  };

  const getModelContent = async (setContent) => {
    const { response } = await new apiRequest(
      "GET /models/" + id + "/content/",
      false
    ).send();
    setContent(response);
  };

  const saveNewModelData = async () => {
    let file = new File([modelContent], modelMetadata.FileName, {
      type: "text/plain",
    });
    var response = await new apiRequest(
      "POST /models/" + id + "/content",
      false
    ).sendModelFile(file);
    if (response) {
      store.addNotification({
        title: "Success",
        message: "Model file uploaded successfully",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
        },
      });
      history.goBack();
    }
  };

  const navigateBack = async () => {
    history.goBack();
  };

  return (
    <div className={styles.editorContainer} data-cy={"modelEditor"}>
      <div className={styles.headerWrapper}>
        <h1
          contentEditable="true"
          onInput={(e) => (modelMetadata.Name = e.currentTarget.textContent)}
          className={styles.modelDetailsHeader}
        >
          {modelMetadata ? modelMetadata.Name : "Loading..."}
          <FontAwesomeIcon className={styles.titleEditIcon} icon="edit" />
        </h1>
      </div>
      <div
        contentEditable="true"
        onInput={(e) =>
          (modelMetadata.Description = e.currentTarget.textContent)
        }
        className={styles.modelDetailsContainerDesc}
      >
        <p>
          {modelMetadata ? modelMetadata.Description : ""}
          <FontAwesomeIcon className={styles.descriptionEditIcon} icon="edit" />
        </p>
      </div>

      <CodeMirror
        className={styles.editor}
        value={modelContent}
        style={{ height: "800px" }}
        ref={codemirrorRef}
        options={{
          mode: "xml",
          theme: "material",
          lineNumbers: true,
        }}
        onBeforeChange={(editor, data, value) => {
          setContent(value);
        }}
        onChange={(editor, data, value) => {}}
      />

            <div className={styles.buttonContainer}>
                <SDButton name={'Back'} appearance={'outline'} onClick={navigateBack}
                          style={{marginLeft: 'auto', marginRight: '10px'}} />
                <SDButton name={'Save'} appearance={'primary'} onClick={saveNewModelData}/>
            </div>
        </div>);
};

export default ModelEditor;

import * as React from 'react';
import { observer } from 'mobx-react';
import styles from "./styles.module.scss";

@observer
export default class Layout extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { children, ...restParams } = this.props;

        return (
            <div>
                <div className={styles.wrap}>
                    { React.cloneElement(children, restParams) }
                </div>
            </div>
        );
    }
}
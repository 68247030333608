import React, { useState, useEffect } from "react";
import styles from "../styles.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
import apiRequest from "src/lib/apiRequest";

const statusToColor = ["#3F88C5", "#F98C32", "#6EFF6E", "#FF6E6E"];

const statusToText = ["Enrolled", "Submitted", "Passed", "Rejected"];

const SubmissionsInfo = ({ enrollmentsList }) => {
  if (enrollmentsList) {
    let enrolled = enrollmentsList.filter(
      (enrollment) => enrollment.Status == 1
    ).length;
    let submitted = enrollmentsList.filter(
      (enrollment) => enrollment.Status == 2
    ).length;
    let accepted = enrollmentsList.filter(
      (enrollment) => enrollment.Status == 3
    ).length;
    let rejected = enrollmentsList.filter(
      (enrollment) => enrollment.Status == 4
    ).length;

    let all = enrollmentsList.length;

    return (
      <div className={styles.submissionDetailsInfo}>
        <h2 className={styles.assignmentDetailsCardHeader}>Submissions Info</h2>

        <div className={styles.assignmentStatisticsRow}>
          <div className={styles.assignmentStatisticsColumn}>
            <div className={styles.assignmentDetailsInfoRow}>
              <span className={styles.assignmentDetailsInfoRowName}>
                Enrolled
              </span>
              <span>{all}</span>
            </div>

            <div className={styles.assignmentDetailsInfoRow}>
              <span className={styles.assignmentDetailsInfoRowName}>
                Submitted
              </span>
              <span>{submitted}</span>
            </div>
          </div>

          <div className={styles.assignmentStatisticsRightColumn}>
            <div className={styles.assignmentDetailsInfoRow}>
              <span className={styles.assignmentDetailsInfoRowName}>
                Accepted
              </span>
              <span>{accepted}</span>
            </div>

            <div className={styles.assignmentDetailsInfoRow}>
              <span className={styles.assignmentDetailsInfoRowName}>
                Rejected
              </span>
              <span>{rejected}</span>
            </div>

            <div className={styles.assignmentDetailsInfoRow}>
              <span className={styles.assignmentDetailsInfoRowName}>
                To be graded
              </span>
              <span>{all - accepted - rejected}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return [];
};

const EnrollmentsRows = ({ assignmentsList }) => {
  let assignmentsRecords = [];
  if (assignmentsList) {
    for (var i = 0; i < assignmentsList.length; i++) {
      var key = "dataRow_" + i;
      var enrollment = assignmentsList[i];
      const enrollmentDate = new Date(
        Date.parse(enrollment.EnrollmentDateUtc + "Z")
      ).toLocaleString();
      const enrollmentId = enrollment.Id;
      assignmentsRecords.push(
        <div className={styles.assignmentsListRow} key={key}>
          <div className={styles.assignmentsListCell}>
            <Link
              to={`/assignments/${enrollment.AssignmentId}/enrollments/${enrollment.Id}`}
            >
              {enrollment.Id}
            </Link>
          </div>
          <div className={styles.assignmentsListCell}>
            {enrollment.UserName}
          </div>
          <div className={styles.assignmentsListCell}>{enrollmentDate}</div>
          <div
            className={styles.assignmentsListActionsCell}
            style={{ backgroundColor: statusToColor[enrollment.Status - 1] }}
          >
            {statusToText[enrollment.Status - 1]}
          </div>
        </div>
      );
    }
  }
  return assignmentsRecords;
};

const SkeletonRows = () => {
  let skeletonRows = [];
  for (var i = 0; i < 10; i++) {
    var key = "skeletonRow_" + i;
    skeletonRows.push(
      <div className={styles.assignmentsListRow} key={key}>
        <div className={styles.assignmentsListCell}>
          <Skeleton />
        </div>
        <div className={styles.assignmentsListCell}>
          <Skeleton />
        </div>
        <div className={styles.assignmentsListCell}>
          <Skeleton />
        </div>
        <div className={styles.assignmentsListCell}>
          <Skeleton />
        </div>
      </div>
    );
  }

  return skeletonRows;
};

const GradeBook = ({ assignmentId }) => {
  const [enrollmentsList, setEnrollmentsList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const enrollmentsList = getEnrollmentsList(setEnrollmentsList);
  }, []);

  const getEnrollmentsList = async (setEnrollmentsList) => {
    let requestPath = "/assignments/" + assignmentId + "/enrollments/";
    const { response } = await new apiRequest(
      "GET " + requestPath,
      false
    ).send();
    if (response) {
      setLoading(false);
      return setEnrollmentsList(response.Entities);
    }
    return [];
  };

  const renderAssignmentsTableHeader = () => {
    return (
      <div
        className={styles.assignmentsListHeaderRow}
        data-cy={"assignmentsTableHeader"}
      >
        <div className={styles.assignmentsListCell}>Id</div>
        <div className={styles.assignmentsListCell}>Author</div>
        <div className={styles.assignmentsListCell}>Enrolled date</div>
        <div className={styles.assignmentsListActionsCell}>Status</div>
      </div>
    );
  };

  const renderEnrollmentsList = () => {
    return (
      <div className={styles.assignmentsListContainer}>
        {renderAssignmentsTableHeader()}
        {loading && <SkeletonRows />}
        {!loading && <EnrollmentsRows assignmentsList={enrollmentsList} />}
      </div>
    );
  };

  return (
    <div className={styles.assignments}>
      <div className={styles.assignmentsContainer}>
        <SubmissionsInfo enrollmentsList={enrollmentsList} />
        <div className={styles.assignmentsRow}>{renderEnrollmentsList()}</div>
      </div>
    </div>
  );
};

export default GradeBook;

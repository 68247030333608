import React, { useState, useEffect } from "react";
import apiRequest from 'src/lib/apiRequest';
import styles from "../styles.module.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { library } from '@fortawesome/fontawesome-svg-core'
import { far } from '@fortawesome/free-regular-svg-icons'
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons'

library.add(far,
    faQuestionCircle);

const auxilaries = {
    dur: '10 days [0..?]',
    total: '10000 peoples [0..?]',
    frsick: '0.2 [0.05 .. 1]'
}

const stocks = {
    recov: '0 peoples [0..?]',
    susc: '9500 peoples [0..?]',
    sick: '500 peoples [0..?]'
}

const ModelConfig = ({modelId, modelData}) => {

    const [modelConfigData, setModelConfigData] = useState(modelData);
    const [AuxDescIsShown, setAuxDescIsShown] = useState(false);
    const [StockDescIsShown, setStockDescIsShown] = useState(false);

    useEffect(() => {
        setModelConfigData(modelData);
        getModelConfigData(setModelConfigData);
    }, [modelData]);


    const getModelConfigData = async(setModelConfigData) => {

        if (!modelConfigData && modelId) {
            const { response } = await new apiRequest('GET /models/' + modelId, false).send();

            if (response) {
                console.log(response);
                return setModelConfigData(response.Entity);
            } else {
                return [];
            }
        }
        
    }

    const AuxesRows = () => {
        let auxesRecords = [];
         for (let i = 0; i < modelConfigAuxes.length; i++) {
             let auxItem = modelConfigAuxes[i];
             auxesRecords.push (
            <div className={styles.modelConfigRow}>
                <div className={styles.modelConfigRowNameWrap}>
                    {auxItem.Name}
                    {auxItem.Description &&
                    <FontAwesomeIcon icon={faQuestionCircle}
                                     className={styles.ModelConfigQuestionIcon}
                                     onMouseEnter={() => setAuxDescIsShown(auxItem.Description)}
                                     onMouseLeave={() => setAuxDescIsShown(false)}
                    />}
                </div>
                { (AuxDescIsShown === auxItem.Description) &&
                <div className={styles.ModelConfigDescBlock}>{auxItem.Description}</div>
                }
                <span>{auxItem.Value} {auxItem.Units}</span>
            </div>
             )
        }

        return auxesRecords;
    }

    const StocksRows = () => {
        let stocksRecords = [];
        for (let i = 0; i < modelConfigStocks.length; i++) {
            let stockItem = modelConfigStocks[i];
            stocksRecords.push (
                <div className={styles.modelConfigRow}>
                    <div className={styles.modelConfigRowNameWrap}>
                    {stockItem.Name}
                    {stockItem.Description &&
                    <FontAwesomeIcon icon={faQuestionCircle}
                                     className={styles.ModelConfigQuestionIcon}
                                     onMouseEnter={() => setStockDescIsShown(stockItem.Description)}
                                     onMouseLeave={() => setStockDescIsShown(false)}
                    />}
                    </div>
                    { (StockDescIsShown === stockItem.Description) &&
                    <div className={styles.ModelConfigDescBlock}>{stockItem.Description}</div>
                    }
                    <span>{stockItem.Value} {stockItem.Units}</span>
                </div>
            )
        }
        return stocksRecords;
    }

    let modelConfigAuxes = {}, modelConfigStocks = {}, timeConfigProps = {};
    if (modelConfigData && modelConfigData.Properties) {
         modelConfigAuxes = modelConfigData.Properties.Auxes;
         modelConfigStocks = modelConfigData.Properties.Stocks;
         timeConfigProps = modelConfigData.TimeProperties;
    }
        return (
            <div className={styles.modelConfig}>
                <h2 className={styles.modelConfigHeader}>Model configuration</h2>
                <div className={styles.modelConfigWrapper}>
                    <div className={styles.modelConfigBlock}>
                        <h3 className={styles.modelConfigBlockHeader}>Modeling time range</h3>
                        <div className={styles.modelConfigRow}>
                            <span className={styles.modelConfigRowName}>Start time</span>
                            <span>{timeConfigProps.Start}</span>
                        </div>
                        <div className={styles.modelConfigRow}>
                            <span className={styles.modelConfigRowName}>End time</span>
                            <span>{timeConfigProps.End}</span>
                        </div>
                        <div className={styles.modelConfigRow}>
                            <span className={styles.modelConfigRowName}>Time step</span>
                            <span>{timeConfigProps.Step}</span>
                        </div>
                    </div>
                    <div className={styles.modelConfigBlock}>
                        <h3 className={styles.modelConfigBlockHeader}>Auxilaries</h3>
                        <AuxesRows/>

                    </div>
                    <div className={styles.modelConfigBlock}>
                        <h3 className={styles.modelConfigBlockHeader}>Stocks</h3>
                        <StocksRows/>
                    </div>
                </div>
            </div>
           );
}

export default ModelConfig;

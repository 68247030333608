import React from "react";
/* Styles of the component */
//import styles from "./styles.module.scss";
// import LineChart from 'react-linechart';
// import '../../../../../node_modules/react-linechart/dist/styles.css';
import Plot from "react-plotly.js";

/**
 * This method renders a line chart with multiple lines to show
 * model execution results grafically
 *
 * @param {executionDetails} param0 - execution details to be rendered
 */
const ResultsChart = ({ executionDetails }) => {
  let maxValue = -10000;
  let colors = [
    "rgb(82,239,153)",
    "rgb(29,117,131)",
    "rgb(105,183,197)",
    "rgb(68,80,130)",
    "rgb(157,151,211)",
    "rgb(111,58,147)",
    "rgb(208,101,225)",
    "rgb(83,28,232)",
    "rgb(188,224,145)",
    "rgb(9,123,53)",
    "rgb(54,229,21)",
    "rgb(124,138,79)",
    "rgb(199,225,31)",
    "rgb(117,72,25)",
    "rgb(216,160,108)",
    "rgb(253,44,59)",
    "rgb(246,138,173)",
    "rgb(141,16,43)",
    "rgb(247,121,79)",
    "rgb(252,209,7)",
    "rgb(62,76,20)",
    "rgb(255,0,135)",
    "rgb(242,49,252)",
  ];
  let data = [];
  if (executionDetails[0]) {
    let keys = Object.keys(executionDetails[0].Values);
    let keysCount = keys.length;

    for (let i = 0; i < keysCount; i++) {
      data.push({
        marker: {
          color: colors[i],
        },
        name: keys[i],
        x: [],
        y: [],
      });
    }

    if (executionDetails[0]) {
      for (let i = 0; i < executionDetails.length; i++) {
        let moment = executionDetails[i].Time;
        for (let keyIndex = 0; keyIndex < keysCount; keyIndex++) {
          data[keyIndex].x.push(moment);
          data[keyIndex].y.push(executionDetails[i].Values[keys[keyIndex]]);
          if (executionDetails[i].Values[keys[keyIndex]] > maxValue) {
            maxValue = executionDetails[i].Values[keys[keyIndex]];
          }
        }
      }
    }
  }

  return (
    <Plot
      id="executionResultsLineChart"
      data={data}
      legendPosition="top-right"
      xLabel="Time"
      yLabel="Stocks values"
      yMax={maxValue * 1.2}
      xMax={executionDetails[executionDetails.length - 1].Time * 1.2}
      layout={{ margin: { t: 30, b: 30, l: 30, r: 30 } }}
      style={{ width: "100%", height: "100%" }}
      config={{
        displaylogo: false,
        responsive: true,
      }}
    />
  );
};

export default ResultsChart;

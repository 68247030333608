import * as React from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import Routes from './Routes';
import {ReactNotifications} from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import './assets/style/index.scss';
import styles from './styles.module.scss';
import sdCloudLogoGif from './assets/img/sdcloud-splash-v1.gif'
import {useEffect, useState} from "react";
import {LOADING_SIGNS} from "./const/app";


const App = () => {
  const [isApplicationLoaded, setIsApplicationLoaded] = useState(false);
  const randomSign = LOADING_SIGNS[Math.floor(Math.random() * LOADING_SIGNS.length)];

  useEffect(() => {
    setTimeout(() => setIsApplicationLoaded(true), 2000);

  }, []);

  return (
    <>
      {!isApplicationLoaded &&
        <div className={styles.splashScreen}>
          <img
            className={styles.splashScreenImage}
            src={sdCloudLogoGif}
            alt={'logo'}
          />
          <h2>{randomSign}</h2>
        </div>
      }
        <Router>
          <ReactNotifications/>
          <Routes/>
        </Router>
    </>
  );
};

export default App;
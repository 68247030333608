import React, {useState, useEffect} from 'react';
import {useHistory} from "react-router";
import styles from './styles.module.scss';
import moment from "moment";
import apiRequest from 'src/lib/apiRequest';
import DateTimePicker from 'react-datetime-picker';
import MarkdownComponent from "../../components/common/markdownComponent/markdownComponent";
import SDPanel from '../../components/common/sdPanel/sdPanel';

const NewAssignment = () => {
  const [name, setName] = useState(null);
  const [endDate, setEndDate] = useState(moment().add(1, "week").toDate());
  const [startDate, setStartDate] = useState(moment().toDate());

  const [description, setDescription] = useState(null);
  const [content, setContent] = useState("Here you can create Markdown description of the assignment");
  const [submissionNotes, setSubmissionNotes] = useState("");
  const [accessCode, setAccessCode] = useState("");
  const [isSecured, setSecured] = useState(false);
  const [isHidden, setHidden] = useState(false);

  const [isStartDateDefined, setStartDateDefined] = useState(false);
  const [isEndDateDefined, setEndDateDefined] = useState(false);

  const history = useHistory();

  useEffect(() => {
    document.title = 'sdCloud :: Bringing System Dynamics Into Cloud :: Create new assignment'
  }, []);

  const sendAssignment = async () => {
    const data = {
      Name: name,
      Description: description,
      Content: content,
      CreatedDateUtc: new Date(),
      IsHidden: isHidden,
      SubmissionTemplate: submissionNotes
    };

    if (isSecured) {
      data.AccessCode = accessCode;
    }
    if (isEndDateDefined) {
      data.EndDateUtc = endDate;
    } else {
      data.EndDateUtc = null;
    }


    if (name && description) {
      const response =
        await new apiRequest('POST /assignments', false)
          .sendJSON(data);
      if (response) {
        history.push({
          pathname: '/assignments',
        });
      }
    }
  };

  const renderTextEditor = () => {
    return (
      <div className={styles.newModel}>
        <div className={styles.modelContainer}>
          <div className={styles.modelRow}>
            <SDPanel title="General information" width="100%" height="100px">
              <input
                className={styles.modelInput}
                style={{marginTop: '1rem'}}
                placeholder={" "}
                id="name"
                name="name"
                type="text"
                autoComplete={false}
                onChange={function (e) {
                  setName(e.target.value)
                }}/>
              <label for={"name"} className={styles.modelLabel}>Name</label>

              <div className={styles.textField}>
                <textarea
                  className={styles.modelInput}
                  style={{marginTop: '1rem'}}
                  name="description"
                  id={'description'}
                  placeholder={" "}
                  onChange={function (e) {
                    setDescription(e.target.value)
                  }}
                />
                <label
                  for={'description'}
                  className={styles.modelLabelForTextArea}
                >
                  General description of the assignment.
                  Visible to everyone
                </label>
              </div>
            </SDPanel>
            <div className={styles.modelRow}>
              <SDPanel title="Visibility and Security" width="100%" height="100px">
                <div className={styles.checkBoxContainer}>
                  <input
                    type="checkbox"
                    className={styles.modelCheckBox}
                    style={{marginLeft: '0.2rem', marginTop: '0.5rem'}}
                    id={'check'}
                    checked={isSecured}
                    onChange={function (e) {
                      setSecured(e.target.checked);
                    }}
                  />
                  <label
                    for={'check'}
                    className={styles.modelLabelForCheckBox}
                    style={{marginTop: '0.5rem'}}
                  >
                    Secure assignment
                  </label>
                </div>
                {isSecured ?
                  <div>
                    <input
                      className={styles.modelInput}
                      id={'accessCode'}
                      placeholder={" "}
                      style={{marginTop: '0.6rem'}}
                      type="text"
                      onChange={function (e) {
                        setAccessCode(e.target.value)
                      }}
                    />
                    <label for={'accessCode'} className={styles.modelLabel}>Enter Access Code</label>
                  </div>
                  :
                  <span/>
                }

                <div className={styles.checkBoxContainer}>
                  <input
                    type="checkbox"
                    className={styles.modelCheckBox}
                    style={{marginLeft: '0.2rem', marginTop: '0.5rem'}}
                    id={'checkIsHidden'}
                    checked={isHidden}
                    onChange={function (e) {
                      setHidden(e.target.checked);
                    }}
                  />
                  <label
                    for={'checkIsHidden'}
                    className={styles.modelLabelForCheckBox}
                    style={{marginTop: '0.5rem'}}
                  >
                    Hide assignment
                  </label>
                </div>
                {isHidden ?
                  <div>
                    <input
                      className={styles.modelInput}
                      id={'joinCode'}
                      placeholder={"Will be generated"}
                      style={{marginTop: '0.6rem'}}
                      type="text"
                      readOnly="true"
                    />
                    <label for={'joinCode'} className={styles.modelLabel}></label>
                  </div>
                  :
                  <span/>
                }
              </SDPanel>
            </div>
            
            <div className={styles.modelRow}>
              <SDPanel title="Timeline configuration" width="100%" height="100px">
                <div className={styles.checkBoxContainer}>
                  <input
                    type="checkbox"
                    className={styles.modelCheckBox}
                    style={{marginLeft: '0.2rem', marginTop: '0.5rem'}}
                    id={'checkIsEndDateDefined'}
                    checked={isEndDateDefined}
                    onChange={function (e) {
                      setEndDateDefined(e.target.checked);
                    }}
                  />
                  <label
                    for={'checkIsStartDateDefined'}
                    className={styles.modelLabelForCheckBox}
                    style={{marginTop: '0.5rem'}}
                  >
                    Specify assignment end date
                  </label>
                </div>
                {isEndDateDefined ?
                  <div className={styles.dateTimeContainer}>
                    <div className={styles.assignmentEditRightColumn}>
                      <DateTimePicker
                        maxDetail="minute"
                        clearIcon={null}
                        onChange={setEndDate}
                        value={endDate}/>
                    </div>
                  </div>
                  :
                  <span/>
                }
              </SDPanel>
              
            </div>
            
            <SDPanel title="Assignment content" width="100%" height="100px">
              <div className={styles.textField}>
                <h2>Problem statement</h2>
                <span>
                  <i>
                    Problem statement to be solved by assignment participants.
                    In case of secured assignment, will be visible only to enrolled users
                  </i>
                </span>
                <MarkdownComponent markdownContent={content} updateRoot={setContent} isEditable={true}/>
              </div>
            </SDPanel>

            <SDPanel title="Submission template" width="100%" height="100px">
                    <h2 className={styles.assignmentsHeaderNew}>Submission template</h2>
                    <span>
                      <i>
                        Optional: You can specify a template for the submission notes for this assignment. This notes template will
                        be offered to participants when they will start working on their submission. Here you can specify list of 
                        response sections to be included or general recommendations on how to fill in submission notes for this
                        assignemnt. You can use <a href="https://daringfireball.net/projects/markdown/" target="_blank"> Markdown </a>
                        syntax to make description better formatted and structured.
                      </i>
                    </span>
                    <div className={styles.assignmentsRow}>
                        <MarkdownComponent
                            markdownContent={submissionNotes}
                            updateRoot={setSubmissionNotes}
                            isEditable={true}
                        />
                    </div>
                </SDPanel>
            <div className={`${styles.modelRow} ${styles.buttonFooter}`}>
              <button className={styles.buttonModel} onClick={sendAssignment}>
                Create
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.newModel}>
      <div className={styles.modelContainer}>
        <div className={styles.modelRow}>
          <div className={styles.modelHeader}>Create new assignment</div>
          <div>
            {renderTextEditor()}
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewAssignment;

exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".src-components-common-sdInfoCard-___styles-module__card___FDKl4{border-radius:.5rem;border:1px solid #3F88C5;-webkit-box-shadow:0 1px 3px rgba(0, 0, 0, .12),0 1px 2px rgba(0, 0, 0, .24);box-shadow:0 1px 3px rgba(0, 0, 0, .12),0 1px 2px rgba(0, 0, 0, .24);margin-top:32px;margin-right:20px;width:350px;float:left;position:relative;padding-bottom:20px}.src-components-common-sdInfoCard-___styles-module__header___1c4OM{color:#404040;width:80%;font-size:28px;text-align:center;margin:20px auto 10px;font-weight:bold}.src-components-common-sdInfoCard-___styles-module__mainText___2vnD8{display:-webkit-box;display:-ms-flexbox;display:flex;padding-left:5px;padding-right:5px;height:15%;margin:auto;text-align:center;color:#3F88C5;font-size:22px;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column}.src-components-common-sdInfoCard-___styles-module__data___3ek8E{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:space-evenly;-ms-flex-pack:space-evenly;justify-content:space-evenly}.src-components-common-sdInfoCard-___styles-module__titleHr___Qze4_{width:90%;height:1px;border:none;background-color:#3F88C5;margin-top:-5px}.src-components-common-sdInfoCard-___styles-module__badge___2ZgJW{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:right;-ms-flex-pack:right;justify-content:right;margin-right:2px;margin-top:2px}.src-components-common-sdInfoCard-___styles-module__badgeName___1opEk{padding:0 10px;font-size:14px;background-color:#73899E;border-radius:.5rem;color:#FFFFFF;margin-bottom:-20px}.src-components-common-sdInfoCard-___styles-module__textStyle___2e1Os{font-weight:bold}\n", ""]);

// Exports
exports.locals = {
	"card": "src-components-common-sdInfoCard-___styles-module__card___FDKl4",
	"header": "src-components-common-sdInfoCard-___styles-module__header___1c4OM",
	"mainText": "src-components-common-sdInfoCard-___styles-module__mainText___2vnD8",
	"data": "src-components-common-sdInfoCard-___styles-module__data___3ek8E",
	"titleHr": "src-components-common-sdInfoCard-___styles-module__titleHr___Qze4_",
	"badge": "src-components-common-sdInfoCard-___styles-module__badge___2ZgJW",
	"badgeName": "src-components-common-sdInfoCard-___styles-module__badgeName___1opEk",
	"textStyle": "src-components-common-sdInfoCard-___styles-module__textStyle___2e1Os"
};
import React, { useState, useEffect } from "react";
import "animate.css/animate.min.css";
import { Store as store } from "react-notifications-component";
import { useHistory, useParams } from "react-router";
import styles from "./styles.module.scss";
import apiRequest from "../../lib/apiRequest";

const Parameters = () => {
  const { id } = useParams();

  const history = useHistory();

  const [stocks, setStocks] = useState([]);
  const [auxes, setAuxes] = useState([]);
  const [modelName, setModelName] = useState([]);
  const [timeParam, setTime] = useState([]);
  const [fileName, setFileName] = useState("");
  let newParamStocks = {};
  let newAuxesParams = {};
  let newTimeParams = {};

  useEffect(() => {
    getParameters(setModelName, setStocks, setAuxes, setTime);
  }, []);

  const getParameters = async (setModelName, setStocks, setAuxes, setTime) => {
    const { response } = await new apiRequest(
      "GET /models/" + id,
      false
    ).send();
    setModelName(response.Entity.Name);
    setStocks(response.Entity.Properties.Stocks);
    setAuxes(response.Entity.Properties.Auxes);
    setTime(response.Entity.TimeProperties);
    setFileName(response.Entity.FileName);
    return [];
  };

  const sendModelParams = async (type) => {
    const Parameters = Object.assign({}, newParamStocks, newAuxesParams);
    for (let item in Parameters) {
      if (Parameters[item] == "" || isNaN(Parameters[item])) {
        delete Parameters[item];
      }
    }
    for (let item in newTimeParams) {
      if (newTimeParams[item] == "" || isNaN(newTimeParams[item])) {
        delete newTimeParams[item];
      }
    }
    const params = {
      Type: type,
      Parameters,
    };
    let data = Object.assign({}, params, newTimeParams);
    const response = await new apiRequest(
      "POST /models/" + id + "/executions",
      false
    ).sendJSON(data);
    if (response) {
      store.addNotification({
        title: "Success",
        message: "Execution queued",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
        },
      });
    } else {
      store.addNotification({
        title: "Error",
        message: `${response.error.Message}`,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
        },
      });
    }
    history.goBack();
  };

  const setNewParamStocks = (newParamStocks, name, e) => {
    newParamStocks[name] = +e.target.value;
    return newParamStocks;
  };

  const setNewParamAuxes = (newAuxesParams, name, e) => {
    newAuxesParams[name] = +e.target.value;
    return newAuxesParams;
  };

  const setNewParamTime = (newTimeParams, name, e) => {
    newTimeParams[name] = +e.target.value;
    return newTimeParams;
  };

  const Models = () => {
    history.goBack();
  };

  const Time = (timeParam) => {
    return (
      <div className={styles.paramsContainer}>
        <div className={styles.params}>
          <div className={styles.inputHeader}>Start time</div>
          <input
            defaultValue={timeParam.Start}
            onChange={(e) => setNewParamTime(newTimeParams, "StartTime", e)}
          />
          <div className={styles.units}>{timeParam.Units}</div>
        </div>
        <div className={styles.params}>
          <div className={styles.inputHeader}>End time</div>
          <input
            defaultValue={timeParam.End}
            onChange={(e) => setNewParamTime(newTimeParams, "EndTime", e)}
          />
          <div className={styles.units}>{timeParam.Units}</div>
        </div>
        <div className={styles.params}>
          <div className={styles.inputHeader}>Delta time</div>
          <input
            defaultValue={timeParam.Step}
            onChange={(e) => setNewParamTime(newTimeParams, "Timestep", e)}
          />
          <div className={styles.units}>{timeParam.Units}</div>
        </div>
      </div>
    );
  };

  const Stock = (stocks) => {
    const stocksParams = stocks.map((item) => {
      let description;
      if (item.Description) {
        description = item.Description.replace(/\s+/g, " ");
      }
      let name =
        item.Name[0] +
        item.Name.replace(/_/g, " ").toLowerCase().slice(1).substring();
      return (
        <div className={styles.paramsContainer}>
          <div className={styles.params}>
            <div className={styles.inputHeader}>{name}</div>
            <input
              name={item.Name}
              defaultValue={item.Value}
              onChange={(e) => setNewParamStocks(newParamStocks, item.Name, e)}
            />
            <div className={styles.units}>{item.Units}</div>
          </div>
          <div className={styles.description}>{description}</div>
        </div>
      );
    });
    if (stocks.length != 0) {
      return <div>{stocksParams}</div>;
    } else {
      return (
        <div className={styles.parameters}>
          <div className={styles.description}>{modelName} has no stocks</div>
        </div>
      );
    }
  };

  const Aux = (auxes) => {
    const auxParams = auxes.map((item) => {
      let description;
      if (item.Description) {
        description = item.Description.replace(/\s+/g, " ");
      }
      let name =
        item.Name[0] +
        item.Name.replace(/_/g, " ").toLowerCase().slice(1).substring();
      return (
        <div className={styles.paramsContainer}>
          <div className={styles.params}>
            <div className={styles.inputHeader}>{name}</div>
            <input
              name={name}
              defaultValue={item.Value}
              onChange={(e) => setNewParamAuxes(newAuxesParams, item.Name, e)}
            />
            <div className={styles.units}>{item.Units}</div>
          </div>
          <div className={styles.description}>{description}</div>
        </div>
      );
    });
    if (auxes.length != 0) {
      return <div>{auxParams}</div>;
    } else {
      return (
        <div className={styles.parameters}>
          <div className={styles.description}>
            {modelName} has no auxilaries
          </div>
        </div>
      );
    }
  };

  return (
    <div className={styles.parameters}>
      <div className={styles.paramsHeader}>
        Custom execution for {modelName}
      </div>
      <div className={styles.headerDescription}>
        Here you can specify execution configuration for custom simulation
      </div>
      <div className={styles.paramsBlock}>
        <div className={styles.subtitle}>Modelling time configuration</div>
        {Time(timeParam)}
      </div>
      <div className={styles.paramsBlock}>
        <div className={styles.subtitle}>Stocks configuration</div>
        {Stock(stocks)}
      </div>
      <div className={styles.paramsBlock}>
        <div className={styles.subtitle}>Auxilaries configuration</div>
        {Aux(auxes)}
      </div>
      <div className={styles.buttonFooter}>
        <div
          className={`${styles.buttonParam} ${styles.buttonBack}`}
          onClick={Models}
        >
          Back
        </div>

        <div className="container">
          <button
            className={`${styles.buttonParam} ${styles.buttonPySD} ${styles.btn}`}
          >
            <span>Run</span>
            <span> ...</span>

            <ul className={`${styles.dropdown}`}>
              <li className={`${styles.active} ${styles.li}`}>
                <a
                  className={`${styles.a}`}
                  onClick={() => sendModelParams("pysd")}
                >
                  with PySD
                </a>
              </li>
              {fileName && fileName.split('.')[fileName.split('.').length-1].toLowerCase() === "mdl" &&
              <li className={`${styles.li}`}>
                <a
                  className={`${styles.a}`}
                  onClick={() => sendModelParams("sdeverywhere")}
                >
                  with SDE
                </a>
              </li>}
            </ul>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Parameters;

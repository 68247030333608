import styles from './styles.module.scss';
import * as React from 'react';

const ErrorPage = () => {
    return (
        <div className={ styles.Content }>
            <div className={styles.Oopstext}> Ooops! Something wrong....</div>
            <div className={styles.MainText}> Do not worry, we are already working on this issue. Please try again later</div>
        </div>
    );
};

export default ErrorPage;
import React from "react";
import styles from "./styles.module.scss";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const statusToColor = {
    'Success': "#6EFF6E",
    'Error': "#FF6E6E",
    'Queued': "#F98C32",
    'InProgress': "#3F88C5",
    'Canceled': "#A0A0A0"
}


const ExecutionsRows = ({ attachmentsList, deleteAttachment, assignmentId, enrollmentId }) => {
    let executionsRecords = [];

    let executionAttachments = attachmentsList.filter(x => x.AttachmentType == 2);
    for (var j = 0; j < executionAttachments.length; j++) {

        var key = "executionRow_" + j;
        var attachment = executionAttachments[j];
        
        const executionDate = (new Date(attachment.Metadata.StartDate * 1000)).toLocaleString()
        const queuedDate = new Date(attachment.Metadata.StartDate * 1000);
        const endDate = new Date(attachment.Metadata.EndDate * 1000);
        const modelTime = Math.abs(endDate - queuedDate);
        const fancyExecutionView = new Date(modelTime).toISOString().substr(11, 8);

        let path="";
        if (enrollmentId) {
            path=`/models/0/executions/0?source=assignment&assignment-id=${assignmentId}&attachment-id=${attachment.Id}&enrollment-id=${enrollmentId}`;
        } else {
            path=`/models/0/executions/0?source=assignment&assignment-id=${assignmentId}&attachment-id=${attachment.Id}`;
        }

        executionsRecords.push(
            <div className={styles.assignmentsListRow} key={key}>
                <div className={styles.assignmentsListCell}>
                    {attachment.Id}
                </div>
                <div className={styles.assignmentsListCell}>
                    <Link to={path}>{attachment.AttachmentId}</Link></div>
                <div className={styles.assignmentsListCell}>
                    {attachment.Metadata.ModelId}
                </div>
                <div className={styles.assignmentsListCell}>
                    {attachment.Metadata.Engine}
                </div>
                <div className={styles.assignmentsListCell}>
                    {executionDate}
                </div>
                <div className={styles.assignmentsListCell}>
                    {fancyExecutionView}
                </div>
                <div className={styles.assignmentsListCell}
                    style={{ backgroundColor: statusToColor[attachment.Metadata.Status] }}>{attachment.Metadata.Status}
                </div>
                {deleteAttachment &&
                    <div className={styles.assignmentsListActionsCell}>
                        <FontAwesomeIcon className={styles.attachmentDeleteIcon}
                            icon="trash"
                            onClick={() => deleteAttachment(attachment.Id)} />
                    </div>
                }
            </div>);
    }

    return executionsRecords;
};

export default ExecutionsRows;
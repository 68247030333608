/**
 *  AssignmentDetails component
 */
import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router";
import styles from "./styles.module.scss";
import SliderMenu from "../../components/execution/components/sliderMenu/sliderMenu";
import { useLocation } from "react-router-dom";
import apiRequest from "../../lib/apiRequest";
import Skeleton from "react-loading-skeleton";
import { Store as store } from "react-notifications-component";
import MarkdownComponent from "../common/markdownComponent/markdownComponent";
import AssignmentInfo from "./assignmentInfo/assignmentInfo";
import AssignmentEnrollment from "./assignmentEnrollment/assignmentEnrollment";
import AssignmentEdit from "./assignmentEdit/assignmentEdit";
import MySubmissions from "./mySubmissions/mySubmissions";
import GradeBook from "./gradeBook/gradeBook";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SDCModalDialog from "../common/sdcModalDialog/sdcModalDialog";

const SkeletonPanels = () => (
  <div className={styles.AssignmentDetailsContainerInfo}>
    <div className={styles.assignmentDetailsInfo}>
      <Skeleton />
    </div>
    <div className={styles.assignmentDetailsInfo}>
      <Skeleton />
    </div>
  </div>
);

const AssignmentDetails = () => {
  const [assignmentDetails, setAssignmentDetails] = useState();
  const [availableAssignmentsList, setAvailableAssignmentsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [assignmentContent, setContent] = useState("");
  const [view, setView] = useState("");

  const [showCodeDialog, setShowCodeDialog] = useState("False");
  const [selectedAssignmentId, setSelectedAssignmentId] = useState(-1);
  const [assignmentAccessCode, setAssignmentAccessCode] = useState("");
  const [isEnrolled, setIsEnrolled] = useState(
    assignmentDetails?.StatusAsString === "Enrolled"
  );

  const { id } = useParams();
  const history = useHistory();
  const location = useLocation();

  const search = useLocation().search;
  const qJoinCode = new URLSearchParams(search).get("joinCode");

  useEffect(() => {
    getAssignmentDetails(setAssignmentDetails);
  }, []);

  useEffect(() => {
    setIsEnrolled(assignmentDetails?.StatusAsString === "Enrolled");
  }, [assignmentDetails]);

  useEffect(() => {
    const qAspect = new URLSearchParams(location.search).get("aspect");
    if (qAspect) {
      setView("sliderMenuItem_" + qAspect);
    } else {
      setView("sliderMenuItem_overview");
    }
    getAssignmentDetails(setAssignmentDetails);
  }, [location]);

  const getAssignmentDetails = async (setAssignmentDetails) => {
    setLoading(true);
    let requestPath = "/assignments/" + id;
    if (qJoinCode) {
      requestPath += "?joinCode=" + qJoinCode;
    }
    const { response } = await new apiRequest(
      "GET " + requestPath,
      false
    ).send();
    if (response) {
      setContent(response.Entity.Content);
      var result = setAssignmentDetails(response.Entity);
      setLoading(false);
      return result;
    } else {
      let responseToRender = {
        Name: "Not Found :(",
        Description: "Unfortunately, requested assignment was not found.",
        IsNotFound: true
      }
      var result = setAssignmentDetails(responseToRender);
      setLoading(false);
      return result;
    }
    return [];
  };

  const handleLeaveButtonClick = async (assignmentId) => {
    var response = await new apiRequest(
      `DELETE /assignments/${assignmentId}/enrollment`
    ).send();
    if (response) {
      setIsEnrolled(false);
      store.addNotification({
        title: "Success",
        message: "Assignment left",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
        },
      });
    } else {
      store.addNotification({
        title: "Error",
        message: `${response.error.Message}`,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
        },
      });
    }
    getAssignmentDetails(setAssignmentDetails);
  };

  const responseToNotification = (response) => {
    if (response.response !== false) {
      setIsEnrolled(true);
      store.addNotification({
        title: "Success",
        message: "Enrollment successful",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
        },
      });
      getAssignmentDetails(setAssignmentDetails);
    } else {
      store.addNotification({
        title: "Failed to enroll",
        message: `${response.error.Message}`,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
        },
      });
    }
  };

  /*-----------------------------

       ASSIGNMENT ENROLLMENT LOGIC:

     -----------------------------*/

  const handleEnrollButtonClick = async (assignmentId) => {
    if (assignmentDetails.Secured) {
      setSelectedAssignmentId(assignmentId);
      setShowCodeDialog("True");
    } else {
      let requestPath = `/assignments/${assignmentId}/enrollment`;
      if (qJoinCode) {
        requestPath += "?joinCode=" + qJoinCode;
      }
      let response = await new apiRequest(
        `POST ` + requestPath
      ).send();
      responseToNotification(response);
    }
  };

  const closeCodeDialog = () => {
    setShowCodeDialog("False");
    setAssignmentAccessCode("");
  };

  const handleCodeChange = (e) => {
    setAssignmentAccessCode(e.target.value);
  };

  const dialogBody = (
    <div>
      Please, enter access code to enroll to this assignment
      <input
        className={styles.modelInput}
        style={{
          marginTop: "15px",
          width: "160px",
        }}
        onChange={handleCodeChange}
        name={"code"}
        placeholder={" "}
        id={"code"}
        autoComplete={"off"}
        value={assignmentAccessCode}
      />
      <label for={"code"} className={styles.modelLabel}>
        Access code
      </label>
    </div>
  );

  const enrollToAssignmentWithCode = async () => {
    let requestPath = "/assignments/" + selectedAssignmentId + "/enrollment";
    const response = await new apiRequest(
      "POST " + requestPath,
      false
    ).sendJSON({ AccessCode: assignmentAccessCode });
    responseToNotification(response);
    closeCodeDialog();
    getAssignmentDetails(setAssignmentDetails);
  };

  const renderOverview = () => {
    return (
      <div className={styles.assignments}>
        {loading && <SkeletonPanels />}
        {!loading && (
          <div className={styles.assignmentsContainer}>
            <div className={styles.AssignmentDetailsContainerInfo}>
              <AssignmentInfo assignmentData={assignmentDetails} />
              <AssignmentEnrollment
                isEnrolled={isEnrolled}
                enrollFunc={() => handleEnrollButtonClick()}
                leaveCommand={() => handleLeaveButtonClick(id)}
                enrollCommand={() => handleEnrollButtonClick(id)}
              />
            </div>
            <div className={styles.assignmentsRow}>
              <MarkdownComponent
                markdownContent={assignmentContent}
                updateRoot={setContent}
                isEditable={false}
              ></MarkdownComponent>
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderSubmission = () => <MySubmissions assignmentId={id} assignmentData={assignmentDetails}/>;

  const renderDetails = () => {
    if (assignmentDetails) {
      return <AssignmentEdit assignmentData={assignmentDetails} />;
    } else {
      return <h1>No details available</h1>;
    }
  };

  const renderBook = () => <GradeBook assignmentId={id} />;

  const renderControlSlider = () => {
    var menuItems = [];
    if (assignmentDetails) {
      var defaultSelected = view;
      menuItems.push({
        name: "Overview",
        id: "sliderMenuItem_overview",
      });
      if (assignmentDetails.StatusAsString === "Enrolled") {
        menuItems.push({
          name: "My submission",
          id: "sliderMenuItem_submission",
        });
      }
      if (assignmentDetails.IsOwned) {
        menuItems.push({
          name: "Edit details",
          id: "sliderMenuItem_details",
        });
        menuItems.push({
          name: "Grade book",
          id: "sliderMenuItem_book",
        });
      }
    }
    return (
      <div>
        {loading ? (
          <Skeleton />
        ) : (
          <SliderMenu
            items={menuItems}
            selected={defaultSelected}
            callback={sliderMenuSelectionChanged}
          />
        )}
      </div>
    );
  };

  const sliderMenuSelectionChanged = (selectedItemId) => {
    if (selectedItemId !== view) {
      const selectedAspect = selectedItemId
        .toString()
        .replace("sliderMenuItem_", "");
      history.push({ search: `?aspect=${selectedAspect}` });
    }
  };

  const handleEnterPressEdit = async (e) => {
    if (e.keyCode === 13 && !e.ctrlKey) {
      handleEditHeader();
      e.preventDefault();
    }
  };

  const handleEditHeader = async () => {
    await new apiRequest(`POST /assignments/${assignmentDetails.Id}`).send(
      assignmentDetails
    );
  };

  return (
    <div className={styles.assignments}>
      <SDCModalDialog
        height="200"
        show={showCodeDialog}
        title="Access key is required"
        body={dialogBody}
        onCancel={closeCodeDialog}
        onOk={enrollToAssignmentWithCode}
      />
      <div className={styles.assignmentsContainer}>
        <div className={styles.assignmentsRow}>
          <div className={styles.headerWrapper}>
            <h1
              contentEditable={
                assignmentDetails && assignmentDetails.IsOwned
                  ? "true"
                  : "false"
              }
              suppressContentEditableWarning={true}
              onInput={(e) =>
                (assignmentDetails.Name = e.currentTarget.textContent)
              }
              onKeyDown={handleEnterPressEdit}
              className={
                assignmentDetails && assignmentDetails.IsOwned
                  ? styles.assignmentDetailsHeader
                  : styles.assignmentDetailsHeaderInactive
              }
            >
              {assignmentDetails ? assignmentDetails.Name : "Loading..."}
              <FontAwesomeIcon className={styles.titleEditIcon} icon="edit" />
            </h1>
          </div>

          <div
            contentEditable={
              assignmentDetails && assignmentDetails.IsOwned ? "true" : "false"
            }
            suppressContentEditableWarning={true}
            onInput={(e) =>
              (assignmentDetails.Description = e.currentTarget.textContent)
            }
            onKeyDown={handleEnterPressEdit}
            className={
              assignmentDetails && assignmentDetails.IsOwned
                ? styles.assignmentDetailsContainerDesc
                : styles.assignmentDetailsContainerDescInactive
            }
          >
            <p>
              {assignmentDetails ? assignmentDetails.Description : ""}
              <FontAwesomeIcon
                className={styles.descriptionEditIcon}
                icon="edit"
              />
            </p>
          </div>

          {assignmentDetails && !assignmentDetails.IsNotFound &&
          <div>
            {renderControlSlider()}
            {!!(view === "sliderMenuItem_overview") && renderOverview()}
            {!!(view === "sliderMenuItem_submission") && renderSubmission()}
            {!!(view === "sliderMenuItem_details") && renderDetails()}
            {!!(view === "sliderMenuItem_book") && renderBook()}
          </div>}
        </div>
      </div>
    </div>
  );
};

export default AssignmentDetails;

import React, { useState, useEffect } from "react";
import styles from "../styles.module.scss";
import apiRequest from 'src/lib/apiRequest';

const executionStatMockData = {
    avgDataUsage: "27.43 RB"
}

const ExecutionStat = ({executionsData}) => {

    const [allRunsData, setAllRunsData, allStartTimeList, setAllStartTimeList] = useState([]);

    let avgExecTimeSum = 0;
    let complitedRunsCount = 0;

    if (executionsData) {

        executionsData.forEach(el => {
            if (el.EndDateUtc) {
                avgExecTimeSum += Date.parse(el.EndDateUtc) - Date.parse(el.StartDateUtc);
                complitedRunsCount++;
            }
        });
        const avgExecTimeValue = (avgExecTimeSum / (complitedRunsCount != 0 ? complitedRunsCount : 1) ) / 1000;
        const allRunsCount = executionsData.length;

        const successfulRunsById = executionsData.filter(function (el) {
            return el.StatusAsString === 'Success';
        })
        const succesfulRunsCount = successfulRunsById.length;
        const failedRunsById = executionsData.filter(function (el) {
            return el.StatusAsString === 'Error';
        })
        const failedRunsCount = failedRunsById.length;
    
        return (
            <div className={styles.modelDetailsExecStat}>
                <h2 className={styles.modelDetailsCardHeader}>Execution Statistics</h2>
                <div className={styles.modelDetailsExecStatRow}>
                    <span className={styles.modelDetailsExecStatRowName}>Success runs count</span>
                    <span>{succesfulRunsCount} ({((succesfulRunsCount/allRunsCount)*100).toFixed(2)}%)</span>
                </div>
                <div className={styles.modelDetailsExecStatRow}>
                    <span className={styles.modelDetailsExecStatRowName}>Failed runs count</span>
                    <span>{failedRunsCount} ({((failedRunsCount/allRunsCount)*100).toFixed(2)}%)</span>
                </div>
                <div className={styles.modelDetailsExecStatRow}>
                    <span className={styles.modelDetailsExecStatRowName}>Total runs count</span>
                    <span>{allRunsCount}</span>
                </div>
                <div className={styles.modelDetailsExecStatRow}>
                    <span className={styles.modelDetailsExecStatRowName}>Average time</span>
                    <span>{avgExecTimeValue.toFixed(2)} s</span>
                </div>
            </div>
        );
    } else {
        return (
            <div className={styles.modelDetailsLastExecInfo}>
    
                <h2 className={styles.modelDetailsCardHeader}>Execution Statistics</h2>
                Model was not yet executed
            </div>
        );
    }
}

export default ExecutionStat;

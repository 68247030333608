import React, {useState, useEffect} from 'react';
import styles from './styles.module.scss';
import userState from 'src/globalState/user';
import ReCAPTCHA from "react-google-recaptcha";
import {useHistory} from "react-router";
import * as Yup from "yup";
import {Formik, Field, Form} from 'formik';
import ErrorMessageWrapper from "../../components/ErrorMessageWrapper";
import {REGEXP_EMAIL} from "../../const/regexp";
import {RECAPTCHA_SITE_KEY} from "../../env/env";
import AuthFormField from "../../components/AuthFormField";
import SDInput from "../../components/common/sdInput/sdInput";

const SignUp = () => {
    const isCaptchaDisabled = RECAPTCHA_SITE_KEY.length === 0;
    const [errorMessage, setErrorMessage] = useState(null);
    const recaptchaRef = React.createRef();

    const history = useHistory();

    useEffect(() => {
        document.title = 'sdCloud :: Bringing System Dynamics Into Cloud :: Sign-Up'
    }, []);

    const submit = async (values) => {
        const recaptchaResponse = isCaptchaDisabled ? "" : grecaptcha?.getResponse();
        const data = {
            UserName: values.username,
            Password: values.password,
            Email: values.email,
            Organisation: values.organisation || undefined,
            OrganisationRole: values.organisationRole || undefined,
            ConfirmPassword: values.confirmPassword
        };
        const {response, error} = await userState.signup(data, recaptchaResponse);
        if (response) {
            history.push('/');
        } else {
            if (error === "") {
                setErrorMessage("User name already exists");
            }
        }
    };

    return (
        <div className={styles.authBody}>
            <Formik
                initialValues={
                    {
                        username: "",
                        password: "",
                        confirmPassword: "",
                        email: "",
                        organisation: "",
                        organisationRole: "",
                        captcha: isCaptchaDisabled
                    }
                }
                validationSchema={
                    Yup.object().shape({
                        username: Yup.string()
                            .min(6, 'Username must be at least 6 characters long')
                            .max(32, 'Username must not exceed 32 characters long')
                            .required('Username cannot be empty'),
                        password: Yup.string()
                            .min(6, 'Password must be at least 6 characters long')
                            .max(100, 'Password must not exceed 100 characters long')
                            .required('Password cannot be empty'),
                        confirmPassword: Yup.string()
                            .oneOf([Yup.ref('password'), null], 'Passwords must match')
                            .required('Passwords must match'),
                        email: Yup.string()
                            .min(6, 'Email must be at least 6 characters long')
                            .max(100, 'Email must not exceed 100 characters long')
                            .matches(REGEXP_EMAIL, 'Email should be valid')
                            .required('Email cannot be empty'),
                        organisation: Yup.string()
                            .when('organisationRole', {
                                is: role => role && role.length > 0,
                                then: Yup.string()
                                    .required('Organisation is required')
                            })
                            .test(
                                'empty-check',
                                'Organisation name length must be between 2 and 100 characters long',
                                organisation => !organisation || (organisation.length >= 2 && organisation.length <= 100)
                            ),
                        organisationRole: Yup.string()
                            .test(
                                'empty-check',
                                'Organisation role length must be between 2 and 100 characters long',
                                role => !role || (role.length >= 2 && role.length <= 100)
                            ),
                        captcha: Yup.bool()
                            .test(
                                'captcha-check',
                                'Please fill in the captcha',
                                () => isCaptchaDisabled || recaptchaRef?.current?.getValue()
                            ).test(
                                'captcha-check-2',
                                'ReCaptcha not verified',
                                () => isCaptchaDisabled || grecaptcha?.getResponse()?.length !== 0
                            )
                    })}
                onSubmit={(values) => submit(values)}
            >
                {
                    ({errors, touched, handleSubmit}) => (
                        <Form
                            className={`${styles.authFormWrapper} ${styles.authControlsWrapper}`}
                            onKeyDown={e => {
                                if (e.key === 'Enter') {
                                    handleSubmit();
                                }
                            }}
                        >
                            <Field
                              label={"Username"}
                              name={"username"}
                              placeholder={"Login"}
                              type={"text"}
                              component={AuthFormField}
                            />
                            <Field
                              label={"Password"}
                              name={"password"}
                              type={"password"}
                              placeholder={"Password"}
                              component={AuthFormField}
                            />
                            <Field
                              label={"Confirm Password"}
                              name={"confirmPassword"}
                              type={"password"}
                              placeholder={"Repeat password"}
                              component={AuthFormField}
                            />
                            <Field
                              label={"Email"}
                              name={"email"}
                              placeholder={"Email"}
                              type={"text"}
                              component={AuthFormField}
                            />
                            <Field
                              label={"Organisation"}
                              name={"organisation"}
                              placeholder={"Organisation"}
                              type={"text"}
                              component={AuthFormField}
                            />
                            <Field
                              label={"Organisation Role"}
                              name={"organisationRole"}
                              placeholder={"Organisation Role"}
                              type={"text"}
                              component={AuthFormField}
                            />
                            <div className={`${styles.authLinkWrapper}`}>
                                <div
                                    className={styles.authLink}
                                    onClick={() => history.push('/login')}
                                >
                                    Login
                                </div>
                            </div>
                            {
                                !isCaptchaDisabled &&
                                <div>
                                    <ReCAPTCHA
                                        ref={recaptchaRef}
                                        sitekey={RECAPTCHA_SITE_KEY}
                                        className={styles.captcha}
                                        data-cy={"captcha"}
                                    />
                                </div>
                            }
                            {
                                Object.keys(errors).map(
                                    (error, key) => (
                                        touched[error] &&
                                        <ErrorMessageWrapper key={key}>
                                            {errors[error]}
                                        </ErrorMessageWrapper>
                                    )
                                )
                            }
                            <ErrorMessageWrapper>{errorMessage}</ErrorMessageWrapper>
                            <button
                                className={`${styles.authButton}`}
                                type={"submit"}
                            >
                                Sign up
                            </button>
                        </Form>
                    )
                }
            </Formik>
        </div>
    );
}

export default SignUp;

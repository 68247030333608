import React from "react";
import styles from "./styles.module.scss";

const SDPieIndicatorCard = (
  {
    title,
    subTitle,
    pieIndicator,
    badgeName
  }) => {

  return (
    <div className={styles.card}>
      {badgeName &&
      <div className={styles.badge}>
        <div className={styles.badgeName}>
        {badgeName}
        </div>
      </div>
      }
      <div className={styles.header}>
        {title}
      </div>
      <hr className={styles.titleHr}/>
      <div className={styles.mainText}>
        {subTitle}
      </div>
      <div className={styles.mainText}>
        {pieIndicator}
      </div>
    </div>
  )
};

export default SDPieIndicatorCard;

import React, { useState, useEffect } from "react";
import { useParams } from "react-router"
import { useHistory } from "react-router";
import styles from "./styles.module.scss";
import apiRequest from 'src/lib/apiRequest';
import { Link } from "react-router-dom";
import Skeleton from 'react-loading-skeleton';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const statusToColor = {
    'Success': "#6EFF6E",
    'Error': "#FF6E6E",
    'Queued': "#F98C32",
    'InProgress': "#3F88C5",
    'Canceled': "#A0A0A0"
}


const ExecutionsRows = ({ executionsList, attachmentsList, deleteAttachment, assignmentId, enrollmentId }) => {
    let executionsRecords = [];
    if (executionsList) {
        let executionAttachments = attachmentsList.filter(x => x.AttachmentType == 2);
        for (var j = 0; j < executionAttachments.length; j++) {
            for (var i = 0; i < executionsList.length; i++) {
                var key = "executionRow_" + i;
                var attachment = executionAttachments[j];
                var execution = executionsList[i];
                if (attachment.AttachmentId == execution.ModelExecutionId) {
                    const executionDate = (new Date(execution.StartDateUtc)).toLocaleString()
                    const queuedDate = Date.parse(execution.QueuedDateUtc + "Z");
                    const endDate = Date.parse(execution.EndDateUtc + "Z");
                    const modelTime = Math.abs(endDate - queuedDate);
                    const fancyExecutionView = new Date(modelTime).toISOString().substr(11, 8);

                    let path="";
                    if (enrollmentId) {
                        path=`/models/0/executions/0?source=assignment&assignment-id=${assignmentId}&attachment-id=${attachment.Id}&enrollment-id=${enrollmentId}`;
                    } else {
                        path=`/models/0/executions/0?source=assignment&assignment-id=${assignmentId}&attachment-id=${attachment.Id}`;
                    }

                    executionsRecords.push(
                        <div className={styles.assignmentsListRow} key={key}>
                            <div className={styles.assignmentsListCell}>
                                {attachment.Id}
                            </div>
                            <div className={styles.assignmentsListCell}>
                                <Link to={path}>{execution.ModelExecutionId}</Link></div>
                            <div className={styles.assignmentsListCell}>
                                {execution.ModelId}
                            </div>
                            <div className={styles.assignmentsListCell}>
                                {execution.ExecutionEngineId}
                            </div>
                            <div className={styles.assignmentsListCell}>
                                {executionDate}
                            </div>
                            <div className={styles.assignmentsListCell}>
                                {fancyExecutionView}
                            </div>
                            <div className={styles.assignmentsListCell}
                                style={{ backgroundColor: statusToColor[execution.StatusAsString] }}>{execution.StatusAsString}
                            </div>
                            {deleteAttachment &&
                                <div className={styles.assignmentsListActionsCell}>
                                    <FontAwesomeIcon className={styles.attachmentDeleteIcon}
                                        icon="trash"
                                        onClick={() => deleteAttachment(attachment.Id)} />
                                </div>
                            }
                        </div>);
                }
            }
        }
    }
    return executionsRecords;
};

export default ExecutionsRows;
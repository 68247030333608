/**
 *  Model component
 */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react";
import styles from "./styles.module.scss";
import {Link} from "react-router-dom";
import { useHistory } from "react-router";

const SDModelCard = ({
                id,
                name,
                description,
                created,
                updated,
                modelFileName,
                onDeleteButtonClick,
                onAttachButtonClick
            }) => {

    const history = useHistory();

    const Params = () => {
        history.push({
            pathname:  `/models/${id}`,
        })
    };

    const getModelType = (fileName) => {
        if (!!fileName) {
            const extension = fileName.split('.').pop();
            switch (extension) {
                case "mdl" :
                    return {name : "VENSIM", style : styles.modelLogoMDL};
                case "xmile" :
                    return {name : "XMILE", style : styles.modelLogoXMILE};
                case "itmx" :
                    return {name : "iThink XMILE", style : styles.modelLogoITMX};
                case "stmx" :
                    return {name : "STELLA XMILE", style : styles.modelLogoSTMX};
                default:
                    return {name : extension, style : styles.modelLogoNoFile};
            }
        } else {
            return {name : "", style : styles.modelLogoNoFile};
        }
    }

    const modelType = getModelType(modelFileName);

    return (
        <div className={`${styles.modelCard}`}>
            <div className={modelType.style}>
                {modelType.name}
            </div>

            <div className={styles.modelHeader}>
                <Link className={styles.modelHeaderTitle} to={`/models/${id}/modeldetails/`}>
                     {name}
                </Link>
            </div>

          <div className={styles.modelDescription}>
            {description}
          </div>

            <div className={styles.modelProperties}>
                <div className={styles.modelImage}/>
                <div className={styles.modelProperty}>
                    <div>
                        <div className={styles.modelPropertyKey}>
                            Created
                        </div>
                        <div className={styles.modelPropertyValue}>
                            {new Date(created).toLocaleString()}
                        </div>
                    </div >
                    {!!(updated) &&
                        <div>
                            <div className={styles.modelPropertyKey}>
                                Updated
                            </div>
                            <div className={styles.modelPropertyValue}>
                                {new Date(updated).toLocaleString()}
                            </div>
                        </div>
                    }
                    <div>
                        <div className={styles.modelPropertyKey}>
                            Has model
                        </div>
                        <div className={styles.modelPropertyValue}>
                            {!!(modelFileName) ? "true" : "false"}
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles.resultModel}>
                <div className={styles.buttonParams} data-cy={"runWithParamsButton"} onClick={Params}/>
                <div className={styles.buttonDelete} data-cy={"deleteModelButton"} onClick={onDeleteButtonClick}/>
                <div className={styles.buttonSubmit} data-cy={"attachButton"} onClick={onAttachButtonClick}/>
            </div>
        </div>
    );
}

export default SDModelCard;

// import "core-js/stable";
import "regenerator-runtime/runtime"; // Need for async/await

import * as React from 'react';
import * as ReactDOM from 'react-dom';

import App from './App';

ReactDOM.render(
    <App />,
    document.getElementById('root'),
);

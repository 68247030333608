import React, { useState, useEffect } from "react";
import { useParams } from "react-router"
import { useHistory } from "react-router";
import styles from "./styles.module.scss";
import apiRequest from 'src/lib/apiRequest';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Skeleton from 'react-loading-skeleton';

const statusToColor = {
    'Success': "#6EFF6E",
    'Error': "#FF6E6E",
    'Queued': "#F98C32",
    'InProgress': "#3F88C5",
    'Canceled': "#A0A0A0"
}

const ModelsRows = ({ modelsList, attachmentsList, deleteAttachment, assignmentId, enrollmentId}) => {
    let modelsRecords = [];

    if (modelsList) {
        let modelAttachments = attachmentsList.filter(x => x.AttachmentType == 1);
        for (var j = 0; j < modelAttachments.length; j++) {
            for (var i = 0; i < modelsList.length; i++) {
                var key = "dataRow_" + i;
                var model = modelsList[i];
                var attachment = modelAttachments[j];
                if (attachment.AttachmentId == model.Id) {
                    let path="";
                    if (enrollmentId) {
                        path=`/models/0/modeldetails/?source=assignment&assignment-id=${assignmentId}&attachment-id=${attachment.Id}&enrollment-id=${enrollmentId}`;
                    } else {
                        path=`/models/0/modeldetails/?source=assignment&assignment-id=${assignmentId}&attachment-id=${attachment.Id}`;
                    }
                    modelsRecords.push(
                        <div className={styles.assignmentsListRow} key={key}>
                            <div className={styles.assignmentsListCell}>{attachment.Id}</div>
                            <div className={styles.assignmentsListCell}>
                                {model.Id}
                            </div>
                            <div className={styles.assignmentsListCell}>
                                <Link to={path}>{model.Name}</Link>
                            </div>
                            {deleteAttachment &&
                                <div className={styles.assignmentsListActionsCell}>
                                    <FontAwesomeIcon className={styles.attachmentDeleteIcon}
                                        icon="trash"
                                        onClick={() => deleteAttachment(attachment.Id)} />
                                </div>}
                        </div>);
                }
            }
        }
    }
    return modelsRecords;
};

export default ModelsRows;

import React, { Component, useState, useEffect } from "react";
/* Styles of the component */
import styles from "./styles.module.scss";

/**
 * Main function to render slider menu componen
 * @param {} param0 
 */
const SliderMenu = ({ items, selected, callback }) => {

    let defaultDelectedItemId = "not-set";
    if (items.length > 0) {
        if (selected) {
            defaultDelectedItemId = selected;
        } else {
            defaultDelectedItemId = items[0].id;
        }
    }
    window.sliderState = defaultDelectedItemId;
    const [selectedItemId, setSelectedItemId] = useState(defaultDelectedItemId);

    /**
     * This method renders a single menu item
     * @param {} param0 
     */
    const MenuItem = ({itemId, itemText, callback}) => {
        return (
            <div className={window.sliderState === itemId ? `${styles.fileOption} ${styles.fileActive}` : styles.fileOption}
                onClick={() => { setSelectedItemId(itemId); window.sliderState = itemId; callback(itemId) }} style={{ 'width': 100 / items.length + '%' }}>
                {itemText}
            </div>
        )
    }

    /**
     * This method renders a group of menu items
     * @param {*} param0 
     */
    const MenuItemsSet = ({ items, callback }) => {
        var menuItems = [];
        const randomSeed = Math.round(Math.random() * 10000000);
        for (var i = 0; i < items.length; i++) {
            var key="menuItem_" + randomSeed + "_" + items[i].id;
            menuItems.push(
                <MenuItem itemId={items[i].id} 
                            itemText={items[i].name} 
                            callback={callback}
                            key={key}/>
            );
        }
        return (
            <div className={styles.resultsViewSelector}>
                {menuItems}
            </div>
        )
    }


    return (
        <div className={ styles.modelRow }> 
            <MenuItemsSet items={ items } callback={ callback }/>
        </div>
    )
}

export default SliderMenu;